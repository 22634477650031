import { WISHLISTDETAIL, ADDTOWISHLIST, DELETEWISHLIST } from ".";
import { showToasterAction  } from '../actions/ToasterActions';
import config from "./axiosConfig";

export const wishlistDetailsAction = (product)=>{
    return dispatch=>{
        dispatch({
            type : WISHLISTDETAIL,
            payload : {
                product : product
            }
        })
    }
}

export const AddToWishlist = (products, userId)=>{
    console.log(products)
    let productArray  = products.product.map((data)=>{
        console.log(data);
        return {
            "product_id": data.id,
        }
    });
    
    let wishlistItems = {
        "user_id" : Number(userId),
        "products" : productArray
    }
    console.log(wishlistItems)
    return dispatch=>{
        config.post('/user/addWishlist',wishlistItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
            }
        }).then(res=>{
            // console.log(res);
           
            dispatch({
                type : ADDTOWISHLIST,
                payload : {
                    msg : res.data.message
                }
            })
            dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            // dispatch(emptyCartDetailsAction());
            ;
        }).catch(err=>{
            console.log(err);
            // dispatch({
            //     type : ADDTOCARTFAILED,
            //     payload : {
            //         msg : res.data.message
            //     }
            // });
        })
    }
}


export const DeleteWishlist = (product_id)=>{
    return dispatch=>{
        dispatch({
            type : DELETEWISHLIST,
            payload: {
                product_id
            }
        })
    }
}