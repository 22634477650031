import { CHECKCOUNT } from ".";
import config from "./axiosConfig";

export const Counter = (token,type)=>{
    return dispatch=>{

        if(type>0){
            config.get('user/getCartSavelistCount',
            {
                headers :  {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+token
                }
            }).then(res=>{
                // setCount();
                dispatch({
                    type : CHECKCOUNT,
                    payload : {counter : res.data.Count_Details}
                });
            }).catch(err=>{
                console.log(err);
            })
        }else{
            config.get('user/getCartWishlistCount',
                {
                    headers :  {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+token
                    }
                }).then(res=>{
                    
                    dispatch({
                        type : CHECKCOUNT,
                        payload : {counter : res.data.Count_Details}
                    });
                }).catch(err=>{
                    console.log(err);
                })
        }
        
    }
}