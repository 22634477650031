export const VIEWPRODUCT = "VIEWPRODUCT";
export const CARTDETAILS = "CARTDETAILS";
export const UPDATECART = "UPDATECART";
export const EMPTYCART = "EMPTYCART";
export const ADDTOWISHLIST = "ADDTOWISHLIST"
export const WISHLISTDETAIL = "WISHLISTDETAIL";
export const DELETEWISHLIST = "DELETEWISHLIST";
export const SHOWTOASTER = "SHOWTOASTER";
export const HIDETOASTER = "HIDETOASTER";
export const ADDTOCART = "ADDTOCART";
export const ADDTOSAVE = "ADDTOSAVE";
export const ADDTOSAVELIST = "ADDTOSAVELIST";
export const DELETEFROMCART = "DELETEFROMCART";
export const LOGINSUCCESS = "LOGINSUCCESS";
export const LOGINFAIL = "LOGINFAIL";
export const SIGNUPSUCCESS = "SIGNUPSUCCESS";
export const SIGNUPFAIL = "SIGNUPFAIL";
export const LOGOUT = "LOGOUT";
export const SETDEFAULT = "SETDEFAULT";
export const CHECKCOUNT = "CHECKCOUNT";