import { combineReducers } from "redux";
import { ViewProductReducer } from "./ViewProductDetailReducer";
import { CartDetailReducer } from "./CartDetailReducer";
import { WishlistDetailReducer } from "./WishlistDetailReducer";
import { toasterReducer } from "./ToasterReducer";
import { LoginReducer } from "./LoginReducer";
import { AddToCartReducer } from "./AddToCartReducer";
import { CountReducer } from "./GeneralReducer";
import { SaveListReducer } from "./SaveListReducer";

export const rootReducer = combineReducers({
    ViewProductReducer,
    CartDetailReducer,
    WishlistDetailReducer,
    SaveListReducer,
    toasterReducer,
    LoginReducer,
    AddToCartReducer,
    CountReducer
});