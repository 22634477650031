import React from "react";
import Banner from "../assets/images/page-header-bg.jpg";
import BG from "../assets/images/backgrounds/bg-4.jpg"
import one from "../assets/images/about/about-2/img-1.jpg"

const AboutUs = ()=>{

    return(
        <>
              <main className="main">
              <div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">About us</h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/home">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">About us</li>
                    </ol>
                </div>
            </nav>

            <div className="page-content pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="about-text text-center mt-3">
                                <h2 className="title text-center mb-2">Who We Are</h2>
                                <p>
                                    <b>Introducing Trendhaul: Your Premier Destination for E-Commerce Excellence</b><br/>

Welcome to Trendhaul, where innovation meets convenience in the world of online shopping. As a pioneering e-commerce platform, Trendhaul stands at the forefront of the industry, offering an extensive array of products spanning diverse categories. Whether you're an individual seeking a singular purchase or a business looking to place bulk orders, Trendhaul is your trusted partner in delivering an unparalleled shopping experience.

<br/><br/><b>Our Commitment to Quality:</b><br/>
At Trendhaul, quality is the cornerstone of our philosophy. We have carefully curated a selection of top-rated sellers, ensuring that every product available on our platform meets the highest standards. Our rigorous vetting process guarantees that you receive nothing but the best, making your shopping journey a seamless and satisfying one.

<br/><br/><b>User-Friendly Interface:</b><br/>
Navigating the world of online shopping has never been easier. Trendhaul's user-friendly interface empowers customers to effortlessly explore our extensive catalog, discover trending items, and make informed choices. Our intuitive design ensures that you can quickly locate the products that match your preferences, providing a hassle-free shopping experience.

<br/><br/><b>Individual and Bulk Ordering:</b><br/>
Whether you're an individual shopper looking for a single item or a business in need of bulk orders, Trendhaul accommodates all your needs. Our flexible ordering system caters to the unique requirements of each customer, making it simple to place orders that suit your personal or business demands.

<br/><br/><b>Unrivaled Functionality:</b><br/>
Trendhaul is committed to continually enhancing your shopping experience. Our platform boasts cutting-edge functionalities that facilitate effortless browsing, secure transactions, and timely deliveries. With Trendhaul, you can trust that your satisfaction is our top priority.

<br/><br/><b>Join the Trendhaul Community:</b><br/>
At Trendhaul, we believe in fostering a community of shoppers who appreciate quality, variety, and convenience. Join us today and experience a new era of e-commerce, where excellence is the norm, and customer satisfaction is paramount.

<br/>Trendhaul - Where Innovation Meets Your Shopping Desires. </p>
                                
                               
                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="icon-box icon-box-sm text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-puzzle-piece"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title">Design Quality</h3>
                                    <p>Sed egestas, ante et vulputate volutpat, eros pede semper est, vitae luctus metus libero <br/>eu augue.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="icon-box icon-box-sm text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-life-ring"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title">Professional Support</h3>
                                    <p>Praesent dapibus, neque id cursus faucibus, <br/>tortor neque egestas augue, eu vulputate <br/>magna eros eu erat. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="icon-box icon-box-sm text-center">
                                <span className="icon-box-icon">
                                    <i className="icon-heart-o"></i>
                                </span>
                                <div className="icon-box-content">
                                    <h3 className="icon-box-title">Made With Love</h3>
                                    <p>Pellentesque a diam sit amet mi ullamcorper <br/>vehicula. Nullam quis massa sit amet <br/>nibh viverra malesuada.</p> 
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <div className="mb-2"></div>

                <div className="bg-image pt-7 pb-5 pt-md-12 pb-md-9" style={{backgroundImage: `url(${BG})`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <div className="count-container text-center">
                                    <div className="count-wrapper text-white">
                                        <span className="count" data-from="0" data-to="40" data-speed="3000" data-refresh-interval="50">0</span>k+
                                    </div>
                                    <h3 className="count-title text-white">Happy Customer</h3>
                                </div>
                            </div>

                            <div className="col-6 col-md-3">
                                <div className="count-container text-center">
                                    <div className="count-wrapper text-white">
                                        <span className="count" data-from="0" data-to="20" data-speed="3000" data-refresh-interval="50">0</span>+
                                    </div>
                                    <h3 className="count-title text-white">Years in Business</h3>
                                </div>
                            </div>

                            <div className="col-6 col-md-3">
                                <div className="count-container text-center">
                                    <div className="count-wrapper text-white">
                                        <span className="count" data-from="0" data-to="95" data-speed="3000" data-refresh-interval="50">0</span>%
                                    </div>
                                    <h3 className="count-title text-white">Return Clients</h3>
                                </div>
                            </div>

                            <div className="col-6 col-md-3">
                                <div className="count-container text-center">
                                    <div className="count-wrapper text-white">
                                        <span className="count" data-from="0" data-to="15" data-speed="3000" data-refresh-interval="50">0</span>
                                    </div>
                                    <h3 className="count-title text-white">Awards Won</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
        </>
    )

}
export default AboutUs;