import { ADDTOSAVELIST } from "../actions";
const initial = {
    product : [],
    msg : '',
    flag : false,

}

export const SaveListReducer = (state = initial, action)=>{
    switch(action.type){
        case ADDTOSAVELIST:
            return {
                    ...state,
                    product : [],
                    msg : action.payload.msg
                }
        default : return state;
    }
}