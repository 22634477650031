import React, { useEffect, useState } from "react";
import BG from "../../assets/images/demos/demo-3/bg-1.jpg";
import config from "../../actions/axiosConfig";
import { useNavigate } from "react-router-dom";
import { ViewProductDetails } from "../../actions/ViewProductDetails";
import SkeletonLoading from "../SkeletonLoading";
import { useDispatch, useSelector } from "react-redux";
import { wishlistDetailsAction } from "../../actions/WishlistDetailAction";
import { Counter } from "../../actions/General";
import {
  showToasterAction,
  hideToasterAction,
} from "../../actions/ToasterActions";
import Toaster from "../Toaster";

const Featured = (props) => {
  const [Products, setProducts] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isloading, setLoading] = useState(false);
  const toaster = useSelector((state) => state.toasterReducer);
  const loggedUser = useSelector((state) => state.LoginReducer);

  useEffect(() => {
    if (props.product.length === 0) {
      setLoading(true);
    } else if (props.product.length > 0) setLoading(false);
  }, [props.product]);

  const viewAllProducts = () => {
    navigate("/productlist", { replace: true });
  };
  const SingleProduct = (data) => {
    // dispatch(ViewProductDetails(data));
    let id = btoa(data.id);
    navigate(`/product/${id}`, { replace: true });
  };

  const addToWishlist = (data) => {
    console.log(data);
    dispatch(wishlistDetailsAction(data));

    if (loggedUser.token !== null) {
      let cartDebouce = setTimeout(() => {
        dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
      }, 2000);
      return () => clearTimeout(cartDebouce);
    }
  };
  useEffect(() => {
    if (toaster.show === true) {
      let toasterDebounce = setTimeout(() => {
        dispatch(hideToasterAction(false, "", ""));
      }, 1000);
      return () => clearTimeout(toasterDebounce);
    }
  }, [toaster]);

  return (
    <>
      {toaster.show === true ? <Toaster /> : null}
      <div className="container featured">
        <ul
          className="nav nav-pills nav-border-anim nav-big justify-content-center mb-3"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active"
              id="products-featured-link"
              data-toggle="tab"
              href="#products-featured-tab"
              style={{ fontSize: "2rem" }}
              role="tab"
              aria-controls="products-featured-tab"
              aria-selected="true"
            >
              Featured
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="products-sale-link"
              data-toggle="tab"
              href="#products-sale-tab"
              role="tab"
              aria-controls="products-sale-tab"
              aria-selected="false"
              style={{ fontSize: "2rem" }}
            >
              On Sale
            </a>
          </li>
        </ul>

        <div className="tab-content tab-content-carousel">
          <div
            className="tab-pane p-0 fade show active"
            id="products-featured-tab"
            role="tabpanel"
            aria-labelledby="products-featured-link"
          >
            <div
              className="row"
              data-owl-options='{
                                                       
                                                        "responsive": {
                                                            "0": {
                                                                "items":2
                                                            },
                                                            "600": {
                                                                "items":2
                                                            },
                                                            "992": {
                                                                "items":3
                                                            },
                                                            "1200": {
                                                                "items":5
                                                            }
                                                        }
                                                    }'
            >
              {isloading && <SkeletonLoading />}

              {props.product.map((data, key) => {
                // if(key<=3){
                return (
                  <div className="col-md-3" key={key}>
                    <div className="product product-2">
                      <figure className="product-media">
                        <a
                          href="#"
                          onClick={() => {
                            SingleProduct(data);
                          }}
                        >
                          <img
                            src={data.s3_url[0]}
                            alt="Product image"
                            className="product-image"
                          />
                        </a>

                        <div className="product-action-vertical">
                          <a
                            href="#"
                            className="btn-product-icon btn-wishlist btn-expandable"
                            onClick={() => {
                              addToWishlist(data);
                            }}
                          >
                            <span>add to wishlist</span>
                          </a>
                        </div>
                      </figure>

                      <div className="product-body">
                        <div className="product-cat">
                          <a href="#">{data.category_name}</a>
                        </div>
                        <h3
                          className="product-title"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <a
                            href="#"
                            onClick={() => {
                              SingleProduct(data);
                            }}
                          >
                            {data.name}
                          </a>
                        </h3>
                        <div className="product-price">
                          &#x20B9;{data.FinalPrice}
                        </div>
                      </div>
                    </div>
                  </div>
                );
                // }
              })}
            </div>
          </div>
          <div
            className="tab-pane p-0 fade"
            id="products-sale-tab"
            role="tabpanel"
            aria-labelledby="products-sale-link"
          >
            <div
              className="row"
              data-owl-options='{
                                "nav": true, 
                                "dots": true,
                                "margin": 20,
                                "loop": false,
                                "responsive": {
                                    "0": {
                                        "items":2
                                    },
                                    "600": {
                                        "items":2
                                    },
                                    "992": {
                                        "items":3
                                    },
                                    "1200": {
                                        "items":4
                                    }
                                }
                            }'
            >
              {isloading && <SkeletonLoading />}
              {props.product.map((data, key) => {
                // if(key>3){
                return (
                  <div className="col-md-3" key={key}>
                    <div className="product product-2">
                      <figure className="product-media">
                        <a
                          href="#"
                          onClick={() => {
                            SingleProduct(data);
                          }}
                        >
                          <img
                            src={data.s3_url[0]}
                            alt="Product image"
                            className="product-image"
                          />
                        </a>

                        <div className="product-action-vertical">
                          <a
                            href="#"
                            className="btn-product-icon btn-wishlist btn-expandable"
                            onClick={() => {
                              addToWishlist(data);
                            }}
                          >
                            <span>add to wishlist</span>
                          </a>
                        </div>
                      </figure>

                      <div className="product-body">
                        <div className="product-cat">
                          <a href="#">{data.category_name}</a>
                        </div>
                        <h3
                          className="product-title"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <a
                            href="#"
                            onClick={() => {
                              SingleProduct(data);
                            }}
                          >
                            {data.name}
                          </a>
                        </h3>
                        <div className="product-price">
                          &#x20B9;{data.FinalPrice}
                        </div>
                      </div>
                    </div>
                  </div>
                );
                // }
              })}
            </div>
          </div>
          {!isloading && (
            <div className="row">
              <div className="col-md-4" style={{ marginLeft: "35%" }}>
                <button
                  onClick={viewAllProducts}
                  className="btn btn-block btn-outline-primary-2"
                >
                  View All
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mb-7 mb-lg-11"></div>
      <div className="container">
        <div
          className="cta cta-border cta-border-image mb-5 mb-lg-7"
          style={{ backgroundImage: `url(${BG})` }}
        >
          <div className="cta-border-wrapper bg-white">
            `
            <div className="row justify-content-center">
              <div className="col-md-11 col-xl-11">
                <div className="cta-content">
                  <div className="cta-heading">
                    <h3 className="cta-title text-right">
                      <span className="text-primary">New Deals</span> <br />
                      Start Daily at 12pm e.t.
                    </h3>
                  </div>

                  <div className="cta-text">
                    <p>
                      Get{" "}
                      <span className="text-dark font-weight-normal">
                        FREE SHIPPING* & 5% rewards
                      </span>{" "}
                      on <br />
                      every order with Molla Theme rewards program
                    </p>
                  </div>
                  <a href="#" className="btn btn-primary btn-round">
                    <span>Add to Cart for $50.00/yr</span>
                    <i className="icon-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Featured;
