import {LOGINSUCCESS, LOGINFAIL, SIGNUPSUCCESS, SIGNUPFAIL, LOGOUT, SETDEFAULT} from './index';
import Cookies from "universal-cookie";
import config from './axiosConfig';
import { showToasterAction  } from '../actions/ToasterActions';

const cookie = new Cookies();
export const LoginAction = ({first_name,last_name,email, password,is_vendor})=>{
   
    console.log({first_name,last_name,email, password,is_vendor});
    return dispatch =>{
        config.post('/user/userLogin',{
            email,
            password,
            is_vendor
        },{
            headers :  {
                'Access-Control-Allow-Origin': '*',
            }
        }).then(res=>{
            console.log(res);
           
            dispatch(loginSuccess(res.data));
            // dispatch(showToasterAction(true,'Logout Successfull','SUCCESS'))
        }).catch(err=>{
            // console.log(err);
            dispatch(loginFail(err.response.data));
        })
    }
}

export const signupAction = ({first_name,last_name,email, password,is_vendor})=>{
   
    console.log({first_name,last_name,email, password,is_vendor});
    return dispatch =>{
        config.post('/user/createUser',{
            first_name,
            last_name,
            email,
            password,
            is_vendor
        },{
            headers :  {
                'Access-Control-Allow-Origin': '*',
            }
        }).then(res=>{
            console.log(res);
           
            dispatch(signupSuccess(res.data));
        }).catch(err=>{
            // console.log(err);
            dispatch(signupFail(err.response.data));
        })
    }
}

export const setLoginStateDefault = ()=>{
    return dispatch=>{
        dispatch(setDefault());
    }
}


export const vendorSignupAction = ({first_name,last_name,email, password,referral_code,gst_number,company_name,is_vendor})=>{
   
    console.log({first_name,last_name,email, password,is_vendor});
    return dispatch =>{
        config.post('/user/createUser',{
            first_name,
            last_name,
            email,
            password,
            referral_code,
            gst_number,
            company_name,
            is_vendor
        },{
            headers :  {
                'Access-Control-Allow-Origin': '*',
            }
        }).then(res=>{
            console.log(res);
           
            dispatch(signupSuccess(res.data));
        }).catch(err=>{
            // console.log(err);
            dispatch(signupFail(err.response.data));
        })
    }
}

export const LogoutAction = ()=>{
    
        localStorage.clear();
        return dispatch =>{
            dispatch(logout());
        }
    
}

export const setDefault = ()=>({
    type : SETDEFAULT,
    payload : {
        data : 'Set Default'
    }
})

const logout = ()=>({
    type : LOGOUT,
    payload : {
        data : 'Logged Out'
    }
})
const loginSuccess = (data)=>(
        
    {
        
        type : LOGINSUCCESS,
        payload : {
            token : data.token,
            name : data.userDetails.first_name,
            email : data.userDetails.email,
            user_id : data.userDetails.id,
            is_vendor : data.is_vendor,
            msg : "Done"
        }
})

const loginFail = (data)=>({
    type : LOGINFAIL,
    payload : {
        error : data.error
    }
})

const signupSuccess = (data)=>({
    type : SIGNUPSUCCESS,
    payload : {
        // token : data.token,
        user_id : data.user_id,
        // type : data.type
        msg : "Done"
    }
})

const signupFail = (data)=>({
type : SIGNUPFAIL,
payload : {
    error : data.error
}
})

