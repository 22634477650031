import { VIEWPRODUCT } from "../actions";

const initial = {
    product : []
}
export const ViewProductReducer = (state = initial, action)=>{

    switch (action.type) {
        case VIEWPRODUCT:
            return{
                ...state,
                product : action.payload.product
            }
            break;
        default:
            return state;
            break;
    }


}