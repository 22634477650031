import React,{useEffect} from 'react';
import { 
    CToast, CToaster, CToastBody
} from '@coreui/react'
// import './home.css';

import { useSelector, useDispatch } from 'react-redux';
import { hideToasterAction } from '../actions/ToasterActions';


const Toaster = ()=>{

    const dispatch = useDispatch();
    const toast = useSelector(state=>state.toasterReducer)
    console.log(toast);

    // useEffect(()=>{

    //     setTimeout(()=>{
    //         dispatch(hideToasterAction(false,'',''))
    //     },5000)
    // },[toast.flag])
    return(
        <>
            <div className="loader">
            <CToaster>
                    <CToast 
                        animation={true}
                        autohide = {false}
                        visible={toast.show} 
                        className="toaster"
                        delay={5000}
                        style={{width : '165%',height: '110%'}}
                    >
                       
                        <CToastBody>
                            {
                                toast.flag === 'SUCCESS' ?
                                
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                :
                                    <svg style={{width: "35px",margin: "0% 88% 0% 0%"}} className="ui-error"  viewBox="0 0 87 87" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="Group-2" transform="translate(2.000000, 2.000000)">
                                                        <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                                                        <circle  className="ui-error-circle" stroke="#F74444" strokeWidth="4" cx="41.5" cy="41.5" r="41.5"></circle>
                                                        <path className="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662" id="Line" stroke="#F74444" strokeWidth="3" strokeLinecap="square"></path>
                                                        <path className="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492" id="Line" stroke="#F74444" strokeWidth="3" strokeLinecap="square"></path>
                                                    </g>
                                            </g>
                                    </svg>

                            }
                            
                           
                            <p 
                                className='toasterText'
                               
                            >{toast.msg}</p>
                        </CToastBody>
                    </CToast>
                </CToaster>
            </div>
             
        </>
    )
}

export default Toaster;