import React from "react";
import Banner from "../assets/images/page-header-bg.jpg";

const TC = ()=>{

    return(
        <>
              <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">Terms&Conditions</h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Pages</a></li>
                        <li className="breadcrumb-item active" aria-current="page">TermsCondtions</li>
                    </ol>
                </div>
            </nav>

            <div className="page-content pb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="about-text text-center mt-3">
                                <h2 className="title text-center mb-2">Who We Are</h2>
                                <p>Terms & Conditions

These Terms and Conditions (these "Terms") of Trend Haul, Inc., ("Trend Haul"), are a legally binding agreement between you and Trend Haul and its affiliates relating to your use of Trend Haul’s website and related services (collectively, the "Services"), including, without limitation, the purchase of a membership subscription to utilize the Services (a "Membership"). These Terms do not apply to any content or services provided by third parties, whether or not such third-party services (or links thereto) appear on Trend Haul’s website or are otherwise incorporated into the Services. These Terms affect your rights, so please read them carefully. Subject to these Terms, Trend Haul will use commercially reasonable efforts to provide you the Services and commercially reasonable technical support via Trend Haul’s website and/or mobile applications available on various platforms in accordance with Trend Haul’s standard practices.<br/>

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Acceptance of Terms and Conditions; License</b><br/>
By using the Services, you are expressing your acceptance of and willingness to be legally bound by these Terms, as amended from time to time. Your use of a particular Trend Haul webpage included within the Trend Haul website may also be subject to additional terms outlined elsewhere on such a webpage. You may not use the Services without accepting these Terms.

Individuals must be 18 years of age or older to use the Services, or, if under 18, be at least 13 years old and have the consent and active involvement of a parent or legal guardian. If you agree to these Terms on behalf of a legal entity, you represent to Trend Haul that you have the authority to bind such entity and its affiliates to these terms. In such a case, the term "you" or "your" as used herein will also refer to such entity and its affiliates.

Subject to your compliance with these Terms, Trend Haul grants you a personal, limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and use the Services during the term of your Membership.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Changes to Terms and Conditions</b><br/>
Trend Haul may, from time to time and in its sole and absolute discretion, amend or modify these Terms with or without notice to you; provided that Trend Haul will use commercially reasonable efforts to give you at least 14 days’ prior notice on Trend Haul’s website or via email of any such amendment or modification. The most current version of these Terms will supersede all previous versions, and your use or continued use of the Services will constitute your acceptance of and willingness to be bound by such a version of these Terms. The most current version of these Terms may be viewed at any time by clicking on the "Terms of Use" link at the bottom of Trend Haul’s website.
<br/>
<b style={{fontSize : '20px',fontWeight:'700'}}>Your Information and Content</b><br/>
As part of the Membership registration process (including free trials, as described below), you will be required to provide certain personal identifying and financial information in connection with your Trend Haul account. You may also add additional personal information to your account profile at your discretion (such information is not required to use the Services but may improve the user experience). All information you provide to Trend Haul, directly or indirectly, is subject to Trend Haul’s Privacy Policy, which policy you agree to by your use of the Services, so please review it carefully to understand Trend Haul’s privacy practices.

Subject to Trend Haul’s Privacy Policy, Trend Haul is free to use any comments, information, ideas, concepts, reviews and/or techniques provided by you to Trend Haul or its affiliates (e.g., via communications or questionnaires directly with Trend Haul or post or comments made on Trend Haul's website), directly or indirectly (collectively, "User Content"), without any form of compensation, acknowledgment or payment to you.

Notwithstanding anything to the contrary, Trend Haul shall have the right to collect and analyze data and other information relating to the provision, use and performance of various aspects of the Services and related systems and technologies (including, without limitation, information concerning your User Content and data derived therefrom), and Trend Haul will be free (during and after the term hereof) to (a) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other Trend Haul offerings, and (b) disclose such data solely in aggregate or another de-identified form in connection with its business. In furtherance of the foregoing, if you provide any suggestions, enhancement requests, recommendations, comments or other feedback relating to the Services to Trend Haul, you agree that Trend Haul and its affiliates may freely use, reproduce, license, distribute, and otherwise commercialize the feedback in connection with the Services and/or for any other lawful purpose, on a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual basis. Trend Haul will not be obligated to implement any feedback or correct any defects, bugs, or errors in the service identified in the input or otherwise.

In addition to the preceding, you hereby also grant to Trend Haul a nonexclusive, worldwide, transferable, sublicensable (to its subcontractors and service providers), irrevocable, royalty-free, fully paid-up license to access, use, display, reproduce, modify and process your User Content in accordance with these Terms and Trend Haul’s Privacy Policy, as each it's amended from time to time.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Restrictions and Responsibilities</b><br/>
You will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or data related to the Services ("Software"); modify, translate, or create derivative works based on the Services; use the Services for time sharing or service bureau purposes or otherwise for the benefit of a third party, or remove any proprietary notices or labels.

Further, you may not remove or export from the India or allow the export or re-export of the Services or anything related thereto, or any direct product thereof in violation of any restrictions, laws or regulations of India or authority. As defined in FAR section 2.101, the software and documentation are "commercial items," and according to DFAR section 252.227 7014(a)(1) and (5) are deemed to be "commercial computer software" and "commercial computer software documentation." Consistent with DFAR section 227.7202 and FAR section 12.212, any use modification, reproduction, release, performance, display, or disclosure of such commercial software or commercial software documentation by the U.S. Government will be governed solely by the terms of these Terms and will be prohibited except to the extent expressly permitted by the terms of these Terms.

You represent a covenant and warrant that you will use the Services only in compliance with Trend Haul’s standard published policies then in effect (the "Policy") and all applicable laws and regulations. Although Trend Haulhas no obligation to monitor your use of the Services, Trend Haulmay do so and may prohibit any use of the Services it believes may be (or alleged to be) in violation of the foregoing.

You shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, "Equipment"). You shall also be responsible for maintaining the security of the Equipment, your account, passwords and files, and for all uses of your account or the Equipment with or without your knowledge or consent.

You are not permitted to (a) transfer or otherwise make the Services available to any third party; (b) provide any service based on the Services without our prior written permission; (c) post links to third-party sites or use their logo, company name, etc. without their prior written permission; or (d) use the Services for spamming and/or other illegal purposes.

You agree that you will not upload to the Services or create any content that depicts violence, nudity, partial nudity or is unlawful, sexually suggestive, pornographic, offensive, discriminatory, threatening, libelous, defamatory, obscene, or otherwise violates any third party’s rights, including intellectual property and/or privacy rights, or that otherwise violates these Terms.

​

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Membership and Billing</b><br/>
After registering for and "logging on" to your Trend Haulaccount, you can review and modify the specific details of your Membership status, purchases and billing information at any time by clicking the "Billing Details" link in the "My Account" section of your Trend Haulaccount webpage. Without limiting the following, you agree to Trend Haul’s Fees and Cancellations policy.

Free Trials: Trend Haulencourages the initial use of the Services through a "free trial" Membership. Free trials are only available to first-time users of the Services and cannot be combined with any other promotional offer. Only one (1) free trial may be redeemed by you or your affiliates (e.g., a retailer, such retailer’s organization, and its agents). In order to redeem a free trial offer, you must have Internet access and a valid form of currently-accepted payment, such as an accepted credit card or debit card, verified PayPal account, or "Trend HaulPrePay" (your "Payment Method"). Upon redemption of the free trial offer, your Payment Method may be authorized in an amount up to the Membership price of the initial term of the Plan (as defined below) for which you were given a free trial. In some instances, your available balance or credit limit may reflect the authorization; however, no charges will be made against your Payment Method until the end of the free trial period. The day you start your free trial (based on Mountain time) is considered "Day 1" of the trial, regardless of the time of day you register. Once the free trial has ended, you will be automatically billed the monthly Membership price applicable to the Plan for which you were given a free trial.

Membership Subscription

Trend Hauloffers a number of Membership subscription plans that may vary from time to time in the sole discretion of Trend Haul, including individual promotional plans with variable pricing, subscription duration, and bundled products and services (collectively, each a "Plan"). A given Plan may not be available for your Membership at any given time, and Trend Haulis under no obligation to offer you the same Plan offered or subscribed to by another Trend Haulcustomer. Trend Haulreserves the right to modify, terminate or otherwise amend offered Plans. If a promotional Plan is made available to you during your free trial and you elect to upgrade to such a promotional Plan, you acknowledge that your free trial will terminate immediately upon submission of payment in respect of such a promotional Plan. To the extent third party products and/or services are bundled with a Plan, Trend Haulexplicitly disclaims any and all responsibility and liability with respect to such third-party products and services. For Trend HaulMemberships purchased over the phone: YOU, THE BUYER, MAY CANCEL THIS CONTRACT AT ANY TIME PRIOR TO MIDNIGHT OF THE THIRD BUSINESS DAY AFTER THE DATE OF THE TRANSACTION OR RECEIPT OF THE PRODUCT, WHICHEVER IS LATER.All requests must be in writing or email and must be postmarked or electronically date stamped within the cancellation period. All requests for cancellation must be sent via email to Customer Support.

Billing (for more information, please see Trend Haul's Fees and Cancellations policy)

Upon registering for your Membership and selecting an available Plan, your Payment Method will be charged an automatic recurring monthly or yearly subscription fee applicable to your selected Plan, any applicable tax and any other charges you may incur in connection with your use of the Services (collectively, and as modified from time to time in the sole discretion of Trend Haul, the "Subscription Fee"). The Subscription Fee is charged on the monthly or annual anniversary of the commencement of your Membership (or, if such date does not occur in a given month, the last day of the applicable month) automatically and without notice to you, unless and until either you cancel, or Trend Haulterminates your Membership. You must cancel your Membership prior to the end of the then-current billing period to avoid being charged for the following billing period, and such amounts shall accrue regardless of the validity of your Payment Method. You can verify the date on which the next Subscription Fee will be charged at any time by clicking the "Billing Details" link in the "My Account" section of your Trend Haulaccount webpage.

ALL FEES AND CHARGES (INCLUDING SUBSCRIPTION FEES) ARE NONREFUNDABLE. Trend Haulreserves the right to modify Subscription Fees and any other fees and charges in effect, or add new fees and charges from time to time; provided that Trend Haulwill give you advance notice of these changes by email or as a notification when you log-in to the Trend Haulwebsite. You are responsible for all taxes associated with your use of the Services and products purchased in connection therewith.

It is your responsibility to maintain a current Payment Method on file. Payment Methods may be updated at any time by clicking the "Billing Details" link in the "My Account" section of your Trend Haulaccount webpage. If any charge to your Payment Method fails, then (a) you grant Trend Haulthe right to re-attempt to process and bill your Payment Method for up to 180 days, and (b) you will be responsible for paying all fees and expenses (including collections fees) incurred by Trend Haulin connection with obtaining payment in full for all accrued but unpaid amounts. Any amounts overdue by more than 30 days shall accrue interest from the initial date of nonpayment at a rate per annum equal to the lesser of five percent (5%) and the highest rate allowed under applicable law. Without limiting the foregoing, Trend Haulmay suspend or terminate your Membership and/or your Trend Haulaccount at any time without notice in connection with non-payment of Subscription Fees or any other amounts due to Trend Haulhereunder.

Cancellations (for more information, please see Trend Haul’s Fees and Cancellations policy)

You may cancel your free trial or your paid membership at any time (a) on the Trend Haulwebsite via your online cancellation button, (b) via email to Trend Haul’s customer support, or (c) via online live chat with Customer Support (only available if you have an Advanced, Pro, or Pro-Plus Plan).

Trend HaulDOES NOT PROVIDE REFUNDS OR CREDITS FOR ANY PARTIAL-MONTH OR YEAR SUBSCRIPTION PERIODS. Without limiting the foregoing, no refunds will be issued for the "Data Export" service.

Trend Haulreserves the right to terminate your account for any or no reason with or without notice.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Account Access; Identity Protection</b><br/>
Subject to Trend Haul’s Privacy Policy, in order to provide you with ease of access to your account, Trend Haulwill place a cookie (a small text file) on any computer or device from which you access the Trend Haulwebsite. When you revisit the Trend Haulwebsite, this cookie will enable us to recognize you as a previous user or as the account holder and provide you with direct access to your account without requiring you to retype any password or other user identification.

You are responsible for updating and maintaining the truth and accuracy of the information you provide to us relating to your account. You are also responsible for maintaining the confidentiality of your account and password and for restricting access to your computer and devices. You may not share your account with other people by providing them your password. However, if you decide to share your account with other people, you take full responsibility for their actions and liabilities that may arise therefrom. Users of public or shared computers should log out of the Trend Haulwebsite at the completion of each visit.

If you discover that you are a victim of identity theft and that involves a Trend Haulaccount, you will notify Customer Support immediately. You should also report the identity theft to all of your credit card issuers, as well as your local law enforcement agency. Trend Haulreserves the right to suspend and terminate your account at any time with or without notice if Trend Haulbelieves that such an account is involved in fraudulent activity. You are not entitled to any refund or other consideration in connection with the suspension of your account by Trend Haulfor any reason.

Product Sales; Other Services
This section applies to you in your capacity as a purchaser of products from Suppliers (as defined below) using the Services, even if you are also a Supplier. Suppliers are subject to additional terms and conditions which are made available to them in connection with qualifying to offer products through the Services.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>PRODUCT AND VENUE DISCLAIMERS</b><br/>
The Services include a venue or marketplace to connect you and other retailers with suppliers of wholesale and bulk products (each, a "Supplier"). Although each Supplier is an authorized distributor of the products it sells and is authorized to use the Services to market and sell such products, Trend Hauldoes not represent, and explicitly disclaims that any particular product is suitable for resale by you or anyone else in any given circumstance or jurisdiction.

You are solely responsible for ascertaining a particular product’s (a) suitability for resale under your particular circumstances, no matter the resale venue you use, (b) lawfulness in the jurisdiction in which you desire to resell such product, (c) manufacturer terms and conditions (including, without limitation, applicable warranties and limitations on liability), and (d) applicable manufacturer policies or minimum advertised price restrictions.

In addition, Trend Haulmay, in its discretion, at any time and for any or no reason, remove any product from the Services without notice to you or to the applicable Supplier(s).

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>CONTACTING SUPPLIERS</b><br/>
Trend Haulwill provide you with certain identifying information (Only If Approved By Trend HaulTeam) about Suppliers, including a Supplier’s trade name and brand(s), and may provide appropriate methods of contacting a Supplier from within the Services. Notwithstanding the foregoing, Trend Haul’s agreement with a Supplier may restrict Trend Haulfrom allowing you to contact such Supplier directly. In such cases, Trend Haulwill take reasonable steps to acquire requested information from a Supplier on your behalf.

Regardless of whether you are permitted to contact a Supplier, and in Trend Haul’s sole discretion, your account may be suspended or terminated if you or anyone on your behalf, contacts or attempts to contact a Supplier outside of the tools provided in the Services, if any, and/or attempts to establish a direct relationship with a Suppliers outside of the Services.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>PRODUCT PRICING</b><br/>
Trend Haulreserves the right to modify and adjust product prices at any time and from time to time without notice or liability, including, without limitation, to correct pricing and typographical errors. You acknowledge that product prices fluctuate as a result of a Supplier pricing changes and that Trend Haulhas no control over any Supplier’s pricing, shipping and handling charge policies, and other charges and fees, which may result in continual and unpredictable product price changes at any time.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>PRODUCT DESCRIPTIONS AND SPECIFICATIONS</b><br/>
You acknowledge that all product specifications and descriptions are provided solely by Suppliers and that Trend Haulis not responsible for the accuracy of all such information provided by Suppliers. Trend Haulmakes no warranty, expressed or implied, with respect to the accuracy of such information. In addition, due to size, the volume of requests, and resource constraints, "Data Exports" may not be current at all times and may be delayed or contain data that is older than what is displayed on Trend Haul’s website.

Supplier products and manufacturer names are listed for identification purposes only and in no way represents an endorsement of the applicable Supplier by such manufacturer. It is your responsibility to ensure that a product’s information is correct, authentic, and aligns with the manufacturer’s official description and specifications. You further agree to promptly report to Trend Haulany incorrect and/or fraudulent product listings and identify the allegedly incorrect or fraudulent elements of such listing.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>PRODUCT ORDERS; CHANGES AND RETURNS</b><br/>
When purchasing any product from a Supplier using the Services, you agree that you are responsible for reading the entire product listing and, by completing the product order process, entering into a binding contract to purchase such product from such Supplier. Trend Haulis not responsible for losses or damages you may incur as a result of erroneous or misplaced product orders or typographical errors connected to any purchase or shipping information that you provide via the Services. It is your sole responsibility to verify the accuracy of each product order, including the actual item, pricing, shipping, and/or handling costs and any other applicable terms prior to submitting an order. The foregoing applies universally regardless of the method of order placement (e.g., single orders, batch orders, APIs).

Each Supplier determines its own return, replacement, and refund policies applicable to the products you purchase from such Suppliers and may modify those policies at any time. You are responsible for becoming familiar with each such policy prior to placing an order and agree to abide by such policies with respect to applicable orders. Failure to comply with any such policy may result in a rejected or reduced return, preplacement, or refund. You can view a Supplier’s policies at any time by clicking the "Supplier Info" link in the "Products" section of Trend Haul’s website.

All payment for orders shall be made via your Payment Method or by Trend HaulWallet, provided that Trend Haulmay limit or suspend for orders over 2,00,000 or that Trend Hauldeems, in its sole and absolute discretion, to have high fraud risk.

Generally, once an order is placed, it cannot be modified or canceled. However, under certain circumstances and in the discretion of Trend Hauland, if applicable, the Supplier, certain orders may be modified or canceled, provided that you email Customer Support as soon as possible with a change or cancellation request. Trend Haulis not responsible for any losses or damages you may incur as a result of any approved or denied order modification or change request.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>SHIPPING AND HANDLING</b><br/>
Notwithstanding anything to the contrary, all product orders are eligible for shipment only within India. All orders must be placed with a residential or business shipping address (i.e., orders with only P.O. Box, shipping addresses will not be processed). Individual Suppliers may have additional shipping restrictions.

You are responsible for paying the shipping and handling charges for all products purchased through the Services, even if you refuse or return a shipment though already included in our price. Odd-sized or excessively heavy items may require custom shipping cost calculations, in which event you will be required to approve such custom shipping arrangements prior to any such order shipping. Trend Haulis not responsible whatsoever for defective products, shipping errors, or damage to products in transit.

Regardless of whether product specifications on Trend Haul’s website provide published shipping weight, such weights are for informational purposes only and are not to be used for estimating or calculating shipping costs or your financial obligation with respect thereto. Please refer to the "Ship Cost" amount located on product webpages and your "Shipping Cart" to determine actual shipping costs.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>SHIPPING AND HANDLING</b><br/>
Trend Haulis not responsible for any warranties for products purchased through the Services; please refer to the warranty terms and conditions provided by the applicable product manufacturers and their respective agents. All product issues, claims, and questions concerning warranties should be submitted directly to the relevant manufacturer and/or, subject to these Terms, the Supplier through the use of the tools provided in the Services. AS BETWEEN Trend HaulAND YOU, THE PRODUCTS ARE PROVIDED "AS IS" AND Trend HaulDISCLAIMS ALL WARRANTIES WITH RESPECT THERETO, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

To the extent, a product is a health-related device or nutritional supplement, all listing information contained on the product webpage, including information relating to medical and health conditions, products and treatments, provided by the manufacturer and/or Supplier for informational purposes only and is not meant to be (a) a substitute for the advice of a qualified physician or other medical professional or (b) be used for prescribing medication or diagnosing a health problem. Statements regarding dietary supplements have not been evaluated by the India. Food and Drug Administration and are not intended to diagnose, treat, cure, or prevent any disease or ailment.

Reselling; Minimum Advertised Pricing
As a reseller, it is your responsibility to obtain payment or assurance of payment from your customers before ordering products through the Services. Trend Haulstrongly recommends obtaining proper address verification systems for credit card purchases from your customers and making sure all physical payments therefrom are deposited and cleared before placing the order through the Services. As described above, Trend Haulproduct orders may or may not be canceled, and returns are subject to applicable charges and fees.

You agree to purchase products through the Services for resale in compliance with applicable law and all rules and regulations imposed by applicable product manufacturers. Without limiting the foregoing, some manufacturers may require strict adherence to rules relating to product advertising (including the use of a manufacturer’s intellectual property). As such, some products may only be advertised at a "Minimum Advertised Price" (a "MAP"). For all products subject to a MAP, Trend Haulwill provide two prices on a product’s webpage: the wholesale cost and the MAP. As a reseller, you agree to never advertise any products subject to a MAP to end consumers for less than the applicable MAP. Products with a MAP will be marked with an asterisk.

It is your responsibility to verify with the manufacturer a product’s information, images, and descriptions prior to marketing such products. Product images are subject to change at any time and are not to be construed as exact representations of any product.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Intellectual Property</b><br/>
Trend Haulshall own and retain all right, title and interest in and to (a) the Services (including the software), all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed as a result of or in connection with any support services, and (c) all intellectual property rights related to any of the foregoing. No title to or ownership of the Services, including, without limitation, any logos, designs, texts, graphics, pictures, data or content, software, platform, technologies, or any proprietary rights related to the Services, is transferred under or by virtue of these Terms. Trend Haulreserves all rights in and to the service not expressly granted to you under these Terms. Further, these Terms do not authorize you to use any name, trademark, or logo of Trend Haul. Trend Haulreserves the right to terminate your Membership without notice or liability to you if, in its sole and absolute discretion, Trend Haulbelieves that you are in violation of this paragraph.

Without limiting the foregoing, reproductions and alterations of individual product images and descriptions may be used for marketing & reselling purposes only. You must receive written consent from Trend Haulfor any other use or any modification of product images and other copyrighted materials.

Any images of persons or personalities contained on the Trend Haulwebsite shall not be an indication of endorsement of any particular product or the Services unless otherwise specifically indicated.

You may not repackage, reproduce, or otherwise sell Memberships, the Software, or any technology or data related thereto. Also, bulk downloading or "screen scraping" of data from the Trend Haulwebsite is prohibited without prior express written permission. Any violation of the foregoing will result in your account being terminated without notice, and, depending on the severity of the violation, Trend Haulreserves the right to press criminal charges and/or seek civil compensation for damages, expenses, and lost revenue.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Term and Termination</b><br/>
These Terms will remain in full force and effect while you use or are registered to use the Services (whether or not you maintain your Membership) and until terminated by either you or Trend Haul. Termination includes the deletion of your user account and ceasing to access the Trend Haulwebsite. You and Trend Haulmay terminate your account and these Terms at any time and for any reason or no reason at all, provided that you shall remain liable for all fees that you accrue in connection with your Membership and the Services.

All sections of these Terms which by their nature should survive termination shall survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.

Trend Haulshall use commercially reasonable efforts consistent with prevailing industry standards to maintain the Services in a manner that minimizes errors and interruptions in the Services. The Services may be temporarily unavailable for scheduled maintenance or unscheduled emergency maintenance, either by Trend Haulor by third-party providers, or because of other causes beyond Trend Haul’s reasonable control, but Trend Haulshall use commercially reasonable efforts to provide advance notice in writing or by email of any scheduled service disruption. HOWEVER, Trend HaulDOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM the USE OF THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES AND IMPLEMENTATION THEREOF ARE PROVIDED "AS IS" AND Trend HaulDISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, the IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.

Indemnity
You agree to indemnify and hold Trend Haul, its directors, managers, officers, employees, equity holders and other representatives, harmless from any third-party claims arising out of your use of the Services or any other party accessing the Services through your account, including any liability or expenses arising from any claims, direct or indirect damages, lost profits, suits, judgments, litigation costs, and attorneys’ fees.

Limitation of Liability
NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, Trend HaulAND ITS DIRECTORS, MANAGERS, OFFICERS, EQUITY HOLDERS AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THESE TERMS OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND Trend Haul’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY YOU TO Trend HaulFOR THE SERVICES UNDER THESE TERMS IN THE 12 MONTHS PRIOR TO THE OCCURRENCE THAT ALLEGEDLY GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT Trend HaulHAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

Limitation of Liability
Communications from Trend Haulto you may be by electronic means. You hereby consent to receive communications from Trend Haulin electronic form and agree that all terms and conditions, agreements, notices, disclosures, and other communications that Trend Haulprovides to you electronically satisfy any legal requirement that such communications would satisfy if they were in writing, other than any non-waivable rights.

The Services may include certain communications from Trend Haul, such as service announcements, administrative messages, and newsletters. You may be prompted to opt into some or all of these communications upon registering your Membership. After opting-in, you may opt-out of receiving such communications at any time. However, you will not be able to opt-out from receiving service announcements and administrative messages.

If you have any questions about our website, the Services, or these Terms, you may email us Attn: Customer Support.

Miscellaneous Provisions
<br/><b style={{fontSize : '20px',fontWeight:'700'}}>FRAUD PROTECTION</b><br/>
To the extent you maintain a valid Membership, you are eligible for fraud protection for dropshipping, the terms, and conditions of which are subject to change at any time.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>YOUR RELATIONSHIP WITH Trend Haul</b><br/>
You, as a reseller of products purchased through the Services, are an independent contractor of Trend Hauland are not considered an employee of Trend Haulfor any reason whatsoever. You are prohibited from making any unauthorized representations, warranties, or claims on behalf of Trend Haul, a Supplier, or a product manufacturer or that are otherwise illegal in your applicable jurisdiction. No agency, partnership, joint venture, or employment is created as a result of these Terms, and you do not have any authority of any kind to bind Trend Haulin any respect whatsoever.

Additionally, you understand that you are an absolutely independent person or entity from Trend Haul. You are responsible for paying taxes to state, federal, or local authorities in accordance with any and all applicable laws. This tax responsibility includes collecting and remitting all appropriate sales taxes to state and local authorities.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>CONFIDENTIALITY</b><br/>
You understand that all information provided by Trend Haulmust be used only in accordance with its specified purpose. You further agree to not provide any confidential or proprietary information received from Trend Haulto any business entity that may compete with Trend Haul. Any violation of confidentiality is grounds for immediate termination of your Membership, and you will be liable for any damages arising therefrom. Trend Haulshall have the right to enforce this confidentiality provision by injunction, specific performance, or other equitable relief, without bond and without prejudice to any other rights and remedies that Trend Haulmay have for a breach hereof.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>SERVICE TESTING</b><br/>
From time to time, Trend Haultests various aspects of the Services and new features and reserves the right to include you in these tests without notice.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>GOVERNING LAW; VENUE</b><br/>
These Terms and any dispute or claim arising out of or related to these Terms, its subject matter or its formation (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of the State of Haryana, without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or related to these Terms or the Services shall be instituted exclusively in the state courts located in Haryana and you further agree that such courts shall have personal jurisdiction and venue with respect to you, and you hereby submit to the jurisdiction and venue of such courts and waive any objection. YOU HEREBY IRREVOCABLY WAIVE THE RIGHT TO TRIAL BY JURY IN ANY CLAIM ARISING OUT OF OR RELATING TO THE SUBJECT MATTER HEREOF. ADDITIONALLY, BOTH YOU AND Trend HaulAGREE THAT EACH PARTY MAY ONLY BRING CLAIMS AGAINST THE OTHER PARTY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>GENERAL PROVISIONS</b><br/>
If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable. These Terms are not assignable, transferable or sublicensable by you except with Trend Haul’s prior written consent. Trend Haulmay transfer and assign any of its rights and obligations under these Terms without consent. These Terms are the complete and exclusive statement of the mutual understanding of the parties and supersede and cancel all previous written and oral agreements, communications, and other understandings relating to the subject matter of these Terms. In any action or proceeding to enforce rights under these Terms, the prevailing party will be entitled to recover costs and attorneys’ fees.

<br/><b style={{fontSize : '20px',fontWeight:'700'}}>Limitation of Liability</b><br/>
While www.Trend Haul.com and all of its dependent pages contain far too much information to be quoted in their entirety in these Terms, you are responsible for reading and understanding all applicable information contained on the Trend Haul website. Trend Haul is not responsible for any inconvenience, loss of profit, or other complications that you or your business or customers may experience as a result of your failure to read and understand relevant information on the Trend Haul website.

If you have any questions about our the Trend Haul's website, the Services or these Terms, you may email us or write to us at: support@TrendHaul.com

​

Please note, return shipments sent to the address above will be refused. Please contact Customer Support for instructions before returning products.


 </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-2"></div>

               

            </div>
        </main>
        </>
    )

}
export default TC;