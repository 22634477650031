import { createStore, applyMiddleware } from "redux";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import { rootReducer } from ".";

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

// export default () => {
//     let store = createStore(persistedReducer,applyMiddleware(thunk))
//     let persistor = persistStore(store)
//     return { store, persistor }
//   }
export const  store = createStore(
    persistedReducer,
    applyMiddleware(thunk)
)