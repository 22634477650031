import config from '../../actions/axiosConfig';
import { useSelector, useDispatch } from 'react-redux';

export const getMyCart = async (loggedUser)=>{
    try{
        const response =  await config.get('user/getCartDetails',
        {
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+loggedUser.token
            }
        })
        return response;
    }catch{
        throw new Error('Could not Fetch the cart');
    }
}