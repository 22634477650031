import React, { useState, useEffect } from "react";
import banner from "../assets/images/page-header-bg.jpg";
import config from "../actions/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Gamer from "../assets/images/gamer.png";

const Profile = () => {
  const [check, setTab] = useState("");
  const loggedUser = useSelector((state) => state.LoginReducer);
  const [profile, setProfile] = useState([]);
  const [orders, setOrders] = useState([]);
  const [address, setAddress] = useState([]);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    config
      .post("/user/getUserDetails", {
        email: loggedUser.email,
      })
      .then((res) => {
        setProfile(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    config
      .get("/user/getOrders", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + loggedUser.token,
        },
      })
      .then((res) => {
        setOrders(res.data.Orders);
        setCurrentItems(res.data.Orders.slice(itemOffset, 5));
        setPageCount(Math.ceil(res.data.Orders.length / 5));
      })
      .catch((err) => {
        console.log(err);
      });
    config
      .get("/user/getAddress", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + loggedUser.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setAddress(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    config
      .get("/user/getOrders", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + loggedUser.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setOrders(res.data.Orders);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 5;
    setCurrentItems(orders.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(orders.length / 5));
  }, [itemOffset]);

  const handleSubmit = () => {
    // config
  };
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % orders.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <main className="main">
        <div
          className="page-header text-center"
          style={{ backgroundImage: `url(${banner}')` }}
        >
          <div className="container">
            <h1 className="page-title">
              Profile<span>Section</span>
            </h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Profile</a>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Shopping Cart</li> */}
            </ol>
          </div>
        </nav>
        <div className="row" style={{ height: "100%", marginBottom: "10%" }}>
          <div className="col-md-4" style={{ height: "100%" }}>
            <div className="profile-left-section">
              <div className="profile-picture">
                <img
                  src={Gamer}
                  alt="profile-picture"
                  style={{ maxWidth: "75%", position: "relative", left: "10%" }}
                />
              </div>
              <div className="profile-inner-section">
                <h5 style={{ textAlign: "center" }}>Ayush Jaiswal</h5>
                <div style={{ margin: "15% 0% 0% 0%" }}>
                  <ul id="tabsJustified" class="nav nav-pills flex-column">
                    <li>
                      <a
                        id="profile-selected"
                        href="#profile"
                        className="profile-inner-section-name"
                        data-target="#profile"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setTab("Profile")}
                      >
                        Profile{" "}
                        <i className="icon-user" style={{ float: "right" }}></i>
                      </a>
                      <hr className="profile-section-hr" />
                    </li>
                    <li>
                      <a
                        id="order-selected"
                        href="#orders"
                        className="profile-inner-section-name"
                        data-target="#orders"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="orders"
                        aria-selected="false"
                        onClick={() => setTab("Orders")}
                      >
                        Orders{" "}
                        <i className="icon-user" style={{ float: "right" }}></i>
                      </a>
                      <hr className="profile-section-hr" />
                    </li>

                    <li>
                      <a
                        href="#address"
                        className="profile-inner-section-name"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="address"
                        aria-selected="false"
                        onClick={() => setTab("Address")}
                      >
                        Address Book{" "}
                        <i className="icon-user" style={{ float: "right" }}></i>
                      </a>
                      <hr className="profile-section-hr" />
                    </li>
                    <li>
                      <a
                        href="#logout"
                        className="profile-inner-section-name"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="logout"
                        aria-selected="false"
                      >
                        Logout{" "}
                        <i className="icon-user" style={{ float: "right" }}></i>
                      </a>
                      <hr className="profile-section-hr" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 ">
            <div
              className="profile-right-section tab-pane fade tab-content active"
              id="profile"
              role="tabpanel"
              style={{ display: check === "Profile" ? "" : "none" }}
              aria-labelledby="profile-selected"
            >
              <h3 style={{ textAlign: "center", margin: "5% 0 5% 0" }}>
                Account Information
              </h3>
              <form>
                <div className="form-group">
                  <label for="singin-email-2">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="singin-email-2"
                    name="email"
                    // onChange={handleChange}
                    // onBlur = {handleBlur}
                    value={profile.first_name}
                    required
                  />
                  {/* {
                                                                errors.email && touched.email ? (<span className='loginErrors'>{errors.email}</span>) : null
                                                            } */}
                </div>
                <div className="form-group">
                  <label for="singin-email-2">Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="singin-email-2"
                    name="email"
                    value={profile.last_name}
                    // onChange={handleChange}
                    // onBlur = {handleBlur}
                    required
                  />
                  {/* {
                                                                errors.email && touched.email ? (<span className='loginErrors'>{errors.email}</span>) : null
                                                            } */}
                </div>
                <div className="form-group">
                  <label for="singin-email-2">Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    id="singin-email-2"
                    name="email"
                    value={profile.email}
                    // onChange={handleChange}
                    // onBlur = {handleBlur}
                    required
                  />
                  {/* {
                                                                errors.email && touched.email ? (<span className='loginErrors'>{errors.email}</span>) : null
                                                            } */}
                </div>

                {/* <div className="form-group"> */}
                {/* <label for="singin-password-2">Password *</label>
                  <input
                    type="password"
                    className="form-control"
                    id="singin-password-2"
                    name="password"
                    // onChange={handleChange}
                    // onBlur = {handleBlur}
                    required
                  /> */}
                {/* {
                                                                errors.password && touched.password ? (<span className='loginErrors'>{errors.password}</span>) : null
                                                            } */}
                {/* </div> */}

                <div className="form-footer">
                  <button type="submit" className="btn btn-outline-primary-2">
                    <span>Update</span>
                    <i className="icon-long-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div>
            <div
              className="profile-right-section tab-pane fade tab-content"
              id="orders"
              role="tabpanel"
              aria-labelledby="order-selected"
              style={{ display: check === "Orders" ? "" : "none" }}
            >
              <h3 style={{ textAlign: "center", margin: "5% 0 5% 0" }}>
                Order Details
              </h3>
              <div className="cart">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <table className="table table-cart table-mobile">
                        <thead>
                          <tr>
                            <th>Order Number</th>
                            <th>Transaction Number</th>
                            <th>Payment Method</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Action </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentItems !== null &&
                            currentItems.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td className="product-col">
                                    <div className="product">
                                      <figure className="product-media">
                                        <a href="#">
                                          {/* <img src={data.s3_url[0]} alt="Product image" /> */}
                                        </a>
                                      </figure>

                                      <h3 className="product-title">
                                        <p style={{ width: "100px" }}>
                                          {data.id}
                                        </p>
                                      </h3>
                                    </div>
                                  </td>
                                  <td>
                                    {data.transaction_id !== null
                                      ? data.transaction_id
                                      : "NA"}
                                  </td>
                                  <td className="price-col">12/12/2023</td>
                                  <td>{data.amount}</td>
                                  <td>{data.order_status}</td>
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn btn-outline-primary-2"
                                    >
                                      <span>View</span>
                                      <i className="icon-long-arrow-right"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>

                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="profile-right-section tab-pane fade tab-content"
              id="address"
              role="tabpanel"
              style={{ display: check === "Address" ? "" : "none" }}
            >
              <h3 style={{ textAlign: "center", margin: "5% 0 5% 0" }}>
                Address Details
                <button
                  style={{ float: "right" }}
                  type="submit"
                  className="btn btn-outline-primary-2"
                >
                  <i className="icon-long-arrow-right"></i>
                  <span>Add</span>
                </button>
                {
                  address.length>0 && address.map((item,key)=>{
                    return(
                      <div className="address-section" style={{display:'flex'}}>
                        <div>
                          <p className="address-span">{loggedUser.name}</p>
                          <p className="address-status">Default</p>
                          <p style={{ float: "left", margin: " 1% 2%" }}>
                            {" "}
                            <i className="icon-user"></i>{item.address}
                          </p>
                          <p style={{ float: "left", margin: "4% -72%" }}>
                            {" "}
                            <i className="icon-user"></i>{item.pincode}
                          </p>
                        </div>
                        <div>
                          <p style={{ color: "red", margin: "15% 0% 0% -20%" }}>
                            Edit | Remove
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
              </h3>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
