import React,{useState,useEffect}from 'react';
import Banner from '../assets/images/page-header-bg.jpg';
import Product1 from '../assets/images/products/table/product-1.jpg';
import EmptyWishlist from '../assets/images/emptyWishlist.png';
import { useSelector,useDispatch } from 'react-redux';
import config from '../actions/axiosConfig';
import Toaster from "./Toaster";
import { showToasterAction  } from '../actions/ToasterActions';
import { DeleteWishlist } from '../actions/WishlistDetailAction';
import { AddSavelistToCart } from '../actions/AddToCart';
import { Counter } from '../actions/General';
import { cartDetailsAction } from '../actions/CartDetailAction';
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import  FileSaver  from 'file-saver';

const BUCKET_NAME = "trendhaulsavelist";
const KEY_ID = "AKIAR3OOVMVSSB63UCNJ";
const SECRET_KEY = "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";
const creds = {
   accessKeyId: KEY_ID,
   secretAccessKey: SECRET_KEY,
};
const client = new S3Client({ 
   credentials: creds,
   region: "ap-south-1" 
})

const Savelist = ()=>{

	const [savelist,setSavelist] = useState([]);
    const [categorySaved, setCategory] = useState([]);
	const dispatch = useDispatch();
	const Wishlist = useSelector(state=>state.WishlistDetailReducer);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [enableAddToCart, setcart] = useState(true);
	const loggedUser = useSelector(state=>state.LoginReducer);
	const toaster = useSelector(state => state.toasterReducer);
	const [sureDelete, setDelete] = useState({check : false, point : ''});

    // console.log(Wishlist);
	useEffect(()=>{

        if(loggedUser.token !==null){
            // console.log(loggedUser.token);
            config.get('user/getSavelistDetails',
            {
                headers :  {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                // console.log(res.data);
                setSavelist(res.data.data);
                if(res.data.data.length>0){
                    const ids = res.data.data.map(({ category_name }) => category_name);
                    const filtered = res.data.data.filter(({ category_name }, index) =>
                        !ids.includes(category_name, index + 1));
                    setCategory(filtered);
                }
                
               
            }).catch(err=>{
                console.log(err);
            })

        }
        // else{
		// 	setWishlist(Wishlist.product)
		// }


    },[])

	useEffect(()=>{
        if(toaster.show === true){
            let toasterDebounce = setTimeout(()=>{
                dispatch(showToasterAction(false,'',''))
              },1000)
              return ()=> clearTimeout(toasterDebounce);
        }
    }, [toaster])

    useEffect(()=>{
            selectedProduct.length>0 ? setcart(false) : setcart(true);
            

    },[selectedProduct])

	const addToCart = ()=>{
        if(loggedUser.token !==null){
            dispatch(AddSavelistToCart(selectedProduct, loggedUser.user_id, loggedUser.token));
            let cartDebouce = setTimeout(()=>{
              
                dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                //   dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
              
            },2000)
			// setTimeout(()=>{
			// 	removeProduct(data.id)
			// },1000)
            return ()=> clearTimeout(cartDebouce);
        }else{
            // console.log(data);
            // dispatch(cartDetailsAction(data,1));
			// setTimeout(()=>{
			// 	removeProduct(data.id)
			// },1000)
            // dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
          }
       
    }

    const setProduct = (e,data)=>{
        if(e.target.checked === true)
            setSelectedProduct([...selectedProduct,{...data}]);
        else if(e.target.checked === false){
            let index = selectedProduct.findIndex(x=>x.id === data.id);
            selectedProduct.splice(index,1);
        }
    }
	
	const removeProduct = (id)=>{
        if(loggedUser.token !==null){
            config.delete(`/user/deleteProductFromSavelist/${id}`,{
                headers : {
                    'Authorization' : 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                let point = savelist.findIndex(x=>x.id === id);
                savelist.splice(point,1);
                dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
                setDelete({check : true, point : id});
                
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            setDelete(true);
            dispatch(DeleteWishlist(id)); 
        }
    }

    const downloadList = ()=>{
        if(loggedUser.token!==null){
           let SelectedProdIds = selectedProduct.map(a=>a.id);
            config.post('user/downloadSavelistDetails',{ids:SelectedProdIds},{
                headers : {
                    'Authorization' : 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                window.open(`${res.data.download_path}`, '_blank');
            })
        }
    }

    const filterRows = (id)=>{
        if(loggedUser.token !==null){
            // console.log(loggedUser.token);
            config.get('user/getSavelistDetails',
            {
                headers :  {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                // console.log(res.data);
                if(res.data.data.length>0){
                    const filtered = res.data.data.filter(({categoryId}, index) => categoryId === id);
                    setSavelist(filtered)
                }
                
               
            }).catch(err=>{
                console.log(err);
            })

        }
       
    }
    return(
        <>
			{
                toaster.show === true ? <Toaster /> : null
            }
             <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">Savelist<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Shop</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Savelist</li>
                    </ol>
                </div>
            </nav>
			{
                savelist.length>0 ? 
					<div className="page-content">
                            
						<div className="container">
                            <div className='row'>
                                <div className=' col-xs-3 mr-3 ml-5 mb-3'>
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-block btn-outline-primary-2"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <i class="icon-list-alt"></i>Select Options
                                        </button>
                                       
                                        <div class="dropdown-menu">
                                            {
                                            
                                                categorySaved.map((data)=>{
                                                        return(
                                                            <a class="dropdown-item" onClick={()=>{filterRows(data.categoryId)}} href="#">{data.category_name}</a>
                                                        )
                                                    })
                                            }
                                            
                                            {/* <a class="dropdown-item" href="#">Another option</a>
                                            <a class="dropdown-item" href="#">The best option</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-xs-3">
                                <div className="dropdown">
                                                            <button 
                                                                className="btn btn-block btn-outline-primary-2" 
                                                                onClick={addToCart} 
                                                                aria-haspopup="true" 
                                                                aria-expanded="false"
                                                                disabled={enableAddToCart}
                                                            >
                                                                <i className="icon-shopping-cart" ></i>Add To Cart
                                                            </button>

                                                            {/* <div className="dropdown-menu">
                                                                <a className="dropdown-item" href="#">First option</a>
                                                                <a className="dropdown-item" href="#">Another option</a>
                                                                <a className="dropdown-item" href="#">The best option</a>
                                                            </div> */}
														</div>
                                </div>
                            </div>
							<table className="table table-wishlist table-mobile">
								<thead>
									<tr>
                                        <th></th>
										<th>Product</th>
										<th>Price</th>
										<th>Stock Status</th>
                                        <th />
										<th> <button className="btn btn-block btn-outline-primary-2" onClick={downloadList}  aria-haspopup="true" aria-expanded="false">
															<i className="icon-download-o" ></i>Download
														</button></th>
									</tr>
								</thead>

								<tbody>
									{

										savelist.map((data,key)=>{
											return(
												<tr key={key} className = {sureDelete.check && sureDelete.point === data.id ? 'rowfade' : ''}>
													<td>
                                                        <div class="custom-control custom-checkbox">
                                                           
                                                            <input type="checkbox" id={`cat-${key}`} onClick={(e)=>{setProduct(e,data)}}/>
                                                            {/* <label class="custom-control-label" for={`cat-${key}`}>.</label>  */}
                                                            
                                                        </div>
                                                    </td>
                                                    <td className="product-col">
														<div className="product">
															<figure className="product-media">
																<a href="#">
																	<img src={data.s3_url[0]} alt="Product image" />
																</a>
															</figure>

															<h3 className="product-title">
															<p style={{width : '100px'}}>{data.name}</p>
															</h3>
														</div>
													</td>
													<td className="price-col">&#x20B9;{data.FinalPrice}</td>
													<td className="stock-col"><span className="in-stock">
														{data.packQty>0 ? 'In stock' : 'Out Of Stock'}
													</span></td>
                                                    <td></td>
													<td className="remove-col">
														<button 
															className="btn-remove"
															onClick={()=>removeProduct(data.id)}
														><i className="icon-close"></i></button></td>
												</tr>
												
											
											)
										})
									}
									
								</tbody>
							</table>
							<div className="wishlist-share">
								<div className="social-icons social-icons-sm mb-2">
									<label className="social-label">Share on:</label>
									<a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
									<a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
									<a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
									<a href="#" className="social-icon" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
									<a href="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
														
													
								</div>
							</div>
						</div>
					</div>
				: 	<div className="page-content">
						<div className="container">
							<img src={EmptyWishlist} alt='No Product in Wishlish' style={{marginLeft : "20%"}}/>
						</div>
					</div>
				
			}
            
        </main>
        </>
    )
}

export default Savelist