import React,{useEffect,useState} from 'react';
import Product1 from "../assets/images/demos/demo-3/products/product-1.jpg";
import { useSelector,useDispatch } from 'react-redux';
import { cartDetailsAction } from '../actions/CartDetailAction';
import { wishlistDetailsAction } from '../actions/WishlistDetailAction';
import { showToasterAction  } from '../actions/ToasterActions';
import ProductSkeleton from './ProductSkeleton';
import Toaster from "./Toaster";
import { useParams } from 'react-router-dom';
import { AddToCart, AddToItems } from '../actions/AddToCart';
import { AddToWishlist } from '../actions/WishlistDetailAction';
import Cookies from 'universal-cookie';
import {
    SideBySideMagnifier,
  } from "react-image-magnifiers";
  import parse from 'html-react-parser';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Counter } from '../actions/General';
import config from '../actions/axiosConfig';
import ProductList from './ProductList';
import { useNavigate } from "react-router-dom";
import Wishlist from './Wishlist';




const Product = ()=>{
    const cookie = new Cookies();
    const [text, setText] = useState('Add To Cart');
    const {productId} = useParams();
    const navigate = useNavigate();
    const [ProductData, setProductData] = useState([]);
    // const [toaster, setToaster] = useState({show:false, flag:'', msg:''})
    const dispatch = useDispatch();
    // const ProductData = useSelector(state=> state.ViewProductReducer);
    const productCheck = useSelector((state)=>state.CartDetailReducer);
    const [image, setImage] = useState(0);
    const [productList, setProductList] = useState([])
    const loggedUser = useSelector(state=>state.LoginReducer);
    const toaster = useSelector(state => state.toasterReducer);
    const [productVariant, setVariant] = useState([]);
    const Cart = useSelector(state=>state.CartDetailReducer);
    const [qty, setQty] = useState(1);
    

    const handleIncQuantity = ()=>{
        setQty(qty!==10 ? qty+1 : qty);
    }
    const handleDecQuantity = ()=>{
        setQty(qty>1 ? qty-1 : qty);
    }
    const addToCart = ()=>{
        if(loggedUser.token !==null){
          
            let cartDebouce = setTimeout(()=>{
              if(loggedUser.is_vendor === 1){
                  dispatch(AddToItems(ProductData, loggedUser.user_id, loggedUser.token));
                  let cartDebouce = setTimeout(()=>{
                    dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                   },2000)
                  return ()=> clearTimeout(cartDebouce);
                //   dispatch(Counter(loggedUser.token, loggedUser.is_vendor))
                //   dispatch(showToasterAction(true,'Added To Item','SUCCESS'))
              }else{
                  dispatch(AddToCart(ProductData,qty, loggedUser.user_id, loggedUser.token));
                  let cartDebouce = setTimeout(()=>{
                    dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                   },2000)
                  return ()=> clearTimeout(cartDebouce);
                //   dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
              }
              
            },1000)
            return ()=> clearTimeout(cartDebouce);
        }else{
            console.log();
            dispatch(cartDetailsAction(ProductData[0],qty));
            // dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
          }
       
    }
    const addToWishlist = ()=>{
        // if(loggedUser.is_vendor === 1){
        //     dispatch(AddToItems(wishlistCheck.product, loggedUser.user_id, loggedUser.token));
        //   //   dispatch(showToasterAction(true,'Added To Item','SUCCESS'))
        //   }else{
            dispatch(AddToWishlist({product:[ProductData[0]]}, loggedUser.user_id));
            let wishlistDebouce = setTimeout(()=>{
                dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
               },2000)
              return ()=> clearTimeout(wishlistDebouce);
          //   dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
        // }
        // dispatch(showToasterAction(true,'Added To Wishlist','SUCCESS'))
        // dispatch(wishlistDetailsAction(ProductData[0])); 
    }
    
      const addWishlist = (data) => {
        dispatch(wishlistDetailsAction(data));
        dispatch(showToasterAction(true, "Added To Wishlist", "SUCCESS"));
      };

    // console.log(ProductData.length);

    useEffect(()=>{
        let id = atob(productId);
        console.log(id);
        config.get(`/user/getProduct/${id}`)
         .then(res=>{
             console.log(res.data);
             setProductData(res.data.Product_Details)
        }).catch(e=>{
             console.log(e);
        })

        config.post('/user/getProducts',{
            "start" : 20,
            "end" : 24
          }).then((res)=>{
              console.log(res.data.products);
              setProductList(res.data.products);
          })
     },[])


  useEffect(()=>{
   

  },[])

    useEffect(()=>{
        if(toaster.show === true){
            let toasterDebounce = setTimeout(()=>{
                dispatch(showToasterAction(false,'',''))
              },1000)
              return ()=> clearTimeout(toasterDebounce);
        }
    }, [toaster])

    const getVariant = (variantId)=>{
        config.post(`/user/getProducts`,{
            "filter_name" : "itemNo",
            "filter_param" : variantId
        })
        .then(res=>{
            console.log(res.data);
            setProductData(res.data.products)
        }).catch(e=>{
            console.log(e);
        })
    }



// console.log(ProductData.length);
console.log(ProductData);
console.log( ProductData.length>0 ?JSON.parse(ProductData[0].modelList):null)
    return(
        <>
            {
                toaster.show === true ? <Toaster /> : null
            }
            
             <main className="main">
                <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
                    <div className="container d-flex align-items-center">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="#">Products</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Default</li>
                        </ol>
                    </div>
                </nav>

           {
            ProductData.length===0 ? <ProductSkeleton />
                
            : 
            <div className="page-content">
                <div className="container">
                    <div className="product-details-top">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="product-gallery product-gallery-vertical">
                                    <div className="row">
                                        <figure className="product-main-image">
                                        <Carousel showArrows={true} showThumbs={false} useKeyboardArrows={true} showIndicators={false}>
                                                        
                                            {
                                                ProductData[0].s3_url.map((img,key)=>{
                                                    return(
                                                        <div>
                                                            
                                                            <SideBySideMagnifier
                                                                imageSrc={img}
                                                                imageAlt="Example"
                                                                // largeImageSrc={ProductData.product.s3_url[0]} // Optional
                                                                alwaysInPlace={true}
                                                                fillAvailableSpace={true}
                                                                overlayBackgroundColor='#000'
                                                                transitionSpeedInPlace={0.2}
                                                            />

                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                            
                                            
                                        </figure>

                                        {/* <div id="product-zoom-gallery" className="product-image-gallery">
                                            {
                                                ProductData.product.s3_url.map((img,key)=>{
                                                    return(
                                                        <a key={key} className="product-gallery-item active" href="#" data-image={img} data-zoom-image={img}>
                                                            <img src={img} alt="product side" />
                                                        </a>
                                                    )
                                                })
                                            }
                                        
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="product-details">
                                    <h1 className="product-title">{ProductData[0].name}</h1>

                                    <div className="ratings-container">
                                        {/* <div className="ratings">
                                            <div className="ratings-val" style={{width: "80%"}}></div>
                                        </div> */}
                                        <a className="ratings-text" href="#product-review-link" id="review-link"> Not Rated Yet</a>
                                    </div>

                                    <div className="product-price">
                                        &#x20B9;{ProductData[0].FinalPrice}
                                    </div>

                                    <div className="product-content">
                                        {parse(ProductData[0].description)}
                                    </div>

                                    <div className="details-filter-row details-row-size">
                                        <label>Color:</label>
                                        {
                                            ProductData[0].modelLabel !==null &&  ProductData[0].modelList.length>0
                                            ?  JSON.parse(ProductData[0].modelList).map((data,key)=>{
                                                return(
                                                    <div className="product-nav product-nav-thumbs">
                                                        <div className="filter-colors">
                                                            <a href="#" className={data.key === ProductData[0].itemNo ? 'selected' : ''} onClick={()=>{getVariant(data.key)}} style={{background: `${data.value}`}}><span className="sr-only"></span></a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            
                                            :null
                                        }

                                        {/* <div className="product-nav product-nav-thumbs">
                                            <a href="#" className="active">
                                                <img src={Product1} alt="product desc" />
                                            </a>
                                            <a href="#">
                                                <img src={Product1} alt="product desc" />
                                            </a>
                                        </div> */}
                                    </div>

                                
                                {
                                    loggedUser.is_vendor<=0 
                                        ?  <div className="details-filter-row details-row-size">
                                                <label htmlFor="qty">Qty:</label>
                                                <div className="product-details-quantity">
                                                {/* <input type="number" id="qty" className="form-control" value="1" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}} /> */}
                                                    <div className="input-group  input-spinner">
                                                        <div className="input-group-prepend">
                                                            <button  onClick={handleDecQuantity} style={{minWidth: "26px"}} className="btn btn-decrement btn-spinner" type="button"><i className="icon-minus"></i></button>
                                                        </div>
                                                        <input 
                                                            type="text" 
                                                            value={qty} 
                                                            name="qty"
                                                            min="1" 
                                                            max="10" 
                                                            step="1" 
                                                            data-decimals="0" 
                                                            style={{textAlign: "center"}} 
                                                            className="form-control " 
                                                            required
                                                        />
                                                        <div className="input-group-append">
                                                            <button 
                                                                style={{minWidth: "26px"}} 
                                                                className="btn btn-increment btn-spinner" 
                                                                type="button"
                                                                onClick={handleIncQuantity}
                                                            ><i className="icon-plus"></i></button>
                                                        </div>
                                                    </div>  
                                                </div>
                                                <div className="product-details-action">
                                                    <a href="#" className="btn-product btn-cart" onClick={addToCart}><span>Add To Cart</span></a>

                                                    <div className="details-action-wrapper">
                                                        <a href="#" className="btn-product btn-wishlist" title="Wishlist" onClick={addToWishlist}><span>Add to Wishlist</span></a>
                                                        {/* <a href="#" className="btn-product btn-compare" title="Compare"><span>Add to Compare</span></a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        :<>
                                            <div className="product-details-action">
                                                <a href="#" className="btn-product btn-cart" title="Savelist" onClick={addToCart}><span>Add to SaveList </span></a>
                                                        {/* <a href="#" className="btn-product btn-compare" title="Compare"><span>Add to Compare</span></a> */}
                                            </div>
                                        </>
                                }
                                

                                

                                    <div className="product-details-footer">
                                        <div className="product-cat">
                                            <span>Category:</span>                                                
                                            <a href="#">{ProductData[0].category_name}</a>
                                        </div>

                                        <div className="social-icons social-icons-sm">
                                            <span className="social-label">Share:</span>
                                            <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                            <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                            <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                            <a href="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="product-details-tab">
                        <ul className="nav nav-pills justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="product-desc-link" data-toggle="tab" href="#product-desc-tab" role="tab" aria-controls="product-desc-tab" aria-selected="true">Description</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="product-info-link" data-toggle="tab" href="#product-info-tab" role="tab" aria-controls="product-info-tab" aria-selected="false">Additional information</a>
                            </li>
                        
                            <li className="nav-item">
                                <a className="nav-link" id="product-review-link" data-toggle="tab" href="#product-review-tab" role="tab" aria-controls="product-review-tab" aria-selected="false">Reviews (2)</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="product-desc-tab" role="tabpanel" aria-labelledby="product-desc-link">
                                <div className="product-desc-content">
                                    <h3>Product Information</h3>
                                    <p style={{textAlign: 'initial'}}>{parse(ProductData[0].description)} </p>
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade" id="product-info-tab" role="tabpanel" aria-labelledby="product-info-link">
                                <div className="product-desc-content">
                                    <h3>Information</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. </p>

                                    <h3>Fabric & care</h3>
                                    <ul>
                                        <li>Faux suede fabric</li>
                                        <li>Gold tone metal hoop handles.</li>
                                        <li>RI branding</li>
                                        <li>Snake print trim interior </li>
                                        <li>Adjustable cross body strap</li>
                                        <li> Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm</li>
                                    </ul>

                                    <h3>Size</h3>
                                    <p>one size</p>
                                </div>
                            </div>
                        
                            <div className="tab-pane fade" id="product-review-tab" role="tabpanel" aria-labelledby="product-review-link">
                                <div className="reviews">
                                    <h3>Reviews (2)</h3>
                                    <div className="review">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <h4><a href="#">Samanta J.</a></h4>
                                                <div className="ratings-container">
                                                    <div className="ratings">
                                                        <div className="ratings-val" style={{width: "80%"}}></div>
                                                    </div>
                                                </div>
                                                <span className="review-date">6 days ago</span>
                                            </div>
                                            <div className="col">
                                                <h4>Good, perfe33ct size</h4>

                                                <div className="review-content">
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!</p>
                                                </div>

                                                <div className="review-action">
                                                    <a href="#"><i className="icon-thumbs-up"></i>Helpful (2)</a>
                                                    <a href="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="review">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                <h4><a href="#">John Doe</a></h4>
                                                <div className="ratings-container">
                                                    <div className="ratings">
                                                        <div className="ratings-val" style={{width: "100%"}}></div>
                                                    </div>
                                                </div>
                                                <span className="review-date">5 days ago</span>
                                            </div>
                                            <div className="col">
                                                <h4>Very good</h4>

                                                <div className="review-content">
                                                    <p>Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!</p>
                                                </div>

                                                <div className="review-action">
                                                    <a href="#"><i className="icon-thumbs-up"></i>Helpful (0)</a>
                                                    <a href="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 className="title text-center mb-4">You May Also Like</h2>

                    <div className="row carousel-with-shadow" data-toggle="owl" 
                        data-owl-options='{
                            "nav": false, 
                            "dots": true,
                            "margin": 20,
                            "loop": false,
                            "responsive": {
                                "0": {
                                    "items":1
                                },
                                "480": {
                                    "items":2
                                },
                                "768": {
                                    "items":3
                                },
                                "992": {
                                    "items":4
                                },
                                "1200": {
                                    "items":4,
                                    "nav": true,
                                    "dots": false
                                }
                            }
                        }'>
                        {productList.map((data, key) => {
            if(key<=7){
                return (
                        <div className="col-md-3" key={key}>
                            <div className="product product-2">
                            <figure className="product-media">
                                <a href={`/product/${btoa(data.id)}`}>
                                <img
                                    src={data.s3_url[0]}
                                    alt="Product image"
                                    className="product-image"
                                    
                                />
                                </a>

                                <div className="product-action-vertical">
                                <a
                                    href="#"
                                    className="btn-product-icon btn-wishlist btn-expandable"
                                    onClick={() => {
                                        addWishlist(data);
                                    }}
                                >
                                    <span>add to wishlist</span>
                                </a>
                                </div>
                            </figure>

                            <div className="product-body">
                                <div className="product-cat">
                                <a href="#">{data.category_name}</a>
                                </div>
                                <h3 className="product-title" style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    }}>
                                <a
                                    href=""
                                    onClick={() => {
                                        setProductData(data);
                                    }}
                                    
                                >
                                    {data.name}
                                </a>
                                </h3>
                                <div className="product-price">
                                &#x20B9;{data.FinalPrice}
                                </div>
                            </div>
                            </div>
                        </div>
                
                );
            }
            
        })}
                    </div>
                </div>
            </div>
           }
        </main>
        </>
    )

}
export default Product;