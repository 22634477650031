import React, { useEffect, useState } from "react";
import BG from "../../assets/images/page-header-bg.jpg";
import { useFormik } from "formik";
import { AddressSchema } from "../ValidationSchema";
import config from "../../actions/axiosConfig";
import { useSelector, useDispatch } from "react-redux";
import Toaster from "../Toaster";
import { showToasterAction } from "../../actions/ToasterActions";
import { getMyCart } from "./Service";

const initialValues = {
  house_no: "",
  locality: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  address: "",
};

const Checkout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.LoginReducer);
  const toaster = useSelector((state) => state.toasterReducer);
  const [Amount, setTotal] = useState(0);
  const [productDetail, setProductDetail] = useState([]);
  const [cartDetail, setCartDetail] = useState([]);
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: AddressSchema,
      // onSubmit : (values, action)=>{
      // console.log(values);
      // dispatch(LoginAction(values));
      // action.resetForm();
      // }
    });

  useEffect(() => {
    config
      .get("user/getAddress", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + loggedUser.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        let Amt = 0;
		let productDetails = []
        getMyCart(loggedUser)
          .then((res) => {
			setCartDetail(res.data.data);
            res.data.data.map((item) => {
              Amt = Amt + item.quantity * Number(item.FinalPrice);
			  if(productDetail.indexOf(item.id)<=0){
				productDetails.push({
					product_id: item.id,
					quantity: item.quantity,
				  })
			  }
			  setProductDetail(productDetails);
              
              //
              // productDetails.push({
              // 	"product_id": item.id,
              // 	"quantity": item.quantity
              // })
            });
            setTotal(Amt);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    // let productDetails = [];
  }, []);
  const handleAddAddress = (e) => {
    values.address =
      values.house_no +
      " " +
      values.locality +
      " " +
      values.city +
      "," +
      values.state +
      "," +
      values.pincode +
      "," +
      values.country;
    
    if (e.target.checked) {
      config
        .post("user/addAddress", values, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + loggedUser.token,
          },
        })
        .then((res) => {
          
          dispatch(showToasterAction(true, res.data.message, "SUCCESS"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (toaster.show === true) {
      let toasterDebounce = setTimeout(() => {
        dispatch(showToasterAction(false, "", ""));
      }, 1000);
      return () => clearTimeout(toasterDebounce);
    }
  }, [toaster]);

  const handleCheckout = () => {
    config
      .post(
        "user/placeOrder",
        {
          products: productDetail,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + loggedUser.token,
          },
        }
      )
      .then((res) => {
        console.log(res.data.payLink);
        window.location.href = `${res.data.payLink}`;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {toaster.show === true ? <Toaster /> : null}
      <main class="main">
        <div
          class="page-header text-center"
          style={{ backgroundImage: `url(${BG}')` }}
        >
          <div class="container">
            <h1 class="page-title">
              Checkout<span>Shop</span>
            </h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" class="breadcrumb-nav">
          <div class="container">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Shop</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Checkout
              </li>
            </ol>
          </div>
        </nav>

        <div class="page-content">
          <div class="checkout">
            <div class="container">
              <div class="checkout-discount">
                <form action="#">
                  <input
                    type="text"
                    class="form-control"
                    required
                    id="checkout-discount-input"
                  />
                  <label for="checkout-discount-input" class="text-truncate">
                    Have a coupon? <span>Click here to enter your code</span>
                  </label>
                </form>
              </div>
              <form action="#">
                <div class="row">
                  <div class="col-lg-9">
                    <h2 class="checkout-title">Billing Details</h2>
                    {/* <div class="row">
		                					<div class="col-sm-6">
		                						<label>First Name *</label>
		                						<input type="text" class="form-control" required />
		                					</div>

		                					<div class="col-sm-6">
		                						<label>Last Name *</label>
		                						<input type="text" class="form-control" required />
		                					</div>
		                				</div> */}

                    {/* <label>Company Name (Optional)</label>
	            						<input type="text" class="form-control" /> */}

                    {/* <label>Country *</label>
	            						<input type="text" class="form-control" required /> */}

                    <label>House Number *</label>
                    <input
                      type="text"
                      name="house_no"
                      class="form-control"
                      placeholder="House number....."
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.house_no && touched.house_no ? (
                      <span className="loginErrors">{errors.house_no}</span>
                    ) : null}
                    <label>locality Name *</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Street Name ..."
                      required
                      name="locality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.locality && touched.locality ? (
                      <span className="loginErrors">{errors.locality}</span>
                    ) : null}

                    <div class="row">
                      <div class="col-sm-6">
                        <label>State *</label>
                        <input
                          type="text"
                          name="state"
                          class="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.state && touched.state ? (
                          <span className="loginErrors">{errors.state}</span>
                        ) : null}
                      </div>
                      <div class="col-sm-6">
                        <label>Town / City *</label>
                        <input
                          type="text"
                          name="city"
                          class="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.city && touched.city ? (
                          <span className="loginErrors">{errors.city}</span>
                        ) : null}
                      </div>
                      <div class="col-sm-6">
                        <label>Country*</label>
                        <input
                          type="text"
                          name="country"
                          class="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.country && touched.country ? (
                          <span className="loginErrors">{errors.country}</span>
                        ) : null}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <label>Postcode / ZIP *</label>
                        <input
                          type="text"
                          name="pincode"
                          class="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.pincode && touched.pincode ? (
                          <span className="loginErrors">{errors.pincode}</span>
                        ) : null}
                      </div>

                      <div class="col-sm-6">
                        <label>Address *</label>
                        <textarea
                          class="form-control"
                          cols="30"
                          name="address"
                          value={
                            values.house_no +
                            " " +
                            values.locality +
                            " " +
                            values.city +
                            "," +
                            values.state +
                            "," +
                            values.pincode +
                            "," +
                            values.country
                          }
                          rows="4"
                          placeholder="Full Address......"
                        ></textarea>
                        {errors.address && touched.address ? (
                          <span className="loginErrors">{errors.address}</span>
                        ) : null}
                      </div>
                    </div>

                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkout-create-acc"
                        onChange={(e) => {
                          handleAddAddress(e);
                        }}
                      />
                      <label
                        class="custom-control-label"
                        for="checkout-create-acc"
                      >
                        Add to Address Book?
                      </label>
                    </div>

                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="checkout-diff-address"
                      />
                      <label
                        class="custom-control-label"
                        for="checkout-diff-address"
                      >
                        Ship to a different address?
                      </label>
                    </div>

                    <label>Order notes (optional)</label>
                    <textarea
                      class="form-control"
                      cols="30"
                      rows="4"
                      placeholder="Notes about your order, e.g. special notes for delivery"
                    ></textarea>
                  </div>
                  <aside class="col-lg-3">
                    <div class="summary">
                      <h3 class="summary-title">Your Order</h3>

                      <table class="table table-summary">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Total</th>
                          </tr>
                        </thead>

                        <tbody>
                          { 
						  cartDetail.map((item, key) => {
                            return (
                              <tr>
                                <td>
                                  <a href="#">{item.name}</a>
                                </td>
                                <td>
                                  <a href="#">{item.quantity}</a>
                                </td>
                                <td>{item.FinalPrice}</td>
                              </tr>
                            );
                          })}

                          <tr class="summary-subtotal">
                            <td>Subtotal:</td>
                            <td colSpan={2}>{Amount}</td>
                          </tr>
                          <tr>
                            <td>Shipping:</td>
                            <td colSpan={2}>Free shipping</td>
                          </tr>
                          <tr class="summary-total">
                            <td>Total:</td>
                            <td colSpan={2}>{Amount}</td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="accordion-summary" id="accordion-payment">
                        <div class="card">
                          <div class="card-header" id="heading-1">
                            <h2 class="card-title">
                              <a
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-1"
                                aria-expanded="true"
                                aria-controls="collapse-1"
                              >
                                Direct bank transfer
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse-1"
                            class="collapse show"
                            aria-labelledby="heading-1"
                            data-parent="#accordion-payment"
                          >
                            <div class="card-body">
                              Make your payment directly into our bank account.
                              Please use your Order ID as the payment reference.
                              Your order will not be shipped until the funds
                              have cleared in our account.
                            </div>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="heading-2">
                            <h2 class="card-title">
                              <a
                                class="collapsed"
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-2"
                                aria-expanded="false"
                                aria-controls="collapse-2"
                              >
                                Check payments
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse-2"
                            class="collapse"
                            aria-labelledby="heading-2"
                            data-parent="#accordion-payment"
                          >
                            <div class="card-body">
                              Ipsum dolor sit amet, consectetuer adipiscing
                              elit. Donec odio. Quisque volutpat mattis eros.
                              Nullam malesuada erat ut turpis.
                            </div>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="heading-3">
                            <h2 class="card-title">
                              <a
                                class="collapsed"
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-3"
                                aria-expanded="false"
                                aria-controls="collapse-3"
                              >
                                Cash on delivery
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse-3"
                            class="collapse"
                            aria-labelledby="heading-3"
                            data-parent="#accordion-payment"
                          >
                            <div class="card-body">
                              Quisque volutpat mattis eros. Lorem ipsum dolor
                              sit amet, consectetuer adipiscing elit. Donec
                              odio. Quisque volutpat mattis eros.
                            </div>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header" id="heading-4">
                            <h2 class="card-title">
                              <a
                                class="collapsed"
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-4"
                                aria-expanded="false"
                                aria-controls="collapse-4"
                              >
                                PayPal{" "}
                                <small class="float-right paypal-link">
                                  What is PayPal?
                                </small>
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse-4"
                            class="collapse"
                            aria-labelledby="heading-4"
                            data-parent="#accordion-payment"
                          >
                            <div class="card-body">
                              Nullam malesuada erat ut turpis. Suspendisse urna
                              nibh, viverra non, semper suscipit, posuere a,
                              pede. Donec nec justo eget felis facilisis
                              fermentum.
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-header" id="heading-5">
                            <h2 class="card-title">
                              <a
                                class="collapsed"
                                role="button"
                                data-toggle="collapse"
                                href="#collapse-5"
                                aria-expanded="false"
                                aria-controls="collapse-5"
                              >
                                Credit Card (Stripe)
                                <img
                                  src="assets/images/payments-summary.png"
                                  alt="payments cards"
                                />
                              </a>
                            </h2>
                          </div>
                          <div
                            id="collapse-5"
                            class="collapse"
                            aria-labelledby="heading-5"
                            data-parent="#accordion-payment"
                          >
                            <div class="card-body">
                              {" "}
                              Donec nec justo eget felis facilisis
                              fermentum.Lorem ipsum dolor sit amet, consectetuer
                              adipiscing elit. Donec odio. Quisque volutpat
                              mattis eros. Lorem ipsum dolor sit ame.
                            </div>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        class="btn btn-outline-primary-2 btn-order btn-block"
                      >
                        <span class="btn-text">Place Order</span>
                        <span class="btn-hover-text" onClick={handleCheckout}>
                          Proceed to Checkout
                        </span>
                      </button>
                    </div>
                  </aside>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Checkout;
