import React,{useEffect, useState} from 'react';
import Banner from "../assets/images/page-header-bg.jpg";
import Product1 from "../assets/images/demos/demo-3/products/product-1.jpg";
import { useDispatch,useSelector } from 'react-redux';
import { ViewProductDetails } from '../actions/ViewProductDetails';
import SkeletonLoading from './SkeletonLoading';
import NoProduct from '../assets/images/no-products.jpg'
import config from '../actions/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { wishlistDetailsAction } from "../actions/WishlistDetailAction";
import Filter from './Pages/ProductFilter';
import Loader from './Loader';

import {
	showToasterAction,
	hideToasterAction,
  } from "../actions/ToasterActions";
  import Toaster from "./Toaster";

const ProductList = ()=>{
	const {categoryId} = useParams();
	// console.log(categoryId);
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
	const [page, setPage] = useState(0);
	const [loader, setLoader] = useState(false)
	const [noProduct, setNoProduct] = useState(false);
	const [checkFilter, setCheckFilter]=useState(categoryId !== undefined ? atob(categoryId) : null);
	const toaster = useSelector((state) => state.toasterReducer);
    const dispatch = useDispatch();
	
	// console.log(categoryId);
	
    useEffect(()=>{
		if(checkFilter !== null){
			// let id = atob(categoryId);
			// setCheckFilter(id);
			// console.log(id.length);
			handleLoader();
			config.post('/user/getProducts',{
				
				"filter_name" : "categoryId",
				"filter_param" : `${checkFilter}`
				
			})
				.then((res)=>{
					// console.log(res.data.products);
					setProducts(res.data.products);
					setPage(res.data.products.length);
					setNoProduct(false);
					handleLoader();
				}).catch((err)=>{
					// console.log(err.response.status);
					if(err.response.status === 400)
						setNoProduct(true);
						handleLoader();

				})
		}else{
			handleLoader();
			config.post('/user/getProducts',{
				"start" : 0,
				"end" : 12,
			})
				.then((res)=>{
					// console.log(res.data.products.length);
					setProducts(res.data.products);
					setPage(res.data.products.length);
					handleLoader();
				})
		}
       
    },[checkFilter])

	useEffect(() => {
		if (toaster.show === true) {
		  let toasterDebounce = setTimeout(() => {
			dispatch(hideToasterAction(false, "", ""));
		  }, 1000);
		  return () => clearTimeout(toasterDebounce);
		}
	  }, [toaster]);
	
	useEffect(()=>{
		if(page>12){
			viewAllProducts(page);
		}
			
	},[page])

	useEffect(()=>{
		window.addEventListener("scroll", handleInfiniteScrolling)
		return ()=> window.removeEventListener("scroll", handleInfiniteScrolling);
	},[])
	
	const handleInfiniteScrolling = ()=>{
		if(document.documentElement.scrollHeight-document.documentElement.scrollTop<=1500){
			setPage((prev)=>prev+12)
		}
	}


    const viewAllProducts = ()=>{
		console.log(page);
		let payload = {};
		if(checkFilter === null){
			payload = {
				"start" : 0,
				"end" :  page
			}
		}else{
			payload = {
				"filter_name" : "categoryId",
				"filter_param" : `${checkFilter}`,
				"start" : 0,
				"end" :  page
			}
		}
        config.post('/user/getProducts',payload).then((res)=>{
                // console.log(res.data.products);
                setProducts(res.data.products)
            })
    }
    const SingleProduct = (data)=>{
		let id = btoa(data.id)
        navigate(`/product/${id}`, {replace : true});
        // dispatch(ViewProductDetails(data));
        // navigate(`/product`, {replace : true});
    }
	const addToWishlist = (data) => {
		dispatch(wishlistDetailsAction(data));
		dispatch(showToasterAction(true, "Added To Wishlist", "SUCCESS"));
	  };
	// console.log(noProduct);
	const handleLoader = ()=>{
		setLoader(loader=>!loader)
	}
    return(
        <>
			{
				loader && <Loader />
			}
			{toaster.show === true ? <Toaster /> : null}
             <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">List<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Shop</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List</li>
                    </ol>
                </div>
            </nav>
            <div className="page-content">
                <div className="container">
                	<div className="row">
						
					
                		<div className="col-lg-9">

						<div className="toolbox">
													<div className="toolbox-left">
														{/* <div className="toolbox-info">
															Showing <span>9 of 56</span> Products
														</div> */}
													</div>

													<div className="toolbox-right">
														<div className="toolbox-sort">
															<label for="sortby">Sort by:</label>
															<div className="select-custom">
																<select name="sortby" id="sortby" className="form-control">
																	<option value="popularity" selected="selected">Most Popular</option>
																	<option value="rating">Most Rated</option>
																	<option value="date">Date</option>
																</select>
															</div>
														</div>
														{/* <div className="toolbox-layout">
															<a href="category-list.html" className="btn-layout active">
																<svg width="16" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="10" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="10" height="4" />
																</svg>
															</a>

															<a href="category-2cols.html" className="btn-layout">
																<svg width="10" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																</svg>
															</a>

															<a href="category.html" className="btn-layout">
																<svg width="16" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="12" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																	<rect x="12" y="6" width="4" height="4" />
																</svg>
															</a>

															<a href="category-4cols.html" className="btn-layout">
																<svg width="22" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="12" y="0" width="4" height="4" />
																	<rect x="18" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																	<rect x="12" y="6" width="4" height="4" />
																	<rect x="18" y="6" width="4" height="4" />
																</svg>
															</a>
														</div> */}
													</div>
												</div>
                			
                            <div className="products mb-3">
							<div class="row justify-content-center">
                                {
                                   noProduct 
								   ? <>
								   		 <div className="product product-list">
                                            <div className="row">
												<img src={NoProduct} alt="No Product Found." style={{width: "60%",marginLeft:"20%"}}/>
											</div>
										</div>
								   	</>
								   
								   :
									products.length===0 ? <SkeletonLoading />
								   	:products.map((data,key)=>{
                                        return(
                                            <>
												
                                                {/* <div class="products mb-3"> */}
													
														<div class="col-6 col-md-4 col-lg-4 col-xl-3">
															<div class="product product-7 text-center">
																<figure class="product-media">
																	<span class="product-label label-new">New</span>
																	<a href="#" onClick={()=>{SingleProduct(data)}}>
																		<img src={data.s3_url[0]} alt="Product image" class="product-image" />
																	</a>

																	<div class="product-action-vertical">
																		<a 
																			href="#" 
																			class="btn-product-icon btn-wishlist btn-expandable"
																			onClick={() => {
																				addToWishlist(data);
																			  }}
																		><span>add to wishlist</span></a>
																		{/* <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
																		<a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
																	</div>

																	{/* <div class="product-action">
																		<a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
																	</div> */}
																</figure>

																<div class="product-body">
																	<div class="product-cat">
																		<a href="#">Women</a>
																	</div>
																	<h3 class="product-title"><a href="#" onClick={()=>{SingleProduct(data)}}>{data.name}</a></h3>
																	<div class="product-price">
																		&#x20B9;{data.FinalPrice}
																	</div>
																	<div class="ratings-container">
																		<div class="ratings">
																			<div class="ratings-val" style={{width: "20%"}}></div>
																		</div>
																		<span class="ratings-text">( 2 Reviews )</span>
																	</div>

																	{/* <div class="product-nav product-nav-thumbs">
																		{
																			data.s3_url.map((img,key)=>{
																				return(
																					
																						<a href="#" className="active">
																							<img src={img} alt="product desc" />
																						</a>
																					
																					
																				)
																			})
																		}
																	</div> */}
																</div>
															</div>
														</div>
                                                       
                                                   
                                                {/* </div> */}
                                            </>
                                        )
                                    })
                                }
                                
							</div>
                               
                            </div>

                			{/* <nav aria-label="Page navigation">
							    
                                 <div className="row">
                                    <div className="col-md-4" style={{marginLeft : '35%'}}>
                                        <button onClick={viewAllProducts} className="btn btn-block btn-outline-primary-2">View More</button>
                                    </div>
                                </div>
							</nav> */}
                		</div>
                		<Filter payload = {checkFilter} setCheckFilter={setCheckFilter} handleLoader={handleLoader}/>
                	</div>
                </div>
            </div>
        </main>
        </>
    );

}
export default ProductList