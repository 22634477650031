import React,{useEffect,useState} from 'react';
import Product from '../assets/images/products/table/product-1.jpg';
import banner from '../assets/images/page-header-bg.jpg';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import config from '../actions/axiosConfig';
import { isEmptyArray } from 'formik';
import { updateCartAction, DeleteFromCart } from '../actions/CartDetailAction';
import EmptyCart from '../assets/images/emptycart.png';
import Toaster from "./Toaster";
import { Counter } from '../actions/General';
import { showToasterAction  } from '../actions/ToasterActions';
import { getMyCart } from './Pages/Service';

const Cart = ()=>{

    const cookie = new Cookies;
    const dispatch = useDispatch();
    const [cart,setCart] = useState([]);
    const [Amount, setTotal] = useState(0);
    const [sureDelete, setDelete] = useState({check : false, point : ''});
    const Cart = useSelector(state=>state.CartDetailReducer);
    const loggedUser = useSelector(state=>state.LoginReducer);
    const toaster = useSelector(state => state.toasterReducer);
    const [quantity,setQty] = useState([]);

    useEffect(()=>{
        getCart();
    },[])

    const getCart = ()=>{
        if(loggedUser.token !==null){
            // console.log(loggedUser.token);
            getMyCart(loggedUser).then(res=>{
                console.log(res.data);
                let Amt = 0
                res.data.data.map(data=>{
                    Amt = Amt+(data.quantity*Number(data.FinalPrice));
                    console.log(Amt);
                })
                console.log(Amt);
               setTotal(Amt);
               setCart(res.data.data);
            }).catch(err=>{
                console.log(err);
            })

        }else{
            let Amt = 0
            Cart.product.map(data=>{
                Amt = Amt+(data.quantity * Number(data.FinalPrice));
            })
           setTotal(Amt);
            setCart(Cart.product);
        }
    }

    useEffect(()=>{
        if(isEmptyArray(quantity) === false){
            let quantityDebounce = setTimeout(()=>{
                dispatch(updateCartAction(quantity, loggedUser.token));
                getCart();
              },1000)
              return ()=> clearTimeout(quantityDebounce);
        }
    },[quantity]);

    useEffect(()=>{
        if(toaster.show === true){
            let toasterDebounce = setTimeout(()=>{
                dispatch(showToasterAction(false,'',''))
              },1000)
              return ()=> clearTimeout(toasterDebounce);
        }
    }, [toaster])

    const handleIncQuantity = (qty,id)=>{
       setQty({
        "qty" : Number(qty)+1,
        "product_id" : id 
       })
    }
    const handleDecQuantity = (qty,id)=>{
       
        setQty({
            "qty" : Number(qty)-1,
            "product_id" : id 
           })
    }

    const removeProduct = (id)=>{
        if(loggedUser.token !==null){
            config.delete(`/user/deleteCart/${id}`,{
                data :{
                    "user_id" : loggedUser.user_id
                },
                headers : {
                    'Authorization' : 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                console.log(res.data);
                let point = cart.findIndex(x=>x.id === id);
                cart.splice(point,1);
                dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
                setDelete({check : true, point : id});
                let counterDebouce = setTimeout(()=>{
                    getCart();
                    dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                   },2000)
                  return ()=> clearTimeout(counterDebouce);
                
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            console.log(id);
            setDelete(true);
            dispatch(DeleteFromCart(id)); 
        }
    }
    
    console.log(cart);
    console.log(quantity);
    return(
        <>
            {
                toaster.show === true ? <Toaster /> : null
            }
             <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${banner}')`}}>
        		<div className="container">
        			<h1 className="page-title">Shopping Cart<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Shop</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Shopping Cart</li>
                    </ol>
                </div>
            </nav>
            {
                cart.length>0 ? 
                    <div className="page-content">
                        <div className="cart">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <table className="table table-cart table-mobile">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Total</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {
                                                    cart.map((data,key)=>{
                                                        return(
                                                            <tr key={key} className = {sureDelete.check && sureDelete.point === data.id ? 'rowfade' : ''}>
                                                                <td className="product-col">
                                                                    <div className="product">
                                                                        <figure className="product-media">
                                                                            <a href="#">
                                                                                <img src={data.s3_url[0]} alt="Product image" />
                                                                            </a>
                                                                        </figure>
            
                                                                        <h3 className="product-title">
                                                                            <p style={{width : '100px'}}>{data.name}</p>
                                                                        </h3>
                                                                    </div>
                                                                </td>
                                                                <td className="price-col">&#x20B9;{data.FinalPrice}</td>
                                                                <td className="quantity-col">
                                                                    <div className="product-details-quantity">
                                                                        <div className="input-group  input-spinner">
                                                                            <div className="input-group-prepend">
                                                                                <button 
                                                                                    style={{minWidth: "26px"}} 
                                                                                    className="btn btn-decrement btn-spinner" 
                                                                                    type="button"
                                                                                    onClick={()=>handleDecQuantity(
                                                                                        isEmptyArray(quantity) === false && quantity.product_id === data.id ? quantity.qty :  data.quantity
                                                                                        ,data.id)}
                                                                                ><i className="icon-minus"></i></button>
                                                                            </div>
                                                                            <input 
                                                                                type="text" 
                                                                                value={ isEmptyArray(quantity) === false && quantity.product_id === data.id ? quantity.qty : data.quantity} 
                                                                                name="qty"
                                                                                min="1" 
                                                                                max="10" 
                                                                                step="1" 
                                                                                data-decimals="0" 
                                                                                style={{textAlign: "center"}} 
                                                                                className="form-control " 
                                                                                required
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <button 
                                                                                    style={{minWidth: "26px"}} 
                                                                                    className="btn btn-increment btn-spinner" 
                                                                                    type="button"
                                                                                    onClick={()=>handleIncQuantity(
                                                                                        isEmptyArray(quantity) === false && quantity.product_id === data.id ? quantity.qty : data.quantity
                                                                                        ,data.id)}
                                                                                ><i className="icon-plus"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="total-col">&#x20B9;{data.quantity * data.FinalPrice}</td>
                                                                <td className="remove-col">
                                                                    <button 
                                                                        className="btn-remove"
                                                                        onClick={()=>removeProduct(data.id)}
                                                                    ><i className="icon-close"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                
                                                
                                            </tbody>
                                        </table>

                                        <div className="cart-bottom">
                                            {/* <div className="cart-discount">
                                                <form action="#">
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" required placeholder="coupon code" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-outline-primary-2" type="submit"><i className="icon-long-arrow-right"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}

                                            {/* <a href="#" className="btn btn-outline-dark-2"><span>UPDATE CART</span><i className="icon-refresh"></i></a> */}
                                        </div>
                                    </div>
                                    <aside className="col-lg-3">
                                        <div className="summary summary-cart">
                                            <h3 className="summary-title">Cart Total</h3>

                                            <table className="table table-summary">
                                                <tbody>
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>&#x20B9;{Amount}</td>
                                                    </tr>
                                                    <tr className="summary-shipping">
                                                        <td>Shipping:</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr className="summary-shipping-row">
                                                        <td>
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" id="free-shipping" name="shipping" className="custom-control-input" />
                                                                <label className="custom-control-label" for="free-shipping">Free Shipping</label>
                                                            </div>
                                                        </td>
                                                        <td>&#x20B9;0</td>
                                                    </tr>


                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>&#x20B9;{Amount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <a href="/checkout" className="btn btn-outline-primary-2 btn-order btn-block">PROCEED TO CHECKOUT</a>
                                        </div>

                                        <a href="productlist" className="btn btn-outline-dark-2 btn-block mb-3"><span>CONTINUE SHOPPING</span><i className="icon-refresh"></i></a>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
            :
                <div>
                        <img 
                            src={EmptyCart} 
                            alt="empty cart"  
                            style={{width: '25%',margin: '0% 0% 3% 38%'}}
                        />
                        <p className='emptyCartText'>Your Cart is Empty!!</p>
                </div>
            }
            
        </main>
        </>
    )

}
export default Cart;