import { SHOWTOASTER, HIDETOASTER } from ".";

export const showToasterAction = (show,msg,flag)=>{
    return dispatch=>{
        dispatch({
            type : SHOWTOASTER,
            payload : {
                show,
                flag,
                msg
            }
        })
    }
}

export const hideToasterAction = (show,msg,flag)=>{
    return dispatch=>{
        dispatch({
            type : HIDETOASTER,
            payload : {
                show,
                flag,
                msg
            }
        })
    }
}
