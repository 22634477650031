import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BG from "../../assets/images/demos/demo-3/deal/bg-1.jpg";
import Product5 from "../../assets/images/demos/demo-3/products/product-5.jpg";
import Product6 from "../../assets/images/demos/demo-3/products/product-6.jpg";
import SkeletonLoading from "../SkeletonLoading";
import config from "../../actions/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { wishlistDetailsAction } from "../../actions/WishlistDetailAction";
import { Counter } from "../../actions/General";

const DealsOutlet = () => {
  const [isloading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.LoginReducer);
  const navigate = useNavigate();
  useEffect(() => {
    config
      .post("/user/getProducts", {
        start: 12,
        end: 24,
      })
      .then((res) => {
        // console.log(res.data.products);
        setProducts(res.data.products);
      });
  }, []);
  const addToWishlist = (data) => {
    console.log(data);
    dispatch(wishlistDetailsAction(data));

    if (loggedUser.token !== null) {
      let cartDebouce = setTimeout(() => {
        dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
      }, 2000);
      return () => clearTimeout(cartDebouce);
    }
  };
  const viewAllProducts = () => {
    navigate("/productlist", { replace: true });
  };
  const SingleProduct = (data) => {
    // dispatch(ViewProductDetails(data));
    let id = btoa(data.id);
    navigate(`/product/${id}`, { replace: true });
  };

  return (
    <>
      <div class="bg-light deal-container pt-7 pb-7 mb-5">
        <div class="container">
          <div class="heading text-center mb-4">
            <h2 class="title">Deals & Outlet</h2>
            <p class="title-desc">Today’s deal and more</p>
          </div>

          {/* <div class="row"> */}
            {/* <div class="col-lg-6 deal-col">
                            <div class="deal" style={{backgroundImage: `url(${BG})`}}>
                                <div class="deal-top">
                                    <h2>Deal of the Day.</h2>
                                    <h4>Limited quantities. </h4>
                                </div>

                                <div class="deal-content">
                                    <h3 class="product-title"><a href="product.html">Home Smart Speaker with  Google Assistant</a></h3>

                                    <div class="product-price">
                                        <span class="new-price">$129.00</span>
                                        <span class="old-price">Was $150.99</span>
                                    </div>

                                    <a href="product.html" class="btn btn-link"><span>Shop Now</span><i class="icon-long-arrow-right"></i></a>
                                </div>
                                <div class="deal-bottom">
                                    <div class="deal-countdown" data-until="+10h"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="products">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="product product-2">
                                            <figure class="product-media">
                                                <span class="product-label label-circle label-top">Top</span>
                                                <span class="product-label label-circle label-sale">Sale</span>
                                                <a href="product.html">
                                                    <img src={Product5} alt="Product image" class="product-image" />
                                                </a>

                                                <div class="product-action-vertical">
                                                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                                </div>

                                                <div class="product-action product-action-dark">
                                                    <a href="#" class="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                    <a href="popup/quickView.html" class="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                                </div>
                                            </figure>

                                            <div class="product-body">
                                                <div class="product-cat">
                                                    <a href="#">Digital Cameras</a>
                                                </div>
                                                <h3 class="product-title"><a href="product.html">Canon - EOS 5D Mark IV DSLR  Camera</a></h3>
                                                <div class="product-price">
                                                    <span class="new-price">$3,599.99</span>
                                                    <span class="old-price">Was $3,999.99</span>
                                                </div>
                                                <div class="ratings-container">
                                                    <div class="ratings">
                                                        <div class="ratings-val" style={{width: "80%"}}></div>
                                                    </div>
                                                    <span class="ratings-text">( 5 Reviews )</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="product product-2">
                                            <figure class="product-media">
                                                <span class="product-label label-circle label-sale">Sale</span>
                                                <a href="product.html">
                                                    <img src={Product6} alt="Product image" class="product-image" />
                                                </a>

                                                <div class="product-action-vertical">
                                                    <a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                                </div>

                                                <div class="product-action product-action-dark">
                                                    <a href="#" class="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                    <a href="popup/quickView.html" class="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                                </div>
                                            </figure>

                                            <div class="product-body">
                                                <div class="product-cat">
                                                    <a href="#">Computers & Tablets</a>
                                                </div>
                                                <h3 class="product-title"><a href="product.html">Apple - Smart Keyboard Folio  for 11-inch iPad Pro</a></h3>
                                                <div class="product-price">
                                                    <span class="new-price">$179.00</span>
                                                    <span class="old-price">Was $200.99</span>
                                                </div>
                                                <div class="ratings-container">
                                                    <div class="ratings">
                                                        <div class="ratings-val" style={{width: "60%"}}></div>
                                                    </div>
                                                    <span class="ratings-text">( 4 Reviews )</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
             <div className="tab-content tab-content-carousel">
             <div
              className="tab-pane p-0 fade show active"
              id="products-featured-tab"
              role="tabpanel"
              aria-labelledby="products-featured-link"
            >
              <div
                className="row"
                data-owl-options='{
                                                       
                                                        "responsive": {
                                                            "0": {
                                                                "items":2
                                                            },
                                                            "600": {
                                                                "items":2
                                                            },
                                                            "992": {
                                                                "items":3
                                                            },
                                                            "1200": {
                                                                "items":5
                                                            }
                                                        }
                                                    }'
              >
                {isloading && <SkeletonLoading />}

                {Products.map((data, key) => {
                  // if(key<=3){
                  return (
                    <div className="col-md-3" key={key}>
                      <div className="product product-2">
                        <figure className="product-media">
                          <a
                            href="#"
                            onClick={() => {
                              SingleProduct(data);
                            }}
                          >
                            <img
                              src={data.s3_url[0]}
                              alt="Product image"
                              className="product-image"
                            />
                          </a>

                          <div className="product-action-vertical">
                            <a
                              href="#"
                              className="btn-product-icon btn-wishlist btn-expandable"
                              onClick={() => {
                                addToWishlist(data);
                              }}
                            >
                              <span>add to wishlist</span>
                            </a>
                          </div>
                        </figure>

                        <div className="product-body">
                          <div className="product-cat">
                            <a href="#">{data.category_name}</a>
                          </div>
                          <h3
                            className="product-title"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <a
                              href="#"
                              onClick={() => {
                                SingleProduct(data);
                              }}
                            >
                              {data.name}
                            </a>
                          </h3>
                          <div className="product-price">
                            &#x20B9;{data.FinalPrice}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  // }
                })}
              </div>
            </div>

             </div>
           
          {/* </div> */}

          <div class="more-container text-center mt-3 mb-0">
            <a
              href="/productlist"
              class="btn btn-outline-dark-2 btn-round btn-more"
            >
              <span>Browse Products</span>
              <i class="icon-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsOutlet;
