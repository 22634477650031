import { CARTDETAILS, EMPTYCART, UPDATECART, DELETEFROMCART } from ".";
import config from "./axiosConfig";
import { showToasterAction  } from '../actions/ToasterActions';

export const cartDetailsAction = (product,qty)=>{
    // Set local cart for guest user
    console.log(product,qty)
    return dispatch=>{
        dispatch({
            type : CARTDETAILS,
            payload : {
                product : product,
                quantity : qty
            }
        });
        dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
    }
}

export const emptyCartDetailsAction = ()=>{
    // Empty the local cart as guest user logged in
    return dispatch=>{
        dispatch({
            type : EMPTYCART,
            payload : {
                product : [],
                msg : ''
            }
        })
    }
}

export const updateCartAction = (product,token)=>{
    // Update the cart to inc/dec the quantity
    console.log(product)
    let updatedproduct = {
        "quantity": product.qty
    }
    return dispatch=>{
        config.patch(`user/updateCart/${product.product_id}`,updatedproduct,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            console.log(res);
           
            dispatch({
                type : UPDATECART,
                payload : {
                    msg : res.data.message
                }
            });
            dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
        }).catch(err=>{
            console.log(err);
            // dispatch({
            //     type : ADDTOCARTFAILED,
            //     payload : {
            //         msg : res.data.message
            //     }
            // });
        })
    }
}

export const DeleteFromCart = (product_id)=>{
    return dispatch=>{
        dispatch({
            type : DELETEFROMCART,
            payload: {
                product_id
            }
        })
    }
}