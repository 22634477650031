import React,{useEffect, useState} from 'react';
import Banner from "../assets/images/page-header-bg.jpg";
import Product1 from "../assets/images/demos/demo-3/products/product-1.jpg";
import { useDispatch,useSelector } from 'react-redux';
import { ViewProductDetails } from '../actions/ViewProductDetails';
import SkeletonLoading from './SkeletonLoading';
import NoProduct from '../assets/images/no-products.jpg'
import config from '../actions/axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { wishlistDetailsAction } from "../actions/WishlistDetailAction";
import {
	showToasterAction,
	hideToasterAction,
  } from "../actions/ToasterActions";
  import Toaster from "./Toaster";

const SearchList = ()=>{
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
	const [noProduct, setNoProduct] = useState(false);
	const toaster = useSelector((state) => state.toasterReducer);
    const dispatch = useDispatch();
	const {input} = useParams();
	console.log(input);
	
    useEffect(()=>{
		if(input !== undefined){
			let id = atob(input);
			console.log(id);
			config.post('/user/searchProduct',{
				"name" : `${id}`
				
			})
				.then((res)=>{
					console.log(res.data.data);
					setProducts(res.data.data)
				}).catch((err)=>{
					console.log(err.response.status);
					if(err.response.status === 400)
						setNoProduct(true);

				})
		}else{
			config.post('/user/getProducts',{
				"start" : 0,
				"end" : 12,
			})
				.then((res)=>{
					console.log(res.data.products);
					setProducts(res.data.products)
				})
		}
       
    },[])

	useEffect(() => {
		if (toaster.show === true) {
		  let toasterDebounce = setTimeout(() => {
			dispatch(hideToasterAction(false, "", ""));
		  }, 1000);
		  return () => clearTimeout(toasterDebounce);
		}
	  }, [toaster]);

    const viewAllProducts = ()=>{
        console.log(products.length);
        config.post('/user/getProducts',{
            "start" : 0,
            "end" :  products.length+12
        }).then((res)=>{
                console.log(res.data.products);
                setProducts(res.data.products)
            })
    }
    const SingleProduct = (data)=>{
		let id = btoa(data.id)
        console.log(id);
        navigate(`/product/${id}`, {replace : true});
        // dispatch(ViewProductDetails(data));
        // navigate(`/product`, {replace : true});
    }
	const addToWishlist = (data) => {
		dispatch(wishlistDetailsAction(data));
		dispatch(showToasterAction(true, "Added To Wishlist", "SUCCESS"));
	  };
	console.log(noProduct);
    return(
        <>
			{toaster.show === true ? <Toaster /> : null}
             <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">List<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Shop</a></li>
                        <li className="breadcrumb-item active" aria-current="page">List</li>
                    </ol>
                </div>
            </nav>
            <div className="page-content">
                <div className="container">
                	<div className="row">
                		<div className="col-lg-9">
						<div className="toolbox">
													<div className="toolbox-left">
														{/* <div className="toolbox-info">
															Showing <span>9 of 56</span> Products
														</div> */}
													</div>

													<div className="toolbox-right">
														<div className="toolbox-sort">
															<label for="sortby">Sort by:</label>
															<div className="select-custom">
																<select name="sortby" id="sortby" className="form-control">
																	<option value="popularity" selected="selected">Most Popular</option>
																	<option value="rating">Most Rated</option>
																	<option value="date">Date</option>
																</select>
															</div>
														</div>
														{/* <div className="toolbox-layout">
															<a href="category-list.html" className="btn-layout active">
																<svg width="16" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="10" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="10" height="4" />
																</svg>
															</a>

															<a href="category-2cols.html" className="btn-layout">
																<svg width="10" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																</svg>
															</a>

															<a href="category.html" className="btn-layout">
																<svg width="16" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="12" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																	<rect x="12" y="6" width="4" height="4" />
																</svg>
															</a>

															<a href="category-4cols.html" className="btn-layout">
																<svg width="22" height="10">
																	<rect x="0" y="0" width="4" height="4" />
																	<rect x="6" y="0" width="4" height="4" />
																	<rect x="12" y="0" width="4" height="4" />
																	<rect x="18" y="0" width="4" height="4" />
																	<rect x="0" y="6" width="4" height="4" />
																	<rect x="6" y="6" width="4" height="4" />
																	<rect x="12" y="6" width="4" height="4" />
																	<rect x="18" y="6" width="4" height="4" />
																</svg>
															</a>
														</div> */}
													</div>
												</div>
                			
                            <div className="products mb-3">
							<div class="row justify-content-center">
                                {
                                   noProduct 
								   ? <>
								   		 <div className="product product-list">
                                            <div className="row">
												<img src={NoProduct} alt="No Product Found." style={{width: "60%",marginLeft:"20%"}}/>
											</div>
										</div>
								   	</>
								   
								   : products.length===0 ? <SkeletonLoading />
                                   :products.map((data,key)=>{
                                        return(
                                            <>
												
                                                {/* <div class="products mb-3"> */}
													
														<div class="col-6 col-md-4 col-lg-4 col-xl-3">
															<div class="product product-7 text-center">
																<figure class="product-media">
																	<span class="product-label label-new">New</span>
																	<a href="#" onClick={()=>{SingleProduct(data)}}>
																		<img src={data.s3_url[0]} alt="Product image" class="product-image" />
																	</a>

																	<div class="product-action-vertical">
																		<a 
																			href="#" 
																			class="btn-product-icon btn-wishlist btn-expandable"
																			onClick={() => {
																				addToWishlist(data);
																			  }}
																		><span>add to wishlist</span></a>
																		{/* <a href="popup/quickView.html" class="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></a>
																		<a href="#" class="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
																	</div>

																	{/* <div class="product-action">
																		<a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
																	</div> */}
																</figure>

																<div class="product-body">
																	<div class="product-cat">
																		<a href="#">Women</a>
																	</div>
																	<h3 class="product-title"><a href="#" onClick={()=>{SingleProduct(data)}}>{data.name}</a></h3>
																	<div class="product-price">
																		&#x20B9;{data.FinalPrice}
																	</div>
																	<div class="ratings-container">
																		<div class="ratings">
																			<div class="ratings-val" style={{width: "20%"}}></div>
																		</div>
																		<span class="ratings-text">( 2 Reviews )</span>
																	</div>

																	{/* <div class="product-nav product-nav-thumbs">
																		{
																			data.s3_url.map((img,key)=>{
																				return(
																					
																						<a href="#" className="active">
																							<img src={img} alt="product desc" />
																						</a>
																					
																					
																				)
																			})
																		}
																	</div> */}
																</div>
															</div>
														</div>
                                                       
                                                   
                                                {/* </div> */}
                                            </>
                                        )
                                    })
                                }
                                
							</div>
                               
                            </div>

                			<nav aria-label="Page navigation">
							    {/* <ul className="pagination">
							        <li className="page-item disabled">
							            <a className="page-link page-link-prev" href="#" aria-label="Previous" tabindex="-1" aria-disabled="true">
							                <span aria-hidden="true"><i className="icon-long-arrow-left"></i></span>Prev
							            </a>
							        </li>
							        <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
							        <li className="page-item"><a className="page-link" href="#">2</a></li>
							        <li className="page-item"><a className="page-link" href="#">3</a></li>
							        <li className="page-item-total">of 6</li>
							        <li className="page-item">
							            <a className="page-link page-link-next" href="#" aria-label="Next" >
							                Next <span aria-hidden="true"><i className="icon-long-arrow-right"></i></span>
							            </a>
							        </li>
							    </ul> */}
                                 <div className="row">
                                    <div className="col-md-4" style={{marginLeft : '35%'}}>
                                        <button onClick={viewAllProducts} className="btn btn-block btn-outline-primary-2">View More</button>
                                    </div>
                                </div>
							</nav>
                		</div>
                		<aside className="col-lg-3 order-lg-first">
                			<div className="sidebar sidebar-shop">
                				<div className="widget widget-clean">
                					<label>Filters:</label>
                					<a href="#" className="sidebar-filter-clear">Clean All</a>
                				</div>

                				<div className="widget widget-collapsible">
    								<h3 className="widget-title">
									    <a data-toggle="collapse" href="#widget-1" role="button" aria-expanded="true" aria-controls="widget-1">
									        Category
									    </a>
									</h3>

									<div className="collapse show" id="widget-1">
										<div className="widget-body">
											<div className="filter-items filter-items-count">
												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-1"/>
														<label className="custom-control-label" for="cat-1">Dresses</label>
													</div>
													<span className="item-count">3</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-2"/>
														<label className="custom-control-label" for="cat-2">T-shirts</label>
													</div>
													<span className="item-count">0</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-3"/>
														<label className="custom-control-label" for="cat-3">Bags</label>
													</div>
													<span className="item-count">4</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-4"/>
														<label className="custom-control-label" for="cat-4">Jackets</label>
													</div>
													<span className="item-count">2</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-5"/>
														<label className="custom-control-label" for="cat-5">Shoes</label>
													</div>
													<span className="item-count">2</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-6" />
														<label className="custom-control-label" for="cat-6">Jumpers</label>
													</div>
													<span className="item-count">1</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-7"/>
														<label className="custom-control-label" for="cat-7">Jeans</label>
													</div>
													<span className="item-count">1</span>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="cat-8"/>
														<label className="custom-control-label" for="cat-8">Sportwear</label>
													</div>
													<span className="item-count">0</span>
												</div>
											</div>
										</div>
									</div>
        						</div>

        						{/* <div className="widget widget-collapsible">
    								<h3 className="widget-title">
									    <a data-toggle="collapse" href="#widget-2" role="button" aria-expanded="true" aria-controls="widget-2">
									        Size
									    </a>
									</h3>

									<div className="collapse show" id="widget-2">
										<div className="widget-body">
											<div className="filter-items">
												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="size-1" />
														<label className="custom-control-label" for="size-1">XS</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="size-2" />
														<label className="custom-control-label" for="size-2">S</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" checked id="size-3" />
														<label className="custom-control-label" for="size-3">M</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" checked id="size-4" />
														<label className="custom-control-label" for="size-4">L</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="size-5" />
														<label className="custom-control-label" for="size-5">XL</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="size-6" />
														<label className="custom-control-label" for="size-6">XXL</label>
													</div>
												</div>
											</div>
										</div>
									</div>
        						</div> */}

        						<div className="widget widget-collapsible">
    								<h3 className="widget-title">
									    <a data-toggle="collapse" href="#widget-3" role="button" aria-expanded="true" aria-controls="widget-3">
									        Colour
									    </a>
									</h3>

									<div className="collapse show" id="widget-3">
										<div className="widget-body">
											<div className="filter-colors">
												<a href="#" style={{background: "#b87145"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background: "#f0c04a"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background: "#333333"}}><span className="sr-only">Color Name</span></a>
												<a href="#" className="selected" style={{background: "#cc3333"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background:" #3399cc"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background: "#669933"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background: "#f2719c"}}><span className="sr-only">Color Name</span></a>
												<a href="#" style={{background: "#ebebeb"}}><span className="sr-only">Color Name</span></a>
											</div>
										</div>
									</div>
        						</div>

        						{/* <div className="widget widget-collapsible">
    								<h3 className="widget-title">
									    <a data-toggle="collapse" href="#widget-4" role="button" aria-expanded="true" aria-controls="widget-4">
									        Brand
									    </a>
									</h3>

									<div className="collapse show" id="widget-4">
										<div className="widget-body">
											<div className="filter-items">
												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-1"/>
														<label className="custom-control-label" for="brand-1">Next</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-2"/>
														<label className="custom-control-label" for="brand-2">River Island</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-3"/>
														<label className="custom-control-label" for="brand-3">Geox</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-4" />
														<label className="custom-control-label" for="brand-4">New Balance</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-5" />
														<label className="custom-control-label" for="brand-5">UGG</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-6" /> 
														<label className="custom-control-label" for="brand-6">F&F</label>
													</div>
												</div>

												<div className="filter-item">
													<div className="custom-control custom-checkbox">
														<input type="checkbox" className="custom-control-input" id="brand-7" />
														<label className="custom-control-label" for="brand-7">Nike</label>
													</div>
												</div>

											</div>
										</div>
									</div>
        						</div> */}

        						
                			</div>
                        </aside>
                	</div>
                </div>
            </div>
        </main>
        </>
    );

}
export default SearchList