import React,{useEffect, Suspense} from 'react';
import Slider from './Pages/Slider';
import Featured from './Pages/FeaturedProducts';
import DealsOutlet from './Pages/DealsOutlet';
import TrendingProduct from './Pages/TrendingProduct';
import GeneralDetails from './Pages/GeneralDetails';
import {useDispatch, useSelector} from "react-redux";
import Cookies from 'universal-cookie';
import { AddAllToCart, AddAllToItems,AddToItems } from '../actions/AddToCart';
import { AddToWishlist } from '../actions/WishlistDetailAction';
import config from '../actions/axiosConfig';
import { useState } from 'react';

// const Featured = React.lazy(()=>import('./Pages/FeaturedProducts'));
// const Slider = React.lazy(()=>import('./Pages/Slider'));
// const DealsOutlet = React.lazy(()=>import('./Pages/DealsOutlet'));
// const TrendingProduct = React.lazy(()=>import('./Pages/TrendingProduct'));
// const GeneralDetails = React.lazy(()=>import('./Pages/GeneralDetails'));

const Home = ()=>{
  const cookie = new Cookies();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([])
  const productCheck = useSelector((state)=>state.CartDetailReducer);
  const wishlistCheck = useSelector((state)=>state.WishlistDetailReducer)
  const loggedUser = useSelector(state=>state.LoginReducer);

  useEffect(()=>{
    config.post('/user/getProducts',{
      "start" : 0,
      "end" : 12
    }).then((res)=>{
        // console.log(res.data.products);
        setProductList(res.data.products);
    })
    if(loggedUser.token !==null && productCheck.product.length>0){
      let cartDebouce = setTimeout(()=>{
        if(loggedUser.is_vendor === 0){
          // console.log(productCheck.product);
          console.log(loggedUser);
          dispatch(AddAllToCart(productCheck.product, loggedUser.user_id, loggedUser.token));
        }else{
          dispatch(AddAllToItems(productCheck.product, loggedUser.user_id,loggedUser.token));
        }
        
      },1000)
      return ()=> clearTimeout(cartDebouce);
    }
    if(loggedUser.token !==null && wishlistCheck.product.length>0){
        WishlistArrayCheck();
    }

  },[wishlistCheck,productCheck])

  const WishlistArrayCheck = ()=>{
    let wishlistDebouce = setTimeout(()=>{
      console.log(wishlistCheck);
      if(loggedUser.is_vendor === 1){
        dispatch(AddToItems(wishlistCheck.product, loggedUser.user_id, loggedUser.token));
      //   dispatch(showToasterAction(true,'Added To Item','SUCCESS'))
      }else{
        dispatch(AddToWishlist(wishlistCheck, loggedUser.user_id));
      //   dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
    }
     
    },1000)
    return ()=> clearTimeout(wishlistDebouce);
  }
  // console.log(wishlistCheck);
    return(
        <>
              <main className="main">
                
                {/* <Suspense fallback={<div>Loading...</div>}> */}
                  {/* <section> */}
                    <Slider />
                    <Featured product = {productList} WishlistArrayCheck={WishlistArrayCheck}/>
                    <DealsOutlet />
                    <TrendingProduct />
                    <GeneralDetails />
                  {/* </section> */}
                {/* </Suspense> */}
                
                
              </main>
        </>
    )
}

export default Home;