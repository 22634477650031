import React from "react";
import Banner from "../assets/images/page-header-bg.jpg";

const PrivacyPolicy = () => {
  return (
    <>
      <main className="main">
        <div
          className="page-header text-center"
          style={{ backgroundImage: `url(${Banner})` }}
        >
          <div className="container">
            <h1 className="page-title">Privacy Policy</h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </li>
            </ol>
          </div>
        </nav>

        <div className="page-content pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text text-center mt-3">
                  <h2 className="title text-center mb-2">Who We Are</h2>
                  <p>
                    Read the following policy to understand how Trend Haul uses
                    the personal information you submit. By using any of Trend
                    Haul's services implies that you agree to these terms.
                    <br />
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      Our Commitment to Privacy
                    </b>
                    <br />
                    Your privacy is important to us. To protect your privacy, we
                    provide this notice explaining our online information
                    practices and the choices you can make about the way your
                    information is collected and used. We make it available from
                    our footer navigation that is found on every page of our
                    Website to make this notice easy to find. Upon registration
                    at Trend Haul, personal information is required. We use this
                    information to check the status and process your orders,
                    conduct account verifications, and to communicate with you.
                    We also use your email address to send you newsletters about
                    Trend Haul special offers, promotional announcements, and
                    consumer surveys. Occasionally, we send our customers
                    special offers and promotional information via postal mail,
                    using the shipping address you provided. To unsubscribe to
                    our newsletters, simply unsubscribe by checking the
                    unsubscribe links.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      The Information We Collect
                    </b>
                    <br />
                    This notice applies to all information collected or
                    submitted on the Trend Haul website. On some pages, you can
                    order memberships, order products, make requests, and
                    register to receive materials. The types of personal
                    information collected on these pages are. Name Billing
                    address Email address Phone number Credit/debit card
                    information Alternative payment information (Google Pay,
                    PayPal, etc.) Unique identifiers (such as username and
                    password) Retail site URL or marketplace name Shipping
                    account information GSTIN On some pages, you can submit
                    information about other people. For example, if you order a
                    product and want it to drop shipped directly to your
                    customer, you will need to submit their address. In this
                    circumstance, the types of personal information collected
                    are: Name Shipping address Phone number Email
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      How We Use Information
                    </b>
                    <br />
                    The primary use of your personal information, other than
                    directly in connection with a purchase, is to efficiently
                    provide you with personalized and relevant information. We
                    also use registration information to let you know about new
                    features or other offers of interest from us and to address
                    customer service needs and requests. Cookies Contrary to the
                    popular myth, cookies do not extract private or personal
                    information from your computer's memory, but rather record
                    only information you volunteer to us upon registration or
                    visiting the site. Cookies are tiny files placed onto the
                    hard drive of your computer when you register at our Website
                    that enable our server to recognize or "remember" who you
                    are each time you return. Cookies can be removed by
                    following Internet browser help file directions. Log files
                    Like most standard website servers, we use log files. This
                    includes Internet protocol (IP) addresses, browser type,
                    Internet service provider (ISP), referring/exit pages,
                    platform type, date/time stamp, and a number of clicks to
                    analyze trends, administer the site, track user's movement
                    in the aggregate, and gather broad demographic information
                    for aggregate use. IP addresses, etc. are not linked to
                    personally identifiable information.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      Communications from Trend Haul
                    </b>
                    <br />
                    Special offers and updates: New members will receive an
                    email to verify password and username. Established members
                    will occasionally receive information on products, services,
                    special deals, and a newsletter. Out of respect for the
                    privacy of our users, we present the option to not receive
                    these types of communications. Registrants will also receive
                    special offers and updates regarding our services. If you
                    are a former customer, you will continue to receive special
                    offers and updates regarding our services unless you choose
                    to unsubscribe from such communications. Newsletter: If a
                    user wishes to subscribe to our newsletter, we ask for
                    contact information such as name and email address. Out of
                    respect for our users' privacy, we provide a way to opt-out
                    of these communications. Service announcements: On rare
                    occasions, it is necessary to send out a strictly
                    service-related announcement. For instance, we will be
                    sending an email to all users if our service is temporarily
                    suspended for maintenance. Generally, users may not opt-out
                    of these communications, though they can deactivate their
                    account. However, these communications are not promotional
                    in nature. Customer service: We communicate with users on a
                    regular basis to provide requested services. Regarding the
                    issues related to their account, we reply via email or phone
                    in accordance with the user's desire. Other: • During the
                    registration, we require phone information to reach you for
                    account management purposes. We will call you during the
                    first several weeks of your trial or service to provide
                    answers to many questions, as well as to offer additional
                    promotions only available via phone. After the first several
                    weeks of your trial or service, we, or our authorized
                    marketing partners, may periodically contact you for the
                    purposes of improving our services by asking you to complete
                    surveys or by offering you other services that you may
                    benefit from.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      Sharing–Third parties
                    </b>
                    <br />
                    Trend Haul will not disclose your personal information
                    provided in connection with membership registration or
                    purchases, except with your knowledge and consent as
                    described in this Privacy Policy or as may be required by
                    law or to protect the rights or property of Trend Haul.
                    Information submitted by you online (such as information
                    about products you purchase from us, your name, address,
                    email address) may be shared with companies with which we
                    have a commercial relationship, including companies through
                    which you reached Trend Haul. For example, if you make a
                    purchase with us because of a special offer made through
                    another site acting as a distributor of our products, in
                    that circumstance, you will be considered their customer as
                    well. We will not, except as may be required by law, share
                    with any other party your password or payment information.
                    We may also share information about you (including name,
                    email address, phone number, and postal address) to our
                    marketing partners or other companies with whom we have a
                    commercial relationship so they may contact you about
                    information and services that we feel you would benefit
                    from. . We also reserve the right to disclose aggregated
                    user statistics, such as "45% of our users are female" in
                    order to describe our services to prospective partners,
                    advertisers, and other third parties. Under the protection
                    of confidentiality agreements, Trend Haul may match common
                    user information, such as name, address, and phone number,
                    with third party data to avoid duplication and prevent
                    errors.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      Our Commitment to Data Security
                    </b>
                    <br />
                    Trend Haul takes every precaution to protect our users'
                    information. When users submit sensitive information via the
                    Website, their information is protected both online and
                    off-line. When our registration/order form asks users to
                    enter sensitive information (such as credit card number),
                    that information is encrypted and is protected with the best
                    encryption software in the industry - SSL. While on a secure
                    page, such as our order form, the lock icon on the bottom of
                    Web browsers such as Netscape Navigator and Microsoft
                    Internet Explorer becomes locked, as opposed to un-locked,
                    or open, when users are just "surfing." While we use SSL
                    encryption to protect sensitive information online, we also
                    do everything in our power to protect user-information
                    off-line. All of our users' information, not just the
                    sensitive information mentioned above, is restricted in our
                    offices. Only employees who need the information to perform
                    a specific job (for example, our billing clerk or a customer
                    service representative) are granted access to personally
                    identifiable information. Furthermore, ALL employees are
                    kept up-to-date on our security and privacy practices.
                    Finally, the servers that store personally identifiable
                    information are in a secure environment, behind a locked
                    cage in a locked facility. Trend Haul reserves the right to
                    make any amendments, modifications or changes to this
                    Privacy Policy at any time. If any material change is made
                    to this Privacy Policy, we will notify our users about those
                    changes via email. If you do not agree to such changes, you
                    can modify your notification settings to opt-out of certain
                    notices from Trend Haul or its marketing partners. If users
                    have any questions about the security at our web site
                    website, please contact Customer Support.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      Our commitment to children's privacy
                    </b>
                    <br />
                    Protecting the privacy of the very young is especially
                    important. For that reason, we never collect or maintain
                    information at our Website from those we actually know are
                    under 13, and no part of our Website is structured to
                    attract anyone under 13. In order to subscribe to Trend
                    Haul's services, the member must be 18 with a valid credit
                    card.
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                      How You Can Access or Correct Your Information
                    </b>
                    <br />
                    You can access your personally identifiable information
                    collected by Trend Haul by logging into your account and
                    visiting the "Settings" section of the Website. You can
                    correct factual errors in your personally identifiable
                    information by sending us a request that credibly shows
                    error. To protect your privacy and security, we will also
                    take reasonable steps to verify your identity before
                    granting access or making corrections.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2"></div>
        </div>
      </main>
    </>
  );
};
export default PrivacyPolicy;
