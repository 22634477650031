import { CARTDETAILS, EMPTYCART, UPDATECART, DELETEFROMCART } from "../actions";

const initial = {
    product : [],
    flag : false,
    msg : ''
}

export const CartDetailReducer = (state = initial, action)=>{
    switch (action.type) {
        // Guest Add to cart management
        case CARTDETAILS:
            
            let quantity = action.payload.quantity;
            let index = state.product.findIndex(x=>x.id === action.payload.product.id) 
            console.log(index);
            if(index !== -1){
                state.product[index].quantity+=quantity;
                return{
                    ...state
                }
            }else{
                return{
                    ...state,
                    product : [...state.product,{...action.payload.product,quantity}] ,
                    flag : true
                }
            }
            
            break;
        case UPDATECART : 
            return {
                ...state,
                msg : action.payload.msg
            }
        
        case EMPTYCART : 
            return{
                ...state,
                product : action.payload.product,
                msg : action.payload.msg
            }
        case DELETEFROMCART : 
            let point = state.product.findIndex(x=>x.id === action.payload.product_id);
            state.product.splice(point,1);
            return {
                ...state
            }
    
        default: return state;
            break;
    }
}