import React,{useEffect} from 'react';
import Banner from '../assets/images/backgrounds/login-bg.jpg';
import { useFormik } from 'formik';
import { LoginSchema } from './ValidationSchema';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { LoginAction, signupAction } from '../actions/LoginAction';
import { useNavigate } from 'react-router-dom';
import VendorSignup from './VendorSignup';

const initialValues  = {
    email : '',
    password : '',
    is_vendor : false
}

const VendorLogin = ()=>{
    const dispatch = useDispatch();
    const loginState = useSelector((state)=>state.LoginReducer);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {values, errors, touched, handleSubmit,handleBlur,handleChange} = useFormik({
        initialValues : initialValues,
        validationSchema : LoginSchema,
        onSubmit : (values,action)=>{
            console.log(values);
            dispatch(LoginAction(values));
            action.resetForm();
        }
    });
   
    // console.log(loginState);
    useEffect(()=>{
        // if(loginState.hit !== showToast ){
        //     setToast(loginState.hit);
            
        //     // cookies.set('myCat', 'Pacman', { path: '/' });
        //     cookies.set('bearerToken', loginState.token, { path: '/'});
           
        //     setTimeout(()=>{
        //         if(cookies.get('bearerToken')!==undefined || cookies.get('bearerToken')!==null){
        //             if(loginState.flag === 'SUCCESS'){
                        
        //                 setRedirect(true);
        //             }
                       
        //         else
        //             setToast(false);
        //         }
        //     },3000)
        // }else{
        //     setTimeout(()=>{
        //         setToast(false);
        //     },3000)
        // }
        // if(loginState.hit === true){
        //     cookies.set('loginId', loginState.user_id, { path: '/' })
        //     navigate(`/home`, {replace : true});
        // }
        
    },[loginState]);
    return(
        <>
             <main className="main">
            <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/home">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Vendor Login</li>
                    </ol>
                </div>
            </nav>

            <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{backgroundImage: `url(${Banner})`}}>
            	<div className="container">
            		<div className="form-box">
            			<div className="form-tab">
	            			<ul className="nav nav-pills nav-fill" role="tablist">
							    <li className="nav-item">
							        <a className="nav-link" id="signin-tab-2" data-toggle="tab" href="#signin-2" role="tab" aria-controls="signin-2" aria-selected="false">Sign In</a>
							    </li>
							    <li className="nav-item">
							        <a className="nav-link active" id="register-tab-2" data-toggle="tab" href="#register-2" role="tab" aria-controls="register-2" aria-selected="true">Register</a>
							    </li>
							</ul>
							<div className="tab-content">
							    <div className="tab-pane fade" id="signin-2" role="tabpanel" aria-labelledby="signin-tab-2">
							    	<form onSubmit={handleSubmit}>
							    		<div className="form-group">
							    			<label for="singin-email-2">Username or email address *</label>
							    			<input 
                                                type="email" 
                                                className="form-control" 
                                                id="singin-email-2" 
                                                name="email" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required />
                                            {
                                                errors.email && touched.email ? (<span className='loginErrors'>{errors.email}</span>) : null
                                            }
							    		</div>

							    		<div className="form-group">
							    			<label for="singin-password-2">Password *</label>
							    			<input 
                                                type="password" 
                                                className="form-control" 
                                                id="singin-password-2" 
                                                name="password" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required />
                                            {
                                                errors.password && touched.password ? (<span className='loginErrors'>{errors.password}</span>) : null
                                            }
							    		</div>

							    		<div className="form-footer">
                                            
                                           
							    			<button type="submit" className="btn btn-outline-primary-2">
			                					<span>LOG IN</span>
			            						<i className="icon-long-arrow-right"></i>
			                				</button>

			                				<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="signin-remember-2" />
												<label className="custom-control-label" for="signin-remember-2">Remember Me</label>
											</div>

											<a href="#" className="forgot-link">Forgot Your Password?</a>
							    		</div>
							    	</form>
							    	<div className="form-choice">
								    	<p className="text-center">or sign in with</p>
								    	<div className="row">
								    		<div className="col-sm-6">
								    			<a href="#" className="btn btn-login btn-g">
								    				<i className="icon-google"></i>
								    				Login With Google
								    			</a>
								    		</div>
								    		<div className="col-sm-6">
								    			<a href="#" className="btn btn-login btn-f">
								    				<i className="icon-facebook-f"></i>
								    				Login With Facebook
								    			</a>
								    		</div>
								    	</div>
							    	</div>
							    </div>
                                <div className="tab-pane fade show active" id="register-2" role="tabpanel" aria-labelledby="register-tab-2">
                                    <VendorSignup />
                                </div>
							    
							</div>
						</div>
            		</div>
            	</div>
            </div>
        </main>
        </>
    )
}

export default VendorLogin