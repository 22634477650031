import Skeleton,{ SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoading = () => {
  return (
   
    <div className="container">
      <div className="row">
       {
         Array(8).fill(0).map(item=>(
                 <div className="col-md-3" >
                    <SkeletonTheme baseColor= "#f8f8f8" highlightColor= "#ebebeb">
                        <Skeleton width={250} height={268} style={{ margin:"0% 10% 10% 10%"}}/>
                    </SkeletonTheme>
        </div>
            ))
       }
       
       
      </div>
    </div>
  );
};

export default SkeletonLoading;
