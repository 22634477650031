import { CHECKCOUNT } from "../actions";

const initial = {
    data : []
}

export const CountReducer = (state=initial, action)=>{
    switch(action.type){
        
        case CHECKCOUNT:
            return{
                ...state,
                data : action.payload.counter
            }
        break;
        default : return state;
        break;
    }
}