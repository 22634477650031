import React,{useState, useEffect} from 'react';
import Banner4 from "../../assets/images/demos/demo-3/banners/banner-4.jpg"
import Product7 from "../../assets/images/demos/demo-3/products/product-7.jpg"
import Product8 from "../../assets/images/demos/demo-3/products/product-8.jpg"
import Product9 from "../../assets/images/demos/demo-3/products/product-9.jpg"
import Product10 from "../../assets/images/demos/demo-3/products/product-10.jpg"
import Product15 from "../../assets/images/demos/demo-3/products/product-15.jpg"
import Product11 from "../../assets/images/demos/demo-3/products/product-11.jpg"
import {useSelector,useDispatch} from "react-redux";
import config from '../../actions/axiosConfig';



const TrendingProduct = ()=>{
    const [categoryProducts, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [count,setcount] = useState(0);

    useEffect(() => {
        //passing getData method to the lifecycle method
        config.post("user/getCategories")
        .then(res=>{
            setCategories(res.data.data)
        }).catch(err=>{
            console.log(err);
        })

    }, []);

    const getProducts = (id)=>{
        config.post('/user/getProducts',{
				
            "filter_name" : "categoryId",
            "filter_param" : `${id}`
            
        })
        .then(res=>{
            setProducts(res.data.data)
        }).catch(err=>{
            console.log(err);
        })
    }

    return(
        <>
             <div className="container trending">
                <div className="heading heading-flex mb-3">
                    <div className="heading-left">
                        <h2 className="title">Trending Products</h2>
                    </div>

                   <div className="heading-right">
                        <ul className="nav nav-pills nav-border-anim justify-content-center" role="tablist">
                        {
                                            categories.length>0
                                            ? categories.map((data,key)=>{
                                                    if(key<=4){
                                                        // setcount(count+1)
                                                        return(
                                                            <li className="nav-item">
                                                                <a 
                                                                    className="nav-link active" 
                                                                    id={`trending-${data.name}-link`} 
                                                                    data-toggle="tab" 
                                                                    href={`#trending-${data.name}-link`} 
                                                                    role="tab" 
                                                                    aria-controls={`trending-${data.name}-link`} 
                                                                    aria-selected="true"
                                                                    onClick={()=>{getProducts(data.id)}}
                                                                >{data.name}</a>
                                                            </li>
                                                        )
                                                    }
                                                   
                                                })
                                            :null
                                        }
                            
                        </ul>
                   </div>
                </div>

                <div className="row">
                    <div className="col-xl-5col d-none d-xl-block">
                        <div className="banner">
                            <a href="#">
                                <img src={Banner4} alt="banner" />
                            </a>
                        </div>
                    </div>

                    <div className="col-xl-4-5col">
                        <div className="tab-content tab-content-carousel just-action-icons-sm">

                            <div className="tab-pane p-0 fade show active" id="trending-all-tab" role="tabpanel" aria-labelledby="trending-all-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                        {
                                            categoryProducts.length>0 
                                            ?  
                                                categoryProducts.map((data,key)=>{
                                                    return(
                                                        <>
                                                            <div className="product product-2">
                                                                <figure className="product-media">
                                                                    <span className="product-label label-circle label-top">Top</span>
                                                                    <a href="product.html">
                                                                        <img src={data.s3_url[0]} alt="Product image" className="product-image" />
                                                                    </a>
                        
                                                                    <div className="product-action-vertical">
                                                                        <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                                                    </div>
                        
                                                                    {/* <div className="product-action product-action-dark">
                                                                        <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                                        <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                                                    </div> */}
                                                                </figure>
                        
                                                                <div className="product-body">
                                                                    <div className="product-cat">
                                                                        <a href="#">Headphones</a>
                                                                    </div>
                                                                    <h3 className="product-title"><a href="product.html">{data.name}</a></h3>
                                                                    <div className="product-price">
                                                                        {data.priceInr}
                                                                    </div>
                                                                    {/* <div className="ratings-container">
                                                                        <div className="ratings">
                                                                            <div className="ratings-val" style={{width: "100%"}}></div>
                                                                        </div>
                                                                        <span className="ratings-text">( 4 Reviews )</span>
                                                                    </div> */}
                        
                                                                    {/* <div className="product-nav product-nav-dots">
                                                                        <a href="#" style={{background: "#69b4ff"}}><span className="sr-only">Color name</span></a>
                                                                        <a href="#" style={{background: "#ff887f"}}><span className="sr-only">Color name</span></a>
                                                                        <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            
                                                
                                        : null
                                        }
                                   

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src={Product8} alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Video Games</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Microsoft - Refurbish Xbox One S 500GB</a></h3>
                                            <div className="product-price">
                                                $279.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src={Product9} alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Smartwatches</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple Watch Series 4 Gold Aluminum Case</a></h3>
                                            <div className="product-price">
                                                $499.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <span className="product-label label-circle label-sale">Sale</span>
                                            <a href="product.html">
                                                <img src={Product10} alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Sony - Class LED 2160p Smart 4K Ultra HD</a></h3>
                                            <div className="product-price">
                                                <span className="new-price">$1,699.99</span>
                                                <span className="old-price">Was $1,999.99</span>
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 10 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src={Product15} alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Samsung - 55" Class  LED 2160p Smart</a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 5 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src={Product11} alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-0 fade" id="trending-tv-tab" role="tabpanel" aria-labelledby="trending-tv-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-13.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Tablets</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-12.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Audio</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                            <div className="product-price">
                                                $79.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <span className="product-label label-circle label-sale">Sale</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-14.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Cell Phone</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL  128GB</a></h3>
                                            <div className="product-price">
                                                <span className="new-price">$35.41</span>
                                                <span className="old-price">Was $41.67</span>
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 10 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-15.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Samsung - 55" Class  LED 2160p Smart</a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 5 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-0 fade" id="trending-computers-tab" role="tabpanel" aria-labelledby="trending-computers-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-15.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Samsung - 55" Class  LED 2160p Smart</a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 5 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-13.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Tablets</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-12.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Audio</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                            <div className="product-price">
                                                $79.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <span className="product-label label-circle label-sale">Sale</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-14.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Cell Phone</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL  128GB</a></h3>
                                            <div className="product-price">
                                                <span className="new-price">$35.41</span>
                                                <span className="old-price">Was $41.67</span>
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 10 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-0 fade" id="trending-phones-tab" role="tabpanel" aria-labelledby="trending-phones-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-12.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Audio</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                            <div className="product-price">
                                                $79.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-13.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Tablets</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-15.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Samsung - 55" Class  LED 2160p Smart</a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 5 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <span className="product-label label-circle label-sale">Sale</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-14.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title=" "><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Cell Phone</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL  128GB</a></h3>
                                            <div className="product-price">
                                                <span className="new-price">$35.41</span>
                                                <span className="old-price">Was $41.67</span>
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 10 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-0 fade" id="trending-watches-tab" role="tabpanel" aria-labelledby="trending-watches-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <span className="product-label label-circle label-sale">Sale</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-14.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Cell Phone</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Google - Pixel 3 XL  128GB</a></h3>
                                            <div className="product-price">
                                                <span className="new-price">$35.41</span>
                                                <span className="old-price">Was $41.67</span>
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 10 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-12.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Audio</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                            <div className="product-price">
                                                $79.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-13.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Tablets</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane p-0 fade" id="trending-acc-tab" role="tabpanel" aria-labelledby="trending-acc-link">
                                <div className="owl-carousel owl-full carousel-equal-height carousel-with-shadow" data-toggle="owl" 
                                    data-owl-options='{
                                        "nav": true, 
                                        "dots": false,
                                        "margin": 20,
                                        "loop": false,
                                        "responsive": {
                                            "0": {
                                                "items":2
                                            },
                                            "480": {
                                                "items":2
                                            },
                                            "768": {
                                                "items":3
                                            },
                                            "992": {
                                                "items":4
                                            }
                                        }
                                    }'>
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-15.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">TV & Home Theater</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Samsung - 55" Class  LED 2160p Smart</a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 5 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-top">Top</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-11.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Laptops</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">MacBook Pro 13" Display, i5</a></h3>
                                            <div className="product-price">
                                                $1,199.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "100%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-12.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Audio</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Bose - SoundLink Bluetooth Speaker</a></h3>
                                            <div className="product-price">
                                                $79.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "60%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 6 Reviews )</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <span className="product-label label-circle label-new">New</span>
                                            <a href="product.html">
                                                <img src="assets/images/demos/demo-3/products/product-13.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-dark">
                                                <a href="#" className="btn-product btn-cart" title="Add to cart"><span>add to cart</span></a>
                                                <a href="popup/quickView.html" className="btn-product btn-quickview" title="Quick view"><span>quick view</span></a>
                                            </div>
                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Tablets</a>
                                            </div>
                                            <h3 className="product-title"><a href="product.html">Apple - 11 Inch iPad Pro  with Wi-Fi 256GB </a></h3>
                                            <div className="product-price">
                                                $899.99
                                            </div>
                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{width: "80%"}}></div>
                                                </div>
                                                <span className="ratings-text">( 4 Reviews )</span>
                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" style={{background: "#edd2c8"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" style={{background: "#eaeaec"}}><span className="sr-only">Color name</span></a>
                                                <a href="#" className="active" style={{background: "#333333"}}><span className="sr-only">Color name</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more-container text-center mt-3 mb-0">
                        <a href="#" class="btn btn-outline-dark-2 btn-round btn-more"><span>Shop more Outlet deals</span><i class="icon-long-arrow-right"></i></a>
                    </div>
            </div>
            <div className="container">
                <hr className="mt-5 mb-0" />
                
            </div>
        </>
    )

}

export default TrendingProduct;