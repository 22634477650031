import React from "react";
import Banner from "../assets/images/page-header-bg.jpg";

const ShippingReturn = () => {
  return (
    <>
      <main className="main">
        <div
          className="page-header text-center"
          style={{ backgroundImage: `url(${Banner})` }}
        >
          <div className="container">
            <h1 className="page-title">Shipping & Returns</h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Pages</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Shipping Returns
              </li>
            </ol>
          </div>
        </nav>

        <div className="page-content pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="about-text text-center mt-3">
                  <h2 className="title text-center mb-2">Shipping, Cancellations, Refunds & Returns</h2>
                  <p>
                    Read the following policy to understand how Trend Haul uses
                    the personal information you submit. By using any of Trend
                    Haul's services implies that you agree to these terms.
                    <br />
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                    Return Policy
                    </b>
                    <br />
                    <ul >
                        <li>We do not accept returns from you or from your buyer. All items shipped out are considered sold to you and if your buyer changes his mind or dislikes the item (or its quality), then it depends on your own store policy whether to accept returns from them or not. This is between you and your buyer only. Please do not use our warehouse address as the return address.</li>
                        <li>The only exception is in the case of RTO (Return to Origin) by the courier. This could be due to undeliverable address or refusal of package. The package must be unopened and with its original address label. We will refund you 100% if we receive the physical package or if the courier’s online tracking information indicates that it has been returned to us.</li>
                        <li>We only entertain the requests for replacement, return or refund (in case of damaged, defective and wrong product) only if the request is raised with appropriate proof within 10 calendar days of receiving the product. in other words, we SHALL NOT entertain any requests for replacement, return or refund after 10 calendar days from the date of delivery of the product.</li>
                    </ul>
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                    Refund Policy
                    </b>
                    <br />
                        <ul>
                            <li>
                            Although we do not accept returns, but we will refund you 100%, which you will received immediately if your buyer can provide enough proof for the following situations:
                            </li>
                            <li>
                            Damaged upon receipt.
                            </li>
                            <li>
                            Defective item.
                            </li>
                            <li>
                            Shipped wrong item, wrong color, or wrong size, etc.
                            </li>
                            <li>
                            Lost or very late delivery (exceeding 20 days).
                            </li>
                            <li>
                            We will partial refund you or reship for the following situations:
                            </li>
                            <li>
                            Shipped wrong quantity.
                            </li>
                            <li>
                            Missing item in order. Please note that sometimes we may split an order into multiple packages and we need to verify that all packages are delivered before concluding that an item is missing.
                            </li>
                            <li>
                            The courier’s tracking information is our only proof that a package is delivered or not. We will refund you 100% if it indicates failure of delivery or RTO.
                            </li>
                            <li>
                            On the other hand, we will not refund you at all if it indicates successful delivery, even if your buyer says that he has not received it.
                            </li>
                            <li>
                            We process refund by crediting to your Trend Haul E-Wallet.
                            </li>
                        </ul>
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                    Cancellation Policy
                    </b>
                    <br />
                        <ul>
                            <li>
                            We process orders very quickly and it may not be possible to intercept an order for cancellation.
                            </li>
                            <li>
                            So once you will make the payment of the order, we will deny the cancellation and proceed with shipping and no refund.
                            </li>
                            <li>
                            However, a workaround is to ask your buyer to refuse the package at the time of delivery and we will refund you 100% if there is proof of “RTO Delivered”.
                            </li>
                        </ul>
                    <br />
                    <b style={{ fontSize: "20px", fontWeight: "700" }}>
                    Cancel Membership
                    </b>
                    <br />
                        <ul>
                            <li>You may downgrade to the free “Starter Plan” at any time and we will not charge you subsequently. We do not provide partial refund for unused portion of the Monthly Plan. The balance in your E-Wallet can be returned to you after settlement of all orders.</li>
                        </ul>
                    <br />
                    
                  </p>
                 
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2"></div>
        </div>
      </main>
    </>
  );
};
export default ShippingReturn;
