import React, {useEffect} from 'react';
import { useFormik } from 'formik';
import { SignupSchema } from './ValidationSchema';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { LoginAction, signupAction } from '../actions/LoginAction';
import { useNavigate } from 'react-router-dom';

const initialValues  = {
    first_name : '',
    last_name:'',
    email : '',
    password : '',
    is_vendor : false
}

const Signup = ()=>{
    const dispatch = useDispatch();
    const loginState = useSelector((state)=>state.LoginReducer);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {values, errors, touched, handleSubmit,handleBlur,handleChange} = useFormik({
        initialValues : initialValues,
        validationSchema : SignupSchema,
        onSubmit : (values,action)=>{
            console.log(values);
            dispatch(signupAction(values));
            action.resetForm();
        }
    });
    // useEffect(()=>{
    //     // if(loginState.hit !== showToast ){
    //     //     setToast(loginState.hit);
            
    //     //     // cookies.set('myCat', 'Pacman', { path: '/' });
    //     //     cookies.set('bearerToken', loginState.token, { path: '/'});
           
    //     //     setTimeout(()=>{
    //     //         if(cookies.get('bearerToken')!==undefined || cookies.get('bearerToken')!==null){
    //     //             if(loginState.flag === 'SUCCESS'){
                        
    //     //                 setRedirect(true);
    //     //             }
                       
    //     //         else
    //     //             setToast(false);
    //     //         }
    //     //     },3000)
    //     // }else{
    //     //     setTimeout(()=>{
    //     //         setToast(false);
    //     //     },3000)
    //     // }
    //     if(loginState.hit === true && loginState.token === null){
    //         cookies.set('loginId', loginState.user_id, { path: '/' })
    //         navigate(`/login`, {replace : true});
    //     }
        
    // },[loginState]);
    return(
        <>
            
							    	<form onSubmit={handleSubmit}>
                                        <div className="form-group">
							    			<label for="register-email-2">First Name *</label>
							    			<input 
                                                type="text" 
                                                className="form-control" 
                                                id="first-name" 
                                                name="first_name" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required/>
                                            {
                                                errors.first_name && touched.first_name ? (<span className='loginErrors'>{errors.first_name}</span>) : null
                                            }
							    		</div>
                                        <div className="form-group">
							    			<label for="register-email-2">Last Name *</label>
							    			<input 
                                                type="text" 
                                                className="form-control" 
                                                id="first-name" 
                                                name="last_name" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required/>
                                            {
                                                errors.last_name && touched.last_name ? (<span className='loginErrors'>{errors.last_name}</span>) : null
                                            }
							    		</div>
							    		<div className="form-group">
							    			<label for="register-email-2">Your email address *</label>
							    			<input 
                                                type="email" 
                                                className="form-control" 
                                                id="register-email-2" 
                                                name="email" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required/>
                                            {
                                                errors.email && touched.email ? (<span className='loginErrors'>{errors.email}</span>) : null
                                            }
							    		</div>

							    		<div className="form-group">
							    			<label for="register-password-2">Password *</label>
							    			<input 
                                                type="password" 
                                                className="form-control" 
                                                id="register-password-2" 
                                                name="password" 
                                                onChange={handleChange}
                                                onBlur = {handleBlur}
                                                required />
                                            {
                                                errors.password && touched.password ? (<span className='loginErrors'>{errors.password}</span>) : null
                                            }
							    		</div>

							    		<div className="form-footer">
                                            
							    			<button type="submit" className="btn btn-outline-primary-2">
			                					<span>SIGN UP</span>
			            						<i className="icon-long-arrow-right"></i>
			                				</button>

			                				<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="register-policy-2" required />
												<label className="custom-control-label" for="register-policy-2">I agree to the <a href="#">privacy policy</a> *</label>
											</div>
							    		</div>
							    	</form>
							    	<div className="form-choice">
								    	<p className="text-center">or sign in with</p>
								    	<div className="row">
								    		<div className="col-sm-6">
								    			<a href="#" className="btn btn-login btn-g">
								    				<i className="icon-google"></i>
								    				Login With Google
								    			</a>
								    		</div>
								    		<div className="col-sm-6">
								    			<a href="#" className="btn btn-login  btn-f">
								    				<i className="icon-facebook-f"></i>
								    				Login With Facebook
								    			</a>
								    		</div>
								    	</div>
							    	</div>
        </>
    )
}

export default Signup