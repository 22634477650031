import React from "react";
import './Loader.css';


const Loader = ()=>{

    return(
        <>
            <div id="cover-spin">
                <div class="circle-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                </div>
            </div>
        </>
    )
}

export default Loader;