import Home from './Home';
import Footer from './Pages/Footer';
import Header from './Pages/Header';
import Cart from './Cart';
import Wishlist from './Wishlist';
import Savelist from './Savelist';
import ProductList from './ProductList';
import SearchList from './SearchList';
import TC from './TermsConditions';
import AboutUs from './AboutUs';
import Product from './Product';
import Checkout from './Pages/Checkout';
import PrivacyPolicy from './PrivacyPolicy';
import ShippingReturn from './ShippingReturns';
import {Routes, Route } from 'react-router-dom';
import Profile from './Profile';
import {useEffect, useState} from 'react';
import {useSelector,useDispatch} from "react-redux";
import { Counter } from '../actions/General';


const  MainApp = ()=>{
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();
  const Counts = useSelector(state=>state.CountReducer);
  const loggedUser = useSelector(state=>state.LoginReducer);
  useEffect(()=>{
      dispatch(Counter(loggedUser.token, loggedUser.is_vendor))
  },[])
  return (
    <>
                  <Header counter={Counts} />
                 
                      <Routes>
                      <Route path="/" element={<Home/>} />
                          <Route path="/home" element={<Home/>} />
                          <Route path="/cart" element={<Cart/>} />
                          <Route path="/wishlist" element={<Wishlist/>} />
                          <Route path="/savelist" element={<Savelist/>} />
                          <Route path="/productlist" element={<ProductList/>} />
                          <Route path="/productlist/:categoryId" element={<ProductList/>} />
                          <Route path="/searchlist/:input" element={<SearchList/>} />
                          <Route path="/checkout" element={<Checkout/>} />
                          <Route exact={true} path="/product/:productId" element={<Product/>} />
                          <Route exact={true} path='/TermsCondition' element={<TC/>} />
                          <Route exact={true} path='/AboutUs' element={<AboutUs/>} />
                          <Route path='/profile' element={<Profile/>}/>
                          <Route path='/privacy' element={<PrivacyPolicy/>}/>
                          <Route path='/shipping' element={<ShippingReturn/>} />
                      </Routes>
                  <Footer className="footer" />
    </>
  );
}

export default MainApp;
