import * as Yup from 'yup';


export const LoginSchema = Yup.object({
    email : Yup.string().email().required("Please Enter Email"),
    password : Yup.string().min(5).max(25).required("Please Enter Password")
});

export const SignupSchema = Yup.object({
    first_name : Yup.string().min(5).required("Please Enter you First name"),
    last_name : Yup.string().min(5).required("Please Enter you Last name"),
    email : Yup.string().email().required("Please Enter Email"),
    password : Yup.string().min(5).max(25).required("Please Enter Password")
});

export const VendorSignupSchema = Yup.object({
    first_name : Yup.string().min(5).required("Please Enter you First name"),
    last_name : Yup.string().min(5).required("Please Enter you Last name"),
    email : Yup.string().email().required("Please Enter Email"),
    password : Yup.string().min(5).max(25).required("Please Enter Password"),
    repeat_password : Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required("Please Enter Password"),
    company_name : Yup.string().min(5).max(25).required("Please Enter Company"),
    gst_number : Yup.string().min(5).max(25).required("Please Enter GST Number"),
    referral_code : Yup.string().min(5).max(25)
});

export const AddressSchema = Yup.object({
    house_no : Yup.string().required("Please Enter you House Number"),
    locality: Yup.string().required("Please Enter Locality"),
    city : Yup.string().min(5).required("Please Enter you city"),
    state : Yup.string().required("Please Enter state"),
    country : Yup.string().required("Please Enter state"),
    pincode : Yup.string().max(6).required("Please Enter Pincode"),
    address : Yup.string().max(10).required("Please Enter Address"),
    // email : Yup.string().email().required("Please Enter email")
});