import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MainApp from './Components/MainApp';
import Login from './Components/Login';
import VendorLogin from './Components/VendorLogin';
// import AdminApp from './Components/admin/AdminApp';


function App() {
  return (
    <Router>
        <div className="App">
            <Routes>
              <Route path="/login" element={<Login/>} />
              <Route path="/vendorLogin" element={<VendorLogin/>} />
              {/* <Route path='/admin' element={<AdminApp/>} /> */}
              <Route path="*" element={<MainApp/>} />
            </Routes>
        </div>
    </Router>
    
  );
}

export default App;
