import React,{useState,useEffect}from 'react';
import Banner from '../assets/images/page-header-bg.jpg';
import Product1 from '../assets/images/products/table/product-1.jpg';
import EmptyWishlist from '../assets/images/emptyWishlist.png';
import { useSelector,useDispatch } from 'react-redux';
import config from '../actions/axiosConfig';
import Toaster from "./Toaster";
import { showToasterAction  } from '../actions/ToasterActions';
import { DeleteWishlist } from '../actions/WishlistDetailAction';
import { AddToCart, AddToItems } from '../actions/AddToCart';
import { cartDetailsAction } from '../actions/CartDetailAction';
import { Counter } from '../actions/General';


const Wishlist = ()=>{

	const [wishlist,setWishlist] = useState([]);
	const dispatch = useDispatch();
	const Wishlist = useSelector(state=>state.WishlistDetailReducer);
	const loggedUser = useSelector(state=>state.LoginReducer);
	const toaster = useSelector(state => state.toasterReducer);
	const [sureDelete, setDelete] = useState({check : false, point : ''});

    console.log(Wishlist);
	useEffect(()=>{

        if(loggedUser.token !==null){
            console.log(loggedUser.token);
            config.get('user/getWishlistDetails',
            {
                headers :  {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                console.log(res.data);
                setWishlist(res.data.data);
            }).catch(err=>{
                console.log(err);
            })

        }else{
			setWishlist(Wishlist.product)
		}


    },[])

	useEffect(()=>{
        if(toaster.show === true){
            let toasterDebounce = setTimeout(()=>{
                dispatch(showToasterAction(false,'',''))
              },1000)
              return ()=> clearTimeout(toasterDebounce);
        }
    }, [toaster])

	const addToCart = (data)=>{
        if(loggedUser.token !==null){
          
            let cartDebouce = setTimeout(()=>{
              if(loggedUser.is_vendor === 1){
                  dispatch(AddToItems(data, loggedUser.user_id, loggedUser.token));
                //   dispatch(showToasterAction(true,'Added To Item','SUCCESS'))
              }else{
                  dispatch(AddToCart([data],1, loggedUser.user_id, loggedUser.token));
                //   dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
              }
              
            },1000)
			setTimeout(()=>{
				removeProduct(data.id)
			},1000)
            return ()=> clearTimeout(cartDebouce);
        }else{
            console.log(data);
            dispatch(cartDetailsAction(data,1));
			setTimeout(()=>{
				removeProduct(data.id)
			},1000)
            // dispatch(showToasterAction(true,'Added To Cart','SUCCESS'))
          }
       
    }
	
	const removeProduct = (id)=>{
        if(loggedUser.token !==null){
            config.delete(`/user/deleteProductFromwishlist/${id}`,{
                headers : {
                    'Authorization' : 'Bearer '+loggedUser.token
                }
            }).then(res=>{
                console.log(res.data);
				let point = wishlist.findIndex(x=>x.id === id);
                wishlist.splice(point,1);
				dispatch(Counter(loggedUser.token, loggedUser.is_vendor));
                dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
                setDelete({check : true, point : id});
                
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            console.log(id);
            setDelete(true);
            dispatch(DeleteWishlist(id)); 
        }
    }
    return(
        <>
			{
                toaster.show === true ? <Toaster /> : null
            }
             <main className="main">
        	<div className="page-header text-center" style={{backgroundImage: `url(${Banner})`}}>
        		<div className="container">
        			<h1 className="page-title">Wishlist<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="#">Shop</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                    </ol>
                </div>
            </nav>
			{
                wishlist.length>0 ? 
					<div className="page-content">
						<div className="container">
							<table className="table table-wishlist table-mobile">
								<thead>
									<tr>
										<th>Product</th>
										<th>Price</th>
										<th>Stock Status</th>
										<th></th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									{
										wishlist.map((data,key)=>{
											return(
												<tr key={key} className = {sureDelete.check && sureDelete.point === data.id ? 'rowfade' : ''}>
													<td className="product-col">
														<div className="product">
															<figure className="product-media">
																<a href="#">
																	<img src={data.s3_url[0]} alt="Product image" />
																</a>
															</figure>

															<h3 className="product-title">
															<p style={{width : '100px'}}>{data.name}</p>
															</h3>
														</div>
													</td>
													<td className="price-col">&#x20B9;{data.FinalPrice}</td>
													<td className="stock-col"><span className="in-stock">
														{data.packQty>0 ? 'In stock' : 'Out Of Stock'}
													</span></td>
													<td className="action-col">
														<div className="dropdown">
														<button className="btn btn-block btn-outline-primary-2" onClick={()=>{addToCart(data)}} aria-haspopup="true" aria-expanded="false">
															<i className="icon-shopping-cart" ></i>Add To Cart
														</button>

														{/* <div className="dropdown-menu">
															<a className="dropdown-item" href="#">First option</a>
															<a className="dropdown-item" href="#">Another option</a>
															<a className="dropdown-item" href="#">The best option</a>
														</div> */}
														</div>
													</td>
													<td className="remove-col">
														<button 
															className="btn-remove"
															onClick={()=>removeProduct(data.id)}
														><i className="icon-close"></i></button></td>
												</tr>
												
											
											)
										})
									}
									
								</tbody>
							</table>
						</div>
					</div>
				: 	<div className="page-content">
						<div className="container">
							<img src={EmptyWishlist} alt='No Product in Wishlish' style={{marginLeft : "20%"}}/>
						</div>
					</div>
				
			}
            
        </main>
        </>
    )
}

export default Wishlist