import { ADDTOCART, ADDTOSAVE } from "../actions";

const initial = {
    msg : ''
}

export const AddToCartReducer = (state = initial, action)=>{

    switch(action.payload){
        case ADDTOCART:
            return {
                ...state,
                msg : action.payload.msg
            }
            break;
        case ADDTOSAVE:
                return {
                    ...state,
                    msg : action.payload.msg
                }
                break;
        default : return state;
            break;
    }

}