import { WISHLISTDETAIL, ADDTOWISHLIST, DELETEWISHLIST } from "../actions";

const initial = {
    product : [],
    msg : '',
    flag : false,

}

export const WishlistDetailReducer = (state = initial, action)=>{
    switch (action.type) {
        case WISHLISTDETAIL:
            console.log(action.payload.product.id);
            let index = state.product.findIndex(x=>x.id === action.payload.product.id);
            console.log(index);
            if(index !== -1){
               
                return{
                    ...state,
                }
            }else{
                return{
                    ...state,
                    product : [...state.product,action.payload.product],
                    flag : true
                }
            }
        case ADDTOWISHLIST:
            return {
                    ...state,
                    product : [],
                    msg : action.payload.msg
                }
        case DELETEWISHLIST : 
            let point = state.product.findIndex(x=>x.id === action.payload.product_id);
            state.product.splice(point,1);
            return {
                ...state
            }
                break;
        default: return state;
            break;
    }
}