import React from 'react';
import trendLogo from '../../assets/images/thLogo.png';



const Footer = ()=>{

    return(
        <>
             <footer className="footer">
        	<div className="footer-middle">
	            <div className="container">
	            	<div className="row">
	            		<div className="col-sm-6 col-lg-3">
	            			<div className="widget widget-about">
	            				<img src={trendLogo} className="footer-logo" alt="Footer Logo" width="105" height="25" />
	            				<p style={{textAlign:'initial'}}>Welcomee to Trendhaul, where innovation meets convenience in the world of online shopping. As a pioneering e-commerce platform, Trendhaul stands at the forefront of the industry, offering an extensive array of products spanning diverse categories.  </p>
								</div>
							</div>

							<div className="col-sm-6 col-lg-3">
								<div className="widget">
									<h4 className="widget-title">Useful Links</h4>

									<ul className="widget-list">
										<li><a href="/AboutUs">About Trenhaul</a></li>
										<li><a href="/TermsCondition">Terms & Condition</a></li>
										<li><a href="/privacy">Privacy Policy</a></li>
										{/* <li><a href="contact.html">Contact us</a></li> */}
									</ul>
								</div>
							</div>

							<div className="col-sm-6 col-lg-3">
								<div className="widget">
									<h4 className="widget-title">Customer Service</h4>

									<ul className="widget-list">
										{/* <li><a href="#">Payment Methods</a></li>
										<li><a href="#">Returns</a></li> */}
										<li><a href="/shipping">Shipping</a></li>
									</ul>
								</div>
							</div>

							<div className="col-sm-6 col-lg-3">
								<div className="widget">
									<h4 className="widget-title">My Account</h4>

									<ul className="widget-list">
										{/* <li><a href="#">Sign In</a></li> */}
										<li><a href="/cart">View Cart</a></li>
										<li><a href="/wishlist">My Wishlist</a></li>
										{/* <li><a href="#">Help</a></li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom">
					<div className="container">
						<p className="footer-copyright">Copyright © 2023 Trendhaul. All Rights Reserved.</p>
						{/* <figure className="footer-payments">
							<img src="assets/images/payments.png" alt="Payment methods" width="272" height="20"/>
						</figure> */}
					</div>
				</div>
			</footer>
			
		
        </>
    )

}

export default Footer;