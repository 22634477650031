import { ADDTOCART, ADDTOSAVE, ADDTOSAVELIST, ADDTOWISHLIST } from ".";
import config from "./axiosConfig";
import { emptyCartDetailsAction } from "./CartDetailAction";
import { showToasterAction  } from '../actions/ToasterActions';

export const AddToCart = (products,qty, userId,token)=>{
    // add to cart for a single product for signed in user
    // console.log(products,qty,userId,token);
    // console.log(products[0].id);
    
    let cartItems = {
        "user_id" : Number(userId),
        "items" : [{
            "product_id": products[0].id,
            "quantity": qty
        }]
    }
    // console.log(cartItems)
    return dispatch=>{
        
        config.post('/user/addToCart',cartItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            console.log(res);
            
            dispatch({
                type : ADDTOCART,
                payload : {
                    msg : res.data.message
                }
            })
            dispatch(emptyCartDetailsAction());
            dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            ;
        }).catch(err=>{
            console.log(err);
        })

       
    }
}

export const AddToItems = (products, userId, token)=>{
   // Add single product in savelist for vendor signed in first time.
    // let productArray  = products.product.map((data)=>{
    //     return {
    //         "product_id": data.id
    //     }
    // });
    
    let saveItems = {
        "vendor_id" : Number(userId),
        "products" : [{
            "product_id": products[0].id
        }]
    }
    return dispatch=>{
        
        config.post('/user/addSavelist',saveItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            // console.log(res);
           
            // dispatch({
            //     type : ADDTOSAVELIST,
            //     payload : {
            //         msg : res.data.message
            //     }
            // })
            dispatch({
                type : ADDTOWISHLIST,
                payload : {
                    msg : res.data.message
                }
            })
            dispatch(emptyCartDetailsAction());
            dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            ;
        }).catch(err=>{
            console.log(err);
        })

       
    }
}
// Adding products from savelist to cart for vendor
export const AddSavelistToCart = (products, userId,token)=>{
    // Add to cart for all the product when user signed in first time.
    let productArray  = products.map((data)=>{
        // console.log(data);
        // console.log(data.id)
        return {
            "product_id": data.id,
            "quantity": 1
        }
    });
    
    let cartItems = {
        "user_id" : Number(userId),
        "items" : productArray
    }
    // console.log(products,userId,token);
    // console.log(cartItems);
    return dispatch=>{
        
        config.post('/user/addToCart',cartItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            // console.log(res);
            
            dispatch({
                type : ADDTOCART,
                payload : {
                    msg : res.data.message
                }
            })
            // dispatch(emptyCartDetailsAction());
            dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            ;
        }).catch(err=>{
            console.log(err);
        })

       
    }
}
export const AddAllToCart = (products, userId,token)=>{
    // Add to cart for all the product when user signed in first time.
    let productArray  = products.map((data)=>{
        // console.log(data);
        // console.log(data.id, data.quantity)
        return {
            "product_id": data.id,
            "quantity": data.quantity
        }
    });
    
    let cartItems = {
        "user_id" : Number(userId),
        "items" : productArray
    }
    // console.log(products,userId,token);
    // console.log(cartItems);
    return dispatch=>{
        
        config.post('/user/addToCart',cartItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            // console.log(res);
            
            dispatch({
                type : ADDTOCART,
                payload : {
                    msg : res.data.message
                }
            })
            dispatch(emptyCartDetailsAction());
            // dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            ;
        }).catch(err=>{
            console.log(err);
        })

       
    }
}

export const AddAllToItems = (products, userId, token)=>{
   
    // Add all the product in savelist for vendor signed in first time.
    let productArray  = products.map((data)=>{
        return {
            "product_id": data.id
        }
    });
    
    let saveItems = {
        "vendor_id" : Number(userId),
        "products" : productArray
    }
    return dispatch=>{
        
        config.post('/user/addSavelist',saveItems,{
            headers :  {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+token
            }
        }).then(res=>{
            // console.log(res);
           
            dispatch({
                type : ADDTOSAVE,
                payload : {
                    msg : res.data.message
                }
            })
            dispatch(emptyCartDetailsAction());
            // dispatch(showToasterAction(true,res.data.message,'SUCCESS'))
            ;
        }).catch(err=>{
            console.log(err);
        })

       
    }
}


