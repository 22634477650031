import React,{useEffect, useState} from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {useSelector,useDispatch} from "react-redux";
import trendLogo from '../../assets/images/thLogo.png';
import config from '../../actions/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { LogoutAction } from '../../actions/LoginAction';
import Skeleton from 'react-loading-skeleton';
import { Counter } from '../../actions/General';

// import Toaster from "./Toaster";

const Header = ({counter})=>{
    const [ipAddr, setIp] = useState('');
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [childCategories, setChildCategories] = useState([]);
    const [childFlag, setChildFlag] = useState({state : false, id:''});
    const [searchInput, setSearchInput] = useState('');
    const loggedUser = useSelector(state=>state.LoginReducer);
    const Cart = useSelector(state=>state.CartDetailReducer);
    const Wishlist = useSelector(state=>state.WishlistDetailReducer);
    const Counts = useSelector(state=>state.CountReducer);
    const [Count, setCount] = useState(counter.data);


    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIp(res.data.ip);
        cookies.set('userIP',res.data.ip, { path: '/'});
      };
    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
        config.post("user/getCategories")
        .then(res=>{
            
            setCategories(res.data.data)
        }).catch(err=>{
            console.log(err);
        })

        // For cart counter
        if(loggedUser.token !==null){
            // console.log(loggedUser.token);
            // if(loggedUser.is_vendor>0){
            //     config.get('user/getSavelistDetails',
            //     {
            //         headers :  {
            //             'Access-Control-Allow-Origin': '*',
            //             'Authorization': 'Bearer '+loggedUser.token
            //         }
            //     }).then(res=>{
            //         console.log(res.data.data.length)
            //         setCount(res.data.data.length);
            //     }).catch(err=>{
            //         console.log(err);
            //     })
            // }else{
                // config.get('user/getCartWishlistCount',
                // {
                //     headers :  {
                //         'Access-Control-Allow-Origin': '*',
                //         'Authorization': 'Bearer '+loggedUser.token
                //     }
                // }).then(res=>{
                //     console.log(res.data);
                //     setCount(res.data.Count_Details);
                // }).catch(err=>{
                //     console.log(err);
                // })
                // console.log('comming');
                // dispatch(Counter(loggedUser.token))
                setCount(counter.data)
            // }
            

        }

    }, [counter]);
    useEffect(() => {
        //passing getData method to the lifecycle method

        if(childFlag.state === true){
            config.post("user/getChildCategories",{
                "parent_id" : childFlag.id
            },{
                headers :  {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+loggedUser.token
                }
            })
            .then(res=>{
                // console.log(res.data.data);
                setChildCategories(res.data.data)
            }).catch(err=>{
                console.log(err);
            })
        }

    }, [childFlag]);

    useEffect(()=>{
        if(loggedUser.type === "Logout")
        {
            let quantityDebounce = setTimeout(()=>{
                // console.log("abc");
                navigate("/login");
               
              },1000)
              return ()=> clearTimeout(quantityDebounce);
            
        }
       
    },[loggedUser.type])

    const Logout = ()=>{
        dispatch(LogoutAction());
    }

    const categoryProductList = (cid)=>{
        let id = btoa(cid)
        console.log(cid);

        navigate(`/productlist/${id}`, {replace : true});
    }
    const searchProduct = ()=>{
        // alert('clicked')
        let id = btoa(searchInput)
        console.log(searchInput);
        
        navigate(`/searchlist/${id}`, {replace : true});
    }

    const handleChange = (e)=>{
        console.log(e.target.value)
        setSearchInput(e.target.value);
    }
    // console.log(Count);
    // console.log(Counts);
    return(
        <>
            <header className="header header-intro-clearance header-3">
            <div className="header-top">
                <div className="container">
                    {/* <div className="header-left">
                        <a href="tel:#"><i className="icon-phone"></i>Call: +0123 456 789</a>
                    </div> */}

                    <div className="header-right">

                        <ul className="top-menu">
                            <li>
                                <a href="#">Links</a>
                                <ul>
                                    {/* <li>
                                        <div className="header-dropdown">
                                            <a href="#">USD</a>
                                            <div className="header-menu">
                                                <ul>
                                                    <li><a href="#">Eur</a></li>
                                                    <li><a href="#">Usd</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li> */}
                                    {/* <li>
                                        <div className="header-dropdown">
                                            <a href="#">English</a>
                                            <div className="header-menu">
                                                <ul>
                                                    <li><a href="#">English</a></li>
                                                    <li><a href="#">French</a></li>
                                                    <li><a href="#">Spanish</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li> */}
                                    <li>
                                       
                                            {
                                                loggedUser.token !=null ? 
                                                    <div className="header-dropdown">
                                                        <a href="#" style={{color:'white'}}><i className="icon-user"></i>Hi {loggedUser.name}</a>
                                                        <div className="header-menu">
                                                            <ul>
                                                                <li><a href="/profile">Profile</a></li>
                                                                <li>
                                                                    <a href="#" onClick={Logout}>
                                                                         Logout
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> 
                                                : 
                                                    <div className="header-dropdown">
                                                        <a href="#" style={{color:'white'}}><i className="icon-user"></i>Sign in / Sign up</a>
                                                        <div className="header-menu">
                                                            <ul>
                                                                <li><a href="/login">User Login</a></li>
                                                                <li><a href="/vendorLogin">Vendor Login</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                            }
                                           
                                       
                                    </li>
                                
                                </ul>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className="header-middle">
                <div className="container">
                    <div className="header-left">
                        <button className="mobile-menu-toggler">
                            <span className="sr-only">Toggle mobile menu</span>
                            <i className="icon-bars"></i>
                        </button>
                        
                        <a href="/" className="logo">
                            <img src={trendLogo} alt="Molla Logo" width="105" height="25" />
                        </a>
                    </div>
                    <div className="header-center">
                        <div className="header-search header-search-extended header-search-visible d-none d-lg-block">
                            {/* <a href="#" className="search-toggle" role="button"><i className="icon-search"></i></a> */}
                            <form onSubmit={searchProduct}>
                                <div className="header-search-wrapper search-wrapper-wide">
                                    {/* <label htmlFor="q" className="sr-only">Search</label> */}
                                    <button 
                                        className="btn btn-primary" 
                                        type="submit"
                                    ><i className="icon-search"></i></button>
                                    <input 
                                        type="search" 
                                        className="form-control" 
                                        placeholder="Search product ..." 
                                        required
                                        onChange={(e)=>{handleChange(e)}}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="header-right">
                        {/* <div className="dropdown compare-dropdown">
                            <a href="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Compare Products" aria-label="Compare Products">
                                <div className="icon">
                                    <i className="icon-random"></i>
                                </div>
                                <p>Compare</p>
                            </a>

                            <div className="dropdown-menu dropdown-menu-right">
                                <ul className="compare-products">
                                    <li className="compare-product">
                                        <a href="#" className="btn-remove" title="Remove Product"><i className="icon-close"></i></a>
                                        <h4 className="compare-product-title"><a href="product.html">Blue Night Dress</a></h4>
                                    </li>
                                    <li className="compare-product">
                                        <a href="#" className="btn-remove" title="Remove Product"><i className="icon-close"></i></a>
                                        <h4 className="compare-product-title"><a href="product.html">White Long Skirt</a></h4>
                                    </li>
                                </ul>

                                <div className="compare-actions">
                                    <a href="#" className="action-link">Clear All</a>
                                    <a href="#" className="btn btn-outline-primary-2"><span>Compare</span><i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div> */}

                        <div className="wishlist">
                           
                                    {
                                        loggedUser.is_vendor>0 
                                        ?   <>
                                                 <a href="/savelist" title="Savelist">
                                                    <div className="icon">
                                                        <i class="icon-star-o"></i> 
                                                        <span className="wishlist-count badge">
                                                            {
                                                                loggedUser.token !== null ? Count.Savelist_Count : Wishlist.product.length
                                                            }
                                                        </span>
                                                    </div>
                                                    <p>Savelist</p>
                                                </a>
                                            </> 
                                        :  <>
                                                 <a href="/wishlist" title="Wishlist">
                                                    <div className="icon">
                                                        <i className="icon-heart-o"></i>
                                                        <span className="wishlist-count badge">
                                                            {
                                                                loggedUser.token !== null ? Count.wishlist_Count : Wishlist.product.length
                                                            }
                                                        </span>
                                                    </div>
                                                    <p>Wishlist</p>
                                                </a>
                                            </>
                                    }
                                   
                                    
                               
                                
                           
                        </div>

                        <div className="dropdown cart-dropdown">
                            <a href="/cart" className="dropdown-toggle" role="button">
                                <div className="icon">
                                    <i className="icon-shopping-cart"></i>
                                    <span className="cart-count">
                                        {
                                            loggedUser.token !==null ? Count.Cart_Count : Cart.product.length
                                        }
                                        </span>
                                </div>
                                <p>Cart</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-bottom sticky-header">
                <div className="container">
                    <div className="header-left">
                        <div className="dropdown category-dropdown">
                            <a href="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Browse Categories" >
                                Browse Categories <i className="icon-angle-down"></i>
                            </a>

                            <div className="dropdown-menu" >
                                <nav className="side-nav" >
                                    <ul className="menu-vertical sf-arrows">
                                        {
                                            categories.length>0
                                            ? categories.map((data,key)=>{
                                                    return(
                                                        <li key={key}>
                                                            <a 
                                                                href="#" 
                                                                className="sf-with-ul"
                                                                onMouseEnter={()=>setChildFlag({state : true, id : data.sunsky_id})}
                                                                onClick={()=>{categoryProductList(data.sunsky_id)}}
                                                            >{data.name}</a>
                                                            {
                                                                childCategories.length>0
                                                                ? 
                                                                            <div className="megamenu megamenu-sm">
                                                                                <div className="row no-gutters">
                                                                                    <div className="col-md-6">
                                                                                        <div className="menu-col">
                                                                                            <div className="menu-title">Sub Categories</div>
                                                                                            <ul>
                                                                                                {
                                                                                                    childCategories.map((data,key)=>{
                                                                                                        return(
                                                                                                            <li 
                                                                                                                key={key}
                                                                                                                onClick={()=>{categoryProductList(data.sunsky_id)}}
                                                                                                            ><a href="#">{data.name}</a></li>
                                                                                                        )})
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                
                                                                                    <div className="col-md-6">
                                                                                        <div className="banner banner-overlay">
                                                                                            <a href="category.html">
                                                                                                <img src="assets/images/demos/demo-19/menu/banner-2.jpg" alt="Banner" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    
                                                                :  <div className="megamenu megamenu-sm">
                                                                        <div className="row no-gutters">
                                                                            <div className="col-md-6">
                                                                                <div className="menu-col">
                                                                                    <div className="menu-title">Sub Categories</div>
                                                                                    <ul>
                                                                                        {/* {
                                                                                            Array(8).fill(0).map((data,key)=>{
                                                                                                return( */}
                                                                                                    <li 
                                                                                                        key={key}
                                                                                                        onClick={()=>{categoryProductList(data.sunsky_id)}}
                                                                                                    ><Skeleton count={15} style={{marginBottom:"10%"}}/></li>
                                                                                                {/* )})
                                                                                        } */}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <div className="banner banner-overlay">
                                                                                    <a href="category.html">
                                                                                        <img src="assets/images/demos/demo-19/menu/banner-2.jpg" alt="Banner" />
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            
                                                        </li>
                                                    )
                                                })
                                            : <li>
                                            <a 
                                                href="#" 
                                                className="sf-with-ul"
                                            >
                                                <Skeleton count={15} style={{marginBottom: "10%"}} />
                                            </a>
                                            
                                        </li>
                                        }
                                        {/* <li>
                                            <a href="product.html" className="sf-with-ul">Playstation</a>

                                            <div className="megamenu megamenu-sm">
                                                <div className="row no-gutters">
                                                    <div className="col-md-6">
                                                        <div className="menu-col">
                                                            <div className="menu-title">Games Software</div>
                                                            <ul>
                                                                <li><a href="#">Action</a></li>
                                                                <li><a href="#">Adventure</a></li>
                                                                <li><a href="#">Sports</a></li>
                                                                <li><a href="#">Strategy</a></li>
                                                                <li><a href="#">Role-Playing Game</a></li>
                                                                <li><a href="#">Action-Adventure</a></li>
                                                                <li><a href="#">Stealth</a></li>
                                                                <li><a href="#">Shooter</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="banner banner-overlay">
                                                            <a href="category.html">
                                                                <img src="assets/images/demos/demo-19/menu/banner-2.jpg" alt="Banner" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="megamenu-container">
                                            <a href="category.html" className="sf-with-ul">XBOX</a>

                                            <div className="megamenu">
                                                <div className="row no-gutters">
                                                    <div className="col-md-8">
                                                        <div className="menu-col">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="menu-title">Games Software</div>
                                                                    <ul>
                                                                        <li><a href="#">Action</a></li>
                                                                        <li><a href="#">Adventure</a></li>
                                                                        <li><a href="#">Music</a></li>
                                                                        <li><a href="#">Racing</a></li>
                                                                        <li><a href="#">Sports</a></li>
                                                                        <li><a href="#">Strategy</a></li>
                                                                        <li><a href="#">Role-Playing Game</a></li>
                                                                        <li><a href="#">Action-Adventure</a></li>
                                                                        <li><a href="#">Stealth</a></li>
                                                                        <li><a href="#">Shooter</a></li>
                                                                        <li><a href="#">Horror</a></li>
                                                                    </ul>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="menu-title">Game Console & Accessories</div>
                                                                    <ul>
                                                                        <li><a href="#">Console</a></li>
                                                                        <li><a href="#">Headsets</a></li>
                                                                        <li><a href="#">Collectibles</a></li>
                                                                        <li><a href="#">Gaming Chairs</a></li>
                                                                        <li><a href="#">Controller</a></li>
                                                                        <li><a href="#">Digital</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="banner banner-overlay">
                                                            <a href="category.html" className="banner banner-menu">
                                                                <img src="assets/images/demos/demo-19/menu/banner-1.jpg" alt="Banner" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="#">Nindendo</a></li>
                                        <li><a href="#">PC Gaming</a></li>
                                        <li><a href="#">Phones & Tablets</a></li>
                                        <li><a href="#">Pre-owned</a></li>
                                        <li><a href="#">Deals</a></li>
                                        <li><a href="#">Coming Soon</a></li>
                                        <li><a href="#">Accessories</a></li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                         
                    </div>

                    <div className="header-center">
                        <nav className="main-nav">
                            <ul className="menu sf-arrows">
                                <li className="megamenu-container active">
                                    <a href="/">Home</a>
                                </li>
                                {/* <li>
                                    <a href="category.html" className="sf-with-ul">AboutUS</a>

                                    <div className="megamenu megamenu-md">
                                        <div className="row no-gutters">
                                            <div className="col-md-8">
                                                <div className="menu-col">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="menu-title">Shop with sidebar</div>
                                                            <ul>
                                                                <li><a href="category-list.html">Shop List</a></li>
                                                                <li><a href="category-2cols.html">Shop Grid 2 Columns</a></li>
                                                                <li><a href="category.html">Shop Grid 3 Columns</a></li>
                                                                <li><a href="category-4cols.html">Shop Grid 4 Columns</a></li>
                                                                <li><a href="category-market.html"><span>Shop Market<span className="tip tip-new">New</span></span></a></li>
                                                            </ul>

                                                            <div className="menu-title">Shop no sidebar</div>
                                                            <ul>
                                                                <li><a href="category-boxed.html"><span>Shop Boxed No Sidebar<span className="tip tip-hot">Hot</span></span></a></li>
                                                                <li><a href="category-fullwidth.html">Shop Fullwidth No Sidebar</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="menu-title">Product Category</div>
                                                            <ul>
                                                                <li><a href="product-category-boxed.html">Product Category Boxed</a></li>
                                                                <li><a href="product-category-fullwidth.html"><span>Product Category Fullwidth<span className="tip tip-new">New</span></span></a></li>
                                                            </ul>
                                                            <div className="menu-title">Shop Pages</div>
                                                            <ul>
                                                                <li><a href="cart.html">Cart</a></li>
                                                                <li><a href="checkout.html">Checkout</a></li>
                                                                <li><a href="wishlist.html">Wishlist</a></li>
                                                                <li><a href="dashboard.html">My Account</a></li>
                                                                <li><a href="#">Lookbook</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="banner banner-overlay">
                                                    <a href="category.html" className="banner banner-menu">
                                                        <img src="assets/images/menu/banner-1.jpg" alt="Banner"/>

                                                        <div className="banner-content banner-content-top">
                                                            <div className="banner-title text-white">Last <br/>Chance<br/><span><strong>Sale</strong></span></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a href="product.html" className="sf-with-ul">Product</a>

                                    <div className="megamenu megamenu-sm">
                                        <div className="row no-gutters">
                                            <div className="col-md-6">
                                                <div className="menu-col">
                                                    <div className="menu-title">Product Details</div>
                                                    <ul>
                                                        <li><a href="product.html">Default</a></li>
                                                        <li><a href="product-centered.html">Centered</a></li>
                                                        <li><a href="product-extended.html"><span>Extended Info<span className="tip tip-new">New</span></span></a></li>
                                                        <li><a href="product-gallery.html">Gallery</a></li>
                                                        <li><a href="product-sticky.html">Sticky Info</a></li>
                                                        <li><a href="product-sidebar.html">Boxed With Sidebar</a></li>
                                                        <li><a href="product-fullwidth.html">Full Width</a></li>
                                                        <li><a href="product-masonry.html">Masonry Sticky Info</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="banner banner-overlay">
                                                    <a href="category.html">
                                                        <img src="assets/images/menu/banner-2.jpg" alt="Banner" />

                                                        <div className="banner-content banner-content-bottom">
                                                            <div className="banner-title text-white">New Trends<br/><span><strong>spring 2019</strong></span></div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a href="#" className="sf-with-ul">Pages</a>
                                </li>
                                <li>
                                    <a href="blog.html" className="sf-with-ul">Blog</a>

                                    <ul>
                                        <li><a href="blog.html">Classic</a></li>
                                        <li><a href="blog-listing.html">Listing</a></li>
                                        <li>
                                            <a href="#">Grid</a>
                                            <ul>
                                                <li><a href="blog-grid-2cols.html">Grid 2 columns</a></li>
                                                <li><a href="blog-grid-3cols.html">Grid 3 columns</a></li>
                                                <li><a href="blog-grid-4cols.html">Grid 4 columns</a></li>
                                                <li><a href="blog-grid-sidebar.html">Grid sidebar</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">Masonry</a>
                                            <ul>
                                                <li><a href="blog-masonry-2cols.html">Masonry 2 columns</a></li>
                                                <li><a href="blog-masonry-3cols.html">Masonry 3 columns</a></li>
                                                <li><a href="blog-masonry-4cols.html">Masonry 4 columns</a></li>
                                                <li><a href="blog-masonry-sidebar.html">Masonry sidebar</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">Mask</a>
                                            <ul>
                                                <li><a href="blog-mask-grid.html">Blog mask grid</a></li>
                                                <li><a href="blog-mask-masonry.html">Blog mask masonry</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="#">Single Post</a>
                                            <ul>
                                                <li><a href="single.html">Default with sidebar</a></li>
                                                <li><a href="single-fullwidth.html">Fullwidth no sidebar</a></li>
                                                <li><a href="single-fullwidth-sidebar.html">Fullwidth with sidebar</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="elements-list.html">Elements</a>
                                </li> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            </header>
            <div class="mobile-menu-overlay"></div>

<div class="mobile-menu-container">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close"><i class="icon-close"></i></span>
        
        <form class="mobile-search" onSubmit={searchProduct}>
            <label for="mobile-search" class="sr-only">Search</label>
            <input 
                                        type="search" 
                                        className="form-control" 
                                        placeholder="Search product ..." 
                                        required
                                        onChange={(e)=>{handleChange(e)}}
                                    />
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
        </form>

        <ul class="nav nav-pills-mobile nav-border-anim" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="mobile-menu-link" data-toggle="tab" href="#mobile-menu-tab" role="tab" aria-controls="mobile-menu-tab" aria-selected="true">Menu</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="mobile-cats-link" data-toggle="tab" href="#mobile-cats-tab" role="tab" aria-controls="mobile-cats-tab" aria-selected="false">Categories</a>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane fade show active" id="mobile-menu-tab" role="tabpanel" aria-labelledby="mobile-menu-link">
                <nav class="mobile-nav">
                    <ul class="mobile-menu">
                    {
                                            categories.length>0
                                            ? categories.map((data,key)=>{
                                                    return(
                                                        <li key={key}>
                                                            <a 
                                                                href="#" 
                                                                className="sf-with-ul"
                                                                onMouseEnter={()=>setChildFlag({state : true, id : data.sunsky_id})}
                                                                onClick={()=>{categoryProductList(data.sunsky_id)}}
                                                            >
                                                            {data.name}</a>

                                                            <ul>
                                                                <li><a href="product.html">Default</a></li>
                                                                <li><a href="product-centered.html">Centered</a></li>
                                                                <li><a href="product-extended.html"><span>Extended Info<span class="tip tip-new">New</span></span></a></li>
                                                                <li><a href="product-gallery.html">Gallery</a></li>
                                                                <li><a href="product-sticky.html">Sticky Info</a></li>
                                                                <li><a href="product-sidebar.html">Boxed With Sidebar</a></li>
                                                                <li><a href="product-fullwidth.html">Full Width</a></li>
                                                                <li><a href="product-masonry.html">Masonry Sticky Info</a></li>
                                                                {/* {
                                                                childCategories.length>0
                                                                ? 
                                                                    childCategories.map((data,key)=>{
                                                                        return(
                                                                            <li 
                                                                                key={key}
                                                                                onClick={()=>{categoryProductList(data.sunsky_id)}}
                                                                            ><a href="#">{data.name}</a></li>
                                                                        )})
                                                                
                                                                :null
                                                            } */}
                                                                
                                                            </ul>
                                                        </li>
                                                      
                                                    )
                                                })
                                            :null
                                        }
                        
                        <li>
                            <a href="product.html" >Product</a>
                            <ul>
                                <li><a href="product.html">Default</a></li>
                                <li><a href="product-centered.html">Centered</a></li>
                                <li><a href="product-extended.html"><span>Extended Info<span class="tip tip-new">New</span></span></a></li>
                                <li><a href="product-gallery.html">Gallery</a></li>
                                <li><a href="product-sticky.html">Sticky Info</a></li>
                                <li><a href="product-sidebar.html">Boxed With Sidebar</a></li>
                                <li><a href="product-fullwidth.html">Full Width</a></li>
                                <li><a href="product-masonry.html">Masonry Sticky Info</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="#">Pages</a>
                            <ul>
                                <li>
                                    <a href="about.html">About</a>

                                    <ul>
                                        <li><a href="about.html">About 01</a></li>
                                        <li><a href="about-2.html">About 02</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="contact.html">Contact</a>

                                    <ul>
                                        <li><a href="contact.html">Contact 01</a></li>
                                        <li><a href="contact-2.html">Contact 02</a></li>
                                    </ul>
                                </li>
                                <li><a href="login.html">Login</a></li>
                                <li><a href="faq.html">FAQs</a></li>
                                <li><a href="404.html">Error 404</a></li>
                                <li><a href="coming-soon.html">Coming Soon</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="blog.html">Blog</a>

                            <ul>
                                <li><a href="blog.html">Classic</a></li>
                                <li><a href="blog-listing.html">Listing</a></li>
                                <li>
                                    <a href="#">Grid</a>
                                    <ul>
                                        <li><a href="blog-grid-2cols.html">Grid 2 columns</a></li>
                                        <li><a href="blog-grid-3cols.html">Grid 3 columns</a></li>
                                        <li><a href="blog-grid-4cols.html">Grid 4 columns</a></li>
                                        <li><a href="blog-grid-sidebar.html">Grid sidebar</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Masonry</a>
                                    <ul>
                                        <li><a href="blog-masonry-2cols.html">Masonry 2 columns</a></li>
                                        <li><a href="blog-masonry-3cols.html">Masonry 3 columns</a></li>
                                        <li><a href="blog-masonry-4cols.html">Masonry 4 columns</a></li>
                                        <li><a href="blog-masonry-sidebar.html">Masonry sidebar</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Mask</a>
                                    <ul>
                                        <li><a href="blog-mask-grid.html">Blog mask grid</a></li>
                                        <li><a href="blog-mask-masonry.html">Blog mask masonry</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Single Post</a>
                                    <ul>
                                        <li><a href="single.html">Default with sidebar</a></li>
                                        <li><a href="single-fullwidth.html">Fullwidth no sidebar</a></li>
                                        <li><a href="single-fullwidth-sidebar.html">Fullwidth with sidebar</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="elements-list.html">Elements</a>
                            <ul>
                                <li><a href="elements-products.html">Products</a></li>
                                <li><a href="elements-typography.html">Typography</a></li>
                                <li><a href="elements-titles.html">Titles</a></li>
                                <li><a href="elements-banners.html">Banners</a></li>
                                <li><a href="elements-product-category.html">Product Category</a></li>
                                <li><a href="elements-video-banners.html">Video Banners</a></li>
                                <li><a href="elements-buttons.html">Buttons</a></li>
                                <li><a href="elements-accordions.html">Accordions</a></li>
                                <li><a href="elements-tabs.html">Tabs</a></li>
                                <li><a href="elements-testimonials.html">Testimonials</a></li>
                                <li><a href="elements-blog-posts.html">Blog Posts</a></li>
                                <li><a href="elements-portfolio.html">Portfolio</a></li>
                                <li><a href="elements-cta.html">Call to Action</a></li>
                                <li><a href="elements-icon-boxes.html">Icon Boxes</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="tab-pane fade" id="mobile-cats-tab" role="tabpanel" aria-labelledby="mobile-cats-link">
                <nav class="mobile-cats-nav">
                    <ul class="mobile-cats-menu">
                        <li><a class="mobile-cats-lead" href="#">Daily offers</a></li>
                        <li><a class="mobile-cats-lead" href="#">Gift Ideas</a></li>
                        <li><a href="#">Beds</a></li>
                        <li><a href="#">Lighting</a></li>
                        <li><a href="#">Sofas & Sleeper sofas</a></li>
                        <li><a href="#">Storage</a></li>
                        <li><a href="#">Armchairs & Chaises</a></li>
                        <li><a href="#">Decoration </a></li>
                        <li><a href="#">Kitchen Cabinets</a></li>
                        <li><a href="#">Coffee & Tables</a></li>
                        <li><a href="#">Outdoor Furniture </a></li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="social-icons">
            <a href="#" class="social-icon" target="_blank" title="Facebook"><i class="icon-facebook-f"></i></a>
            <a href="#" class="social-icon" target="_blank" title="Twitter"><i class="icon-twitter"></i></a>
            <a href="#" class="social-icon" target="_blank" title="Instagram"><i class="icon-instagram"></i></a>
            <a href="#" class="social-icon" target="_blank" title="Youtube"><i class="icon-youtube"></i></a>
        </div>
    </div>
</div>
        </>
    )
}

export default Header