import Skeleton,{ SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ProductSkeleton = () => {
  return (
   
    <div className="container">
      <div className="row">
                <div className="col-md-6" >
                    <SkeletonTheme baseColor= "#f8f8f8" highlightColor= "#ebebeb">
                        <Skeleton width="100%" height="60vh" style={{ margin:"0% 10% 10% 0%"}}/>
                    </SkeletonTheme>
                </div>
                <div className="col-md-6" >
                    {/* <SkeletonTheme baseColor= "#f8f8f8" highlightColor= "#ebebeb"> */}
                        <Skeleton width="80%" height={35} style={{ margin:"0% 0% 2% 5%"}}/>
                        <Skeleton width="30%" height={35} style={{ margin:"0% 0% 5% 5%"}}/>
                        <Skeleton width="80%" height={100} style={{ margin:"0% 0% 3% 5%"}}/>
                        <Skeleton count={2} width="30%" height={30} style={{ margin:"0% 0% 2% 5%"}}/>
                        <div className="row">
                            <div className="col-xl-4" style={{marginLeft:'0%'}}>
                                <Skeleton width="100%" height={60} style={{ margin:"0% 0% 2% 25%"}}/>
                            </div>
                            <div className="col-xl-4">
                                <Skeleton width="100%" height={60} style={{ margin:"0% 0% 2% 40%"}}/>
                            </div>
                            
                           
                        </div>
                    {/* </SkeletonTheme> */}
                </div>
                <div className="col-md-12">
                <Skeleton width="100%" height="70%" style={{ margin:"5% 0% 40% 5%"}}/>
                </div>
       
      </div>
    </div>
  );
};

export default ProductSkeleton;
