import {LOGINSUCCESS, LOGINFAIL, SIGNUPSUCCESS, SIGNUPFAIL, LOGOUT, SETDEFAULT} from '../actions/index';
const initial = {
    token : null,
    user_id : null,
    is_vendor:false,
    name : '',
    email :'',
    type : '',
    message:'',
    hit : false,
    flag : null
}


export const LoginReducer = (state = initial, action)=>{
    switch(action.type){
        case LOGINSUCCESS:
            // console.log(action);
            return {
                ...state,
                token : action.payload.token,
                user_id : action.payload.user_id,
                name : action.payload.name,
                email : action.payload.email,
                type : 'Login',
                is_vendor : action.payload.is_vendor,
                message : 'User Login SuccessFull',
                hit : true,
                flag : 'SUCCESS'

            }
        case LOGINFAIL:
            return{
                ...state,
                message : action.payload.error,
                hit : true,
                flag : 'ERROR'
            }
        case SIGNUPSUCCESS:
                return {
                    ...state,
                    // token : action.payload.token,
                    user_id : action.payload.user_id,
                    // type : action.payload.type,
                    message : 'User Resitered SuccessFull. Please Login To Continue',
                    hit : true,
                    flag : 'SUCCESS'
    
            }
        case SIGNUPFAIL:
                return{
                    ...state,
                    message : action.payload.error,
                    hit : true,
                    flag : 'ERROR'
            }
        case LOGOUT:
            return{
                ...state,
                token : null,
                user_id : null,
                type : 'Logout',
                message : action.payload.data,
                hit : true,
                flag : 'SUCCESS'
            }
        case SETDEFAULT:
                return{
                    ...state,
                    token : null,
                    user_id : null,
                    type : 'Logged Out',
                    message : "Logged Out SuccessFully",
                    is_vendor:false,
                    hit : false,
                    flag : null
                }
        default : return state;
    }
}